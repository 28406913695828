import { useQuery } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import {
  DefaultApiGetEventsRequest,
  DefaultApiGetEventSubmissionsRequest,
  IEventGetAllResponse,
} from '../autogenerated';

const getEventSubmissions = (parameters?: DefaultApiGetEventSubmissionsRequest) =>
  getAuthenticatedApiClient()
    .getEventSubmissions(parameters)
    .then((x) => x.data);

export const useGetEventSubmissions = (parameters?: DefaultApiGetEventSubmissionsRequest, options?: any) =>
  useQuery(
    [
      'submissions',
      {
        page: parameters?.page,
        pageSize: parameters?.pageSize,
        id: parameters?.id,
        searchString: parameters?.searchString,
      },
    ],
    () => getEventSubmissions(parameters),
    options,
  );
