import { Alert, Box, Card, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateNotification } from '../api-client/hooks/useCreateNofification';
import FilledButton from '../components/buttons/FilledButton';
import UploadBox from '../components/UploadBox';
import UploadedImage from '../components/UploadedImage';

export default function SendNotificationPage() {
  const [open, setOpen] = useState(false);

  const { mutateAsync: createNotification } = useCreateNotification();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState<null | File>(null);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSend = async () => {
    try {
      await createNotification({ iPushNotificationCreation: { body, title, topic: 'upcoming-events', dryRun: false } });
      setBody('');
      setTitle('');
      setOpen(true);
    } catch (e) {}
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Notification successfully sent!
        </Alert>
      </Snackbar>
      <Card
        elevation={4}
        sx={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
          width: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField value={title} onChange={(e) => setTitle(e.target.value)} style={{ minWidth: 350 }} label="Title" />
          <TextField
            value={body}
            onChange={(e) => setBody(e.target.value)}
            multiline
            style={{ minWidth: 350, marginTop: 32, marginBottom: 32 }}
            minRows={3}
            label="Body"
          />
          {/*
          {!image && <UploadBox onChange={(file) => setImage(file)} text="Upload Image (optional)" />}
          {image && (
            <div>
              <Typography variant="h6">Image</Typography>
              <UploadedImage
                imageStyle={{ maxWidth: 350 }}
                onRemove={() => setImage(null)}
                src={URL.createObjectURL(image)}
              />
            </div>
          )}
          */}
          <FilledButton onClick={handleSend} style={{ marginTop: 48 }}>
            Send Notification
          </FilledButton>
        </Box>
      </Card>
    </Box>
  );
}
