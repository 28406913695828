import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { LatLng } from './Map';

interface PlaceServiceType {
  current: google.maps.places.PlacesService | null;
}

const autocompleteService = { current: null };
const placeService = { current: null } as PlaceServiceType;

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting | null;
  place_id: string;
}

interface GooglePlacesAutocompleteProps {
  onChange?: (place: PlaceType | null) => void;
  onDetailedInfoLoad?: (result: google.maps.places.PlaceResult | null) => void;
  value: PlaceType | null;
  style?: React.CSSProperties;
  markerPosition?: LatLng | null;
}

export default function GooglePlacesAutocomplete({
  onChange,
  onDetailedInfoLoad,
  style,
  value,
  markerPosition,
}: GooglePlacesAutocompleteProps) {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (!placeService.current && (window as any).google) {
      placeService.current = new (window as any).google.maps.places.PlacesService(
        document.getElementById('results-anchor') as HTMLDivElement,
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      console.log('Autocomplete reuslts: ', results);
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  //If map marker position is (0,0) and there's an address, search for the location's coordinates.
  React.useEffect(() => {
    if (markerPosition?.lat === 0 && markerPosition.lng === 0 && value) {
      placeService?.current?.findPlaceFromQuery(
        { query: value.description, fields: ['geometry.location', 'formatted_address'] },
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results?.length && results.length > 0) {
            onDetailedInfoLoad?.(results[0]);
            onChange?.({
              description: results[0].formatted_address || value.description,
              structured_formatting: null,
              place_id: '0',
            });
          }
        },
      );
    }
  }, [markerPosition]);

  React.useEffect(() => {
    if (value?.place_id) {
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId: value.place_id,
        fields: ['geometry.location'],
      };

      placeService?.current?.getDetails(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          onDetailedInfoLoad?.(results);
        }
      });
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        id="google-map-demo"
        sx={{ width: 350 }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description || '')}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          onChange?.(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label="Add a location" fullWidth />}
        renderOption={(props, option) => {
          const matches = option.structured_formatting?.main_text_matched_substrings;
          const parts = matches
            ? parse(
                option.structured_formatting!.main_text,
                matches.map((match: any) => [match.offset, match.offset + match.length]),
              )
            : null;

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid>
                <Grid item xs>
                  {parts?.map((part: any, index: number) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        style={style}
      />
      <div id="results-anchor" />
    </>
  );
}
