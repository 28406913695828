import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiUpdateGenericPageBySlugRequest } from '../autogenerated';

const updateFerryText = (parameters: DefaultApiUpdateGenericPageBySlugRequest) =>
  getAuthenticatedApiClient()
    .updateGenericPageBySlug(parameters)
    .then((x) => x.data);

export const useUpdateFerryText = () => useMutation(updateFerryText);
