import { IconButton, Popover, Typography } from '@mui/material';
import { SketchPicker } from 'react-color';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { Colorize } from '@mui/icons-material';
import { useState } from 'react';
import { PhotoshopPicker } from 'react-color';

interface ColorItemProps {
  name: string;
  color: string;
  onChange: (color: string) => void;
}

export default function ColorItem({ name, color, onChange }: ColorItemProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ display: 'flex', width: 300, alignItems: 'center' }}>
      <Typography variant="body2" style={{ fontSize: 16, flexGrow: 1 }}>
        {name}:
      </Typography>
      <Typography variant="body2" style={{ fontSize: 16, marginRight: 16 }}></Typography>
      <div style={{ height: 24, width: 32, backgroundColor: color, marginRight: 8, border: '1px solid black' }} />
      <IconButton onClick={handleClick}>
        <Colorize />
      </IconButton>
      <Popover
        onClose={handleClose}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
      >
        <SketchPicker color={color} onChange={(c: any) => onChange(c.hex)} />
      </Popover>
    </div>
  );
}
