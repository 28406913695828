import { Drawer } from '@mui/material';
import { theme } from '../themes/CustomTheme';
import gretnaLogo from '../assets/gretna-logo.png';
import DrawerItem, { DrawerItemProps } from './DrawerItem';
import { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

/** @jsxImportSource @emotion/react */

export default function NavDrawer() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();

  const options: DrawerItemProps[] = [
    { title: 'Events', iconName: 'event' },
    { title: 'Points of Interest', iconName: 'my_location' },
    // { title: 'Event Acts', iconName: 'event_note' },
    { title: 'Add Admin', iconName: 'person', route: 'add-admin' },
    { title: 'Email List', iconName: 'mail', route: 'email-list' },
    { title: 'Change PDF', iconName: 'picture_as_pdf', route: 'change-pdf' },
    { title: 'Ferry Text', iconName: 'directions_boat', route: 'ferry' },
    { title: 'Send Notification', iconName: 'notifications', route: 'notifications' },
    { title: 'Event Submissions', iconName: 'rate_review', route: 'submissions' },
    { title: 'Logout', iconName: 'logout' },
  ];

  const handleLogout = async () => {
    await Auth.signOut();
    navigate('/login');
  };

  const handleOption = (index: number) => {
    if (index === options.length - 1) {
      handleLogout();
    } else if (index > 1) {
      navigate(`/${options[index].route}`);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <Drawer
      sx={{
        width: 216,
        '& .MuiDrawer-paper': {
          width: 216,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.primary.main,
          paddingTop: 4,
        },
      }}
      open
      variant="permanent"
    >
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 32 }}>
        <img src={gretnaLogo} style={{ width: 51, height: 61 }} />
      </div>
      {options.map(({ title, iconName }, index, array) => (
        <DrawerItem
          title={title}
          iconName={iconName}
          selected={selectedIndex === index}
          onClick={() => handleOption(index)}
          disableDropdown={index > 1}
        />
      ))}
    </Drawer>
  );
}
