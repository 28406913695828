import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { IEvent, IEventCreation, IMapLocationCreation } from '../autogenerated';

const createMapLocation = (location: IMapLocationCreation) =>
  getAuthenticatedApiClient()
    .createMapLocation({
      iMapLocationCreationRequest: { mapLocation: location },
    })
    .then((x) => x.data);

export const useCreateMapLocation = () => useMutation(createMapLocation);
