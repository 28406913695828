import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiReplaceThumbnailImageForImageGalleryRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const replaceThumbnailImage = (request: DefaultApiReplaceThumbnailImageForImageGalleryRequest) =>
  getAuthenticatedApiClient()
    .replaceThumbnailImageForImageGallery(request)
    .then((res) => res.data);

export const useReplaceThumbnailImage = () => useMutation('replace-thumbnail-image', replaceThumbnailImage);
