import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiDeleteEventRequest } from '../autogenerated';

const deleteEvent = (parameters: DefaultApiDeleteEventRequest) =>
  getAuthenticatedApiClient()
    .deleteEvent(parameters)
    .then((x) => x.data);

export const useDeleteEvent = () => useMutation(deleteEvent);
