import React, { useEffect, useState } from 'react';
import EventActsListHeader from '../components/EventActsListHeader';
import ActListItem from '../components/ActListItem';
import { Card, Divider, List, Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetActs } from '../api-client/hooks/useGetActs';
import { IAct } from '../api-client/autogenerated';
import { useDeleteAct } from '../api-client/hooks/useDeleteAct';

const EventActsPage = () => {
  const { eventId } = useParams();

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState<IAct[]>([]);
  const [maxPage, setMaxPage] = useState(0);

  const { data: acts, refetch: refetchActs } = useGetActs(Number(eventId));
  const { mutateAsync: deleteAct } = useDeleteAct();

  const handleDeletePressed = async (act: IAct) => {
    await deleteAct(act);
    refetchActs();
  };

  const PAGE_SIZE = 12;

  const getFilteredEvents = () => {
    if (!acts) return [];
    let filtered = [...acts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))];
    if (searchInput !== '') {
      filtered = filtered.filter((x) => x.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    const max = Math.ceil(filtered.length / PAGE_SIZE);
    setMaxPage(max);
    if (page >= max) {
      setPage(max - 1);
    }
    if (page < 0) {
      setPage(0);
    }

    filtered = filtered.slice(page * PAGE_SIZE, Math.min((page + 1) * PAGE_SIZE, acts.length));
    return filtered;
  };

  useEffect(() => {
    setFilteredEvents(getFilteredEvents());
  }, [page, searchInput, acts]);

  const handlePageChange = (e: any, value: number) => {
    setPage(value - 1);
  };

  return (
    <Card sx={{ width: '100%', minHeight: 64, marginLeft: '10px', marginTop: '50px' }} elevation={4}>
      <EventActsListHeader searchInput={searchInput} setSearchInput={setSearchInput} />
      <Divider style={{ backgroundColor: 'black', minWidth: '100%' }} />
      <List>
        {filteredEvents?.map((x, index) => (
          <ActListItem onDelete={() => handleDeletePressed(x)} index={index} act={x} />
        ))}
      </List>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={maxPage} shape="rounded" page={page + 1} onChange={handlePageChange} />
      </div>
    </Card>
  );
};

export default EventActsPage;
