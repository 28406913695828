export default function PrivacyPolicyPage() {
  return (
    <div style={{ maxWidth: 960, marginLeft: 16, marginRight: 16 }}>
      <h2>Privacy Policy for Gretna Live</h2>
      <ol>
        <li>
          <b>Introduction</b> Welcome to the Gretna Live privacy policy. Vigilus developed Gretna Live in accordance
          with Google Play User Data Policy. While Gretna Live does not customarily collect personal data and does not
          store or handle user accounts, it does, at times, request email addresses as part of an opt-in service during
          app installation. This voluntary action improves the user's experiences by providing easy access to important
          information about the City of Gretna.
        </li>
        <li>
          <b>Entity Information</b> "Gretna Live" is a property owned, developed, and managed by Vigilus. Users with
          queries or concerns regarding this privacy policy or its implementation are advised to direct submissions
          through our online contact form at https://vigilus.com/contact-us.
        </li>
        <li>
          <b>Data Collection and Usage</b> To enhance the user experience, Gretna Live requests users to voluntarily
          provide their email addresses during app installation. This information, which is provided on an opt-in basis,
          is solely for the facilitation of distributing relevant information about the City of Gretna. No other
          personal or sensitive user data is actively collected or retained.
        </li>
        <li>
          <b>Data Sharing</b> Under no circumstances does Gretna Live disclose, share, or sell user data (including
          opt-in email addresses) to any third parties. Data collected by the app is exclusively used to enrich user
          experiences and further the app's core function of providing essential information.
        </li>
        <li>
          <b>Data Security</b> Although Gretna Live's data collection is minimal, security protocols are paramount.
          Vigilus employs best-practice security measures to ensure that data that does accrue during operation remains
          protected and secure.
        </li>
        <li>
          <b>Data Retention</b> and Deletion Policy Gretna Live maintains a stringent data retention and deletion
          policy. We retain collected email addresses only for as long as required by necessity and delete them in
          accordance with our standard processes.
        </li>
        <li>
          <b>Changes to our Privacy Policy</b> Any modifications to this privacy policy will be promptly visible on this
          page. Users are encouraged to check back regularly to ensure continued alignment with our privacy practices.
        </li>
        <li>
          <b>Consent</b> By choosing to use Gretna Live, users agree to the terms of this privacy policy. This includes
          the collection of email addresses for providing City of Gretna updates, exclusively on an opt-in basis.
        </li>
        <p>
          Thank you for choosing Gretna Live. For any queries or concerns, please contact us, we value your feedback.
        </p>
      </ol>
    </div>
  );
}
