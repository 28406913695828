import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiInviteUserRequest, IEvent, IEventCreation } from '../autogenerated';

const inviteUser = (params: DefaultApiInviteUserRequest) =>
  getAuthenticatedApiClient()
    .inviteUser(params)
    .then((x) => x.data);

export const useInviteUser = () => useMutation(inviteUser);
