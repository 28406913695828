import { css } from '@emotion/react';
import { Card, TextField, Typography } from '@mui/material';
import FilledButton from './buttons/FilledButton';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

const styles = {
  card: css`
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  textField: css`
    width: 300px;
  `,
};

export default function LoginCard() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [resettingPassword, setResettingPassword] = useState(false);

  const [password2, setPassword2] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [user, setUser] = useState<any>();

  const navigate = useNavigate();

  async function signIn() {
    try {
      const u = await Auth.signIn({ username: email, password });
      if (u?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(u);
        setResettingPassword(true);
        setPassword('');
      } else {
        navigate('/events');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function resetPassword() {
    if (password2 !== passwordRepeat) {
      alert('Passwords do not match.');
      return;
    }
    try {
      const auth = await Auth.completeNewPassword(user, password2);
      navigate('/events');
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => navigate('/events'))
      .catch(() => {});
  }, []);

  return (
    <Card css={styles.card} elevation={4}>
      {!resettingPassword ? (
        <>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            css={styles.textField}
            sx={{ marginBottom: '24px' }}
            label="Email Address"
          />
          <TextField
            css={styles.textField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: '48px' }}
            label="Password"
            type="password"
          />
          <FilledButton onClick={() => signIn()} size="large" sx={{ fontSize: '20px', width: '220px' }}>
            Login
          </FilledButton>
        </>
      ) : (
        <>
          <Typography sx={{ marginBottom: 4 }} variant="body2">
            Please enter a new password.
          </Typography>
          <TextField
            css={styles.textField}
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            sx={{ marginBottom: '48px' }}
            label="Password"
            type="password"
          />
          <TextField
            css={styles.textField}
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
            sx={{ marginBottom: '48px' }}
            label="Password Repeat"
            type="password"
          />
          <FilledButton onClick={() => resetPassword()} size="large" sx={{ fontSize: '20px', width: '220px' }}>
            Confirm
          </FilledButton>
        </>
      )}
    </Card>
  );
}
