import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, css, Grid, TextField, Typography } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import UploadedImage from '../components/UploadedImage';
import UploadExtraImage from '../components/UploadExtraImage';
import UploadBox from '../components/UploadBox';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAct } from '../api-client/hooks/useCreateAct';
import { IAct, IActCreation, IActUpdate, IImageGalleryImage } from '../api-client/autogenerated';
import { useDeleteImages } from '../api-client/hooks/useDeleteImages';
import GooglePlacesAutocomplete, { PlaceType } from '../components/GooglePlacesAutocomplete';
import Map, { LatLng } from '../components/Map';
import { useCreateMapLocation } from '../api-client/hooks/useCreateMapLocation';
import { useUploadImages } from '../api-client/hooks/useUploadImages';
import { useGetActs } from '../api-client/hooks/useGetActs';
import { useUpdateAct } from '../api-client/hooks/useUpdateAct';
import { useReplaceThumbnailImage } from '../api-client/hooks/useReplaceThumbnailImage';

/** @jsxImportSource @emotion/react */

const styles = {
  textfield: css`
    width: 350px;
    margin-bottom: 32px;
  `,
};

const CreateEventActPage = () => {
  const { eventId, actId } = useParams();
  const { data: acts } = useGetActs(parseInt(eventId!));
  const navigate = useNavigate();

  const { mutateAsync: createAct } = useCreateAct();
  const { mutateAsync: deleteImages } = useDeleteImages();
  const { mutateAsync: createMapLocation } = useCreateMapLocation();
  const { mutateAsync: replaceThumbnailImage } = useReplaceThumbnailImage();
  const { mutateAsync: uploadImages } = useUploadImages();
  const { mutateAsync: updateAct } = useUpdateAct();

  const [existingThumbnailImageURL, setExistingThumbnailImageUrl] = useState<string>();
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | null>(null);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<IImageGalleryImage[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const removeImage = (file: File) => {
    const remove = newImages.findIndex((x) => file === x);
    const imagesTemp = [...newImages];
    imagesTemp.splice(remove, 1);
    setNewImages(imagesTemp);
  };

  const [textLocation, setTextLocation] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(null);
  const [detailedAddress, setDetailedAddress] = useState<google.maps.places.PlaceResult | null>(null);
  const [doNotMoveMarkerFlag, setDoNotMoveMarkerFlag] = useState(false);
  const [mapMarkerPosition, setMapMarkerPosition] = useState<LatLng | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLng>({ lat: 29.918, lng: -90.063 });

  const originalAct = useRef<IAct | null>(null);

  const addImage = (file: File) => {
    const imagesTemp = [...newImages];
    imagesTemp.push(file);
    setNewImages(imagesTemp);
  };

  const deleteImage = async (id: number) => {
    await deleteImages([id]);
    setExistingImages((prev) => prev.filter((x) => x.id !== id));
  };

  const handleCreateClicked = async () => {
    let response: IAct;
    const mapData = {
      address: selectedAddress!.description,
      latitude: mapMarkerPosition!.lat,
      longitude: mapMarkerPosition!.lng,
    };
    if (!actId) {
      const act: IActCreation = {
        name,
        description,
        startsAt: selectedStartDate!.toISOString(),
        endsAt: selectedEndDate!.toISOString(),
        eventId: parseInt(eventId!),
        textLocation,
      };
      response = await createAct({ act, mapLocation: mapData });
    } else {
      if (!originalAct.current) return;
      const mapLocation =
        mapData.latitude !== originalAct.current.mapLocation?.latitude ||
        mapData.longitude !== originalAct.current.mapLocation?.longitude
          ? await createMapLocation(mapData)
          : null;
      const act: IActUpdate = {
        name: name !== originalAct.current.name ? name : undefined,
        description: description !== originalAct.current.description ? description : undefined,
        startsAt:
          selectedStartDate!.toISOString() !== originalAct.current.startsAt
            ? selectedStartDate!.toISOString()
            : undefined,
        endsAt:
          selectedEndDate!.toISOString() !== originalAct.current.endsAt ? selectedEndDate!.toISOString() : undefined,
        mapLocationId: mapLocation?.id || undefined,
        eventId: undefined,
        textLocation: textLocation !== originalAct.current.textLocation ? textLocation : undefined,
      };
      response = await updateAct({ id: parseInt(actId!), iActUpdateRequest: { act } });
    }
    if (response.imageGallery) {
      if (newImages.length > 0) {
        await uploadImages({ id: response.imageGallery.id, images: newImages });
      }
      if (newThumbnailImage) {
        await replaceThumbnailImage({ id: response.imageGallery.id, thumbnailImage: newThumbnailImage });
      }
    }
    navigate(-1);
  };

  const onAutocompleteSelectPlace = (place: google.maps.places.PlaceResult | null) => {
    setDetailedAddress(place);
    if (!doNotMoveMarkerFlag) {
      setMapCenter({ lat: place?.geometry?.location?.lat() || 0, lng: place?.geometry?.location?.lng() || 0 });
      setMapMarkerPosition({
        lat: place?.geometry?.location?.lat() || 0,
        lng: place?.geometry?.location?.lng() || 0,
      });
    }
    setDoNotMoveMarkerFlag(false);
  };

  const handleMapClicked = (place: PlaceType, latLng: LatLng) => {
    setSelectedAddress(place);
    setDoNotMoveMarkerFlag(true);
    setMapMarkerPosition(latLng);
  };

  const populateFields = async () => {
    const act = acts?.find((e) => e.id === Number(actId));
    if (!act) return;
    originalAct.current = act;
    setName(originalAct.current.name);
    setDescription(originalAct.current.description || '');
    setSelectedStartDate(moment(originalAct.current.startsAt));
    setSelectedEndDate(moment(originalAct.current.endsAt));
    setSelectedAddress(
      originalAct.current.mapLocation?.address
        ? { description: originalAct.current.mapLocation?.address, structured_formatting: null, place_id: '0' }
        : null,
    );
    setTextLocation(originalAct.current.textLocation || '');
    setMapMarkerPosition({
      lat: originalAct.current.mapLocation?.latitude || 0,
      lng: originalAct.current.mapLocation?.longitude || 0,
    });
    setMapCenter({
      lat: originalAct.current.mapLocation?.latitude || 0,
      lng: originalAct.current.mapLocation?.longitude || 0,
    });

    setExistingImages(act.imageGallery?.images || []);
    setExistingThumbnailImageUrl(act.imageGallery?.thumbnailImageUrl || undefined);
  };
  useEffect(() => {
    if (eventId !== undefined && actId !== undefined) {
      populateFields();
    }
  }, [eventId, actId, acts]);

  return (
    <Card
      sx={{
        width: '500px',
        padding: 0,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
          outline: 'solid black',
          paddingBottom: '30px',
        }}
      >
        <Grid
          item
          sx={{
            width: '100%',
            height: '80px',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#F1EFDE',
          }}
        >
          <Typography
            align="center"
            sx={{
              paddingTop: '10px',
              color: 'black',
              width: '100%',
              height: '100%',
              fontSize: '40px',
              fontWeight: '700',
            }}
          >
            Create Event Act
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ marginTop: 2 }}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              css={styles.textfield}
              size="small"
              label="Name"
            />
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <TextField
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              css={styles.textfield}
              size="small"
              multiline
              minRows={6}
              label="Description"
            />
          </Box>
        </Grid>
        <Grid item sx={{ width: '350px' }}>
          <DesktopDateTimePicker
            label="Start Time"
            inputFormat="MM/DD/YYYY h:mm A"
            css={styles.textfield}
            value={selectedStartDate}
            onChange={(value) => setSelectedStartDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item sx={{ width: '350px' }}>
          <DesktopDateTimePicker
            label="End Time"
            inputFormat="MM/DD/YYYY h:mm A"
            css={styles.textfield}
            value={selectedEndDate}
            onChange={(value) => setSelectedEndDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        <Grid item sx={{ width: '350px', marginBottom: '16px' }}>
          <Typography variant="h6">Location</Typography>
        </Grid>

        <Grid item sx={{ width: '350px' }}>
          <TextField
            value={textLocation}
            onChange={(e) => setTextLocation(e.target.value)}
            css={styles.textfield}
            size="small"
            label="Simple Location"
          />
        </Grid>

        <Grid item sx={{ width: '350px' }}>
          <GooglePlacesAutocomplete
            onDetailedInfoLoad={onAutocompleteSelectPlace}
            onChange={(address) => setSelectedAddress(address)}
            value={selectedAddress}
          />
          <div style={{ height: 16 }} />
          <Map
            selectedAddressDetails={detailedAddress}
            onClickAndReverseGeocode={handleMapClicked}
            markerPosition={mapMarkerPosition}
            center={mapCenter}
          />
          <div style={{ height: 16 }} />
          {!existingThumbnailImageURL && !newThumbnailImage && (
            <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Upload Thumbnail" />
          )}
          {!!existingThumbnailImageURL && (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Existing Thumbnail</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 16 }}>
                  <UploadedImage imageStyle={{ maxWidth: 350 }} src={existingThumbnailImageURL} />
                </div>
              </div>
              {!newThumbnailImage && (
                <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Replace Thumbnail" />
              )}
            </>
          )}
          {newThumbnailImage && (
            <div>
              <Typography variant="h6">New Thumbnail</Typography>
              <UploadedImage
                imageStyle={{ maxWidth: 350 }}
                onRemove={() => setNewThumbnailImage(null)}
                src={URL.createObjectURL(newThumbnailImage)}
              />
            </div>
          )}
          {existingImages.length > 0 && <div style={{ height: 32 }} />}
          {existingImages.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">Existing Images</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {existingImages.map((x) => (
                  <div style={{ marginBottom: 16 }}>
                    <UploadedImage imageStyle={{ maxWidth: 350 }} onRemove={() => deleteImage(x.id)} src={x.url} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {newImages.length === 0 ? (
            <UploadBox onChange={(file) => setNewImages([file])} text="Upload Images" />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">New Images</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {newImages.map((x) => (
                  <div style={{ marginBottom: 16 }}>
                    <UploadedImage
                      imageStyle={{ maxWidth: 350 }}
                      onRemove={() => removeImage(x)}
                      src={URL.createObjectURL(x)}
                    />
                  </div>
                ))}
              </div>
              <UploadExtraImage onChange={(file) => addImage(file)} />
            </div>
          )}
        </Grid>
        <div style={{ height: 64 }} />
        <Grid item>
          <Button
            variant="contained"
            sx={{
              backgroundColo: '#2C9470',
              color: 'white',
              width: '150px',
            }}
            onClick={handleCreateClicked}
          >
            {actId ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateEventActPage;
