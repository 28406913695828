import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { css } from '@emotion/react';
import {
  IPointOfInterestCreation,
  IPointOfInterestUpdate,
  IImageGalleryImage,
  IPointOfInterest,
} from '../api-client/autogenerated';
import GooglePlacesAutocomplete, { PlaceType } from '../components/GooglePlacesAutocomplete';
import UploadBox from '../components/UploadBox';
import UploadedImage from '../components/UploadedImage';
import UploadExtraImage from '../components/UploadExtraImage';
import { useCreatePOI } from '../api-client/hooks/useCreatePOI';
import { useNavigate, useParams } from 'react-router-dom';
import FilledButton from '../components/buttons/FilledButton';
import { useGetPOICategories } from '../api-client/hooks/useGetPOICategories';
import { useUpdatePOI } from '../api-client/hooks/useUpdatePOI';
import { useGetPointsOfInterest } from '../api-client/hooks/useGetPointsOfInterest';
import { useUploadImages } from '../api-client/hooks/useUploadImages';
import { useDeleteImages } from '../api-client/hooks/useDeleteImages';
import Map, { LatLng } from '../components/Map';
import { useCreateMapLocation } from '../api-client/hooks/useCreateMapLocation';
import { useReplaceThumbnailImage } from '../api-client/hooks/useReplaceThumbnailImage';

/** @jsxImportSource @emotion/react */

const styles = {
  header: css`
    height: 100px;
    display: flex;
    align-items: center;
    background-color: beige;
  `,
  headerTitle: css`
    width: 100%;
    color: #000;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 64px;
  `,
  textfield: css`
    width: 100%;
    margin-bottom: 32px;
  `,
  subtitle: css`
    color: #000;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    align-self: flex-start;
  `,
};

export default function CreatePOIs() {
  const { eventId, pointOfInterestId } = useParams<{ eventId: string; pointOfInterestId?: string }>();

  const { data: categories } = useGetPOICategories();
  const { data: pointsOfInterest } = useGetPointsOfInterest({ id: Number(eventId) });
  const { mutateAsync: createMapLocation } = useCreateMapLocation();
  const { mutateAsync: uploadImages } = useUploadImages();
  const { mutateAsync: deleteImages } = useDeleteImages();
  const { mutateAsync: replaceThumbnailImage } = useReplaceThumbnailImage();
  const { mutateAsync: createPOI } = useCreatePOI();
  const { mutateAsync: updatePOI } = useUpdatePOI();

  const navigate = useNavigate();

  const isEditMode = pointOfInterestId !== undefined;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<number | ''>('');
  const [isStage, setIsStage] = useState(false);
  const [isDisplayedOnMap, setIsDisplayedOnMap] = useState(false);
  const [isDisplayedInList, setIsDisplayedInList] = useState(true);
  const [textLocation, setTextLocation] = useState('');
  const [mapMarkerPosition, setMapMarkerPosition] = useState<LatLng | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLng>({ lat: 29.918, lng: -90.063 });
  const [detailedAddress, setDetailedAddress] = useState<google.maps.places.PlaceResult | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(null);
  const [doNotMoveMarkerFlag, setDoNotMoveMarkerFlag] = useState(false);
  const [existingThumbnailImageURL, setExistingThumbnailImageUrl] = useState<string>();
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | null>(null);
  const [existingImages, setExistingImages] = useState<IImageGalleryImage[]>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const originalPOI = useRef<IPointOfInterest | null>(null);

  useEffect(() => {
    if (pointOfInterestId) {
      const poi = pointsOfInterest?.find((p) => p.id === Number(pointOfInterestId));
      if (poi) {
        setName(poi.name);
        setDescription(poi.description);
        setSelectedCategory(poi.categoryId);
        setTextLocation(poi.textLocation || '');
        setIsStage(poi.isStage);
        setIsDisplayedInList(poi.isDisplayedInList);
        setIsDisplayedOnMap(poi.isDisplayedOnMap);
        if (poi.imageGallery) {
          setExistingImages(poi.imageGallery.images);
          setExistingThumbnailImageUrl(poi.imageGallery.thumbnailImageUrl || undefined);
        }
        if (poi.mapLocation) {
          setSelectedAddress({ description: poi.mapLocation.address, structured_formatting: null, place_id: '0' });
          setMapCenter({ lat: poi.mapLocation.latitude, lng: poi.mapLocation.longitude });
          setMapMarkerPosition({ lat: poi.mapLocation.latitude, lng: poi.mapLocation.longitude });
        }
        originalPOI.current = poi;
      }
    }
  }, [pointsOfInterest, pointOfInterestId]);

  const handleMapClicked = (place: PlaceType, latLng: LatLng) => {
    setSelectedAddress(place);
    setDoNotMoveMarkerFlag(true);
    setMapMarkerPosition(latLng);
  };

  const deleteExistingImage = async (id: number) => {
    await deleteImages([id]);
    setExistingImages((prev) => prev.filter((x) => x.id !== id));
  };

  const removeImage = (file: File) => {
    const remove = newImages.findIndex((x) => file === x);
    const imagesTemp = [...newImages];
    imagesTemp.splice(remove, 1);
    setNewImages(imagesTemp);
  };

  const addImage = (file: File) => {
    const imagesTemp = [...newImages];
    imagesTemp.push(file);
    setNewImages(imagesTemp);
  };

  const handleCreate = async () => {
    if (!name || selectedCategory === '') return;
    setIsLoading(true);
    const mapData = {
      address: selectedAddress!.description,
      latitude: mapMarkerPosition!.lat,
      longitude: mapMarkerPosition!.lng,
    };
    const poi: IPointOfInterestCreation = {
      name,
      description,
      categoryId: selectedCategory,
      eventId: Number(eventId),
      textLocation,
      isStage,
      isDisplayedInList,
      isDisplayedOnMap,
    };
    const { imageGallery } = await createPOI({ poi, mapLocation: mapData });
    if (imageGallery) {
      if (newImages.length > 0) {
        await uploadImages({ id: imageGallery.id, images: newImages });
      }
      if (newThumbnailImage) {
        await replaceThumbnailImage({ id: imageGallery.id, thumbnailImage: newThumbnailImage });
      }
    }
    navigate(-1);
    setIsLoading(false);
  };

  const handleEdit = async () => {
    if (pointOfInterestId === undefined || !name || selectedCategory === '' || !originalPOI.current) return;
    setIsLoading(true);
    const mapData = {
      address: selectedAddress!.description,
      latitude: mapMarkerPosition!.lat,
      longitude: mapMarkerPosition!.lng,
    };
    const mapLocation =
      mapData.latitude !== originalPOI.current.mapLocation?.latitude ||
      mapData.longitude !== originalPOI.current.mapLocation?.longitude
        ? await createMapLocation(mapData)
        : null;
    const poi: IPointOfInterestUpdate = {
      name,
      description,
      mapLocationId: mapLocation?.id || undefined,
      categoryId: selectedCategory,
      eventId: Number(eventId),
      textLocation,
      isStage,
      isDisplayedInList,
      isDisplayedOnMap,
    };
    const { imageGallery } = await updatePOI({
      id: Number(pointOfInterestId),
      iPointOfInterestUpdateRequest: { pointOfInterest: poi },
    });
    if (imageGallery) {
      if (newImages.length > 0) {
        await uploadImages({ id: imageGallery.id, images: newImages });
      }
      if (newThumbnailImage) {
        await replaceThumbnailImage({ id: imageGallery.id, thumbnailImage: newThumbnailImage });
      }
    }
    navigate(-1);
    originalPOI.current = null;
    setIsLoading(false);
  };

  const onAutocompleteSelectPlace = (place: google.maps.places.PlaceResult | null) => {
    setDetailedAddress(place);
    if (!doNotMoveMarkerFlag) {
      setMapCenter({ lat: place?.geometry?.location?.lat() || 0, lng: place?.geometry?.location?.lng() || 0 });
      setMapMarkerPosition({
        lat: place?.geometry?.location?.lat() || 0,
        lng: place?.geometry?.location?.lng() || 0,
      });
    }
    setDoNotMoveMarkerFlag(false);
  };

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
      <Card style={{ width: '60%', borderRadius: 15, paddingBottom: 20 }}>
        <Box css={styles.header}>
          <Typography css={styles.headerTitle}>{name || 'Point of Interest'}</Typography>
        </Box>
        <Box css={styles.content}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            css={styles.textfield}
            size="small"
            label="Name"
          />
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            css={styles.textfield}
            size="small"
            label="Description"
            multiline
            rows={5}
          />
          <FormControl css={styles.textfield}>
            <InputLabel style={{ backgroundColor: 'white' }}>Category</InputLabel>
            <Select
              value={selectedCategory}
              sx={{ color: 'black' }}
              onChange={(e) => setSelectedCategory(e.target.value as number)}
            >
              {categories
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            value={textLocation}
            onChange={(e) => setTextLocation(e.target.value)}
            css={styles.textfield}
            style={{ marginBottom: 16 }}
            size="small"
            label="Simple Location"
          />

          <Box style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start', marginBottom: 8 }}>
            <Checkbox checked={isStage} onChange={(e) => setIsStage(e.target.checked)} style={{ paddingLeft: 0 }} />
            <Typography>POI is stage?</Typography>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start', marginBottom: 8 }}>
            <Checkbox
              checked={isDisplayedOnMap}
              onChange={(e) => setIsDisplayedOnMap(e.target.checked)}
              style={{ paddingLeft: 0 }}
            />
            <Typography>POI should display on event map?</Typography>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-start', marginBottom: 8 }}>
            <Checkbox
              checked={isDisplayedInList}
              onChange={(e) => setIsDisplayedInList(e.target.checked)}
              style={{ paddingLeft: 0 }}
            />
            <Typography>POI should display in vendors list?</Typography>
          </Box>

          <Typography css={styles.subtitle}>Location</Typography>
          <GooglePlacesAutocomplete
            onDetailedInfoLoad={onAutocompleteSelectPlace}
            onChange={(address) => setSelectedAddress(address)}
            value={selectedAddress}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          <Box style={{ marginBottom: 32 }}>
            <Map
              selectedAddressDetails={detailedAddress}
              onClickAndReverseGeocode={handleMapClicked}
              center={mapCenter}
              markerPosition={mapMarkerPosition}
            />
          </Box>
          {!existingThumbnailImageURL && !newThumbnailImage && (
            <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Upload Thumbnail" />
          )}
          {!!existingThumbnailImageURL && (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">Existing Thumbnail</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 16 }}>
                  <UploadedImage imageStyle={{ maxWidth: 350 }} src={existingThumbnailImageURL} />
                </div>
              </div>
              {!newThumbnailImage && (
                <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Replace Thumbnail" />
              )}
            </>
          )}
          {!!newThumbnailImage && (
            <div>
              <Typography variant="h6">New Thumbnail</Typography>
              <UploadedImage
                imageStyle={{ maxWidth: 350 }}
                onRemove={() => setNewThumbnailImage(null)}
                src={URL.createObjectURL(newThumbnailImage)}
              />
            </div>
          )}
          <div style={{ height: 16 }} />
          {existingImages.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">Existing Images</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {existingImages.map((x) => (
                  <div style={{ marginBottom: 16 }}>
                    <UploadedImage
                      imageStyle={{ maxWidth: 350 }}
                      onRemove={() => deleteExistingImage(x.id)}
                      src={x.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {newImages.length === 0 ? (
            <UploadBox onChange={(file) => setNewImages([file])} text="Upload Images" />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">New Images</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {newImages.map((x) => (
                  <div style={{ marginBottom: 16 }}>
                    <UploadedImage
                      imageStyle={{ maxWidth: 350 }}
                      onRemove={() => removeImage(x)}
                      src={URL.createObjectURL(x)}
                    />
                  </div>
                ))}
              </div>
              <UploadExtraImage onChange={(file) => addImage(file)} />
            </div>
          )}
          {!isLoading ? (
            <FilledButton
              disabled={!name || !selectedCategory}
              onClick={!isEditMode ? handleCreate : handleEdit}
              style={{ padding: '16px 48px', marginTop: 32 }}
            >
              {!isEditMode ? 'Create' : 'Update POI'}
            </FilledButton>
          ) : (
            <CircularProgress style={{ marginTop: 32 }} />
          )}
        </Box>
      </Card>
    </Box>
  );
}
