import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiDeleteActRequest } from '../autogenerated';

const deleteAct = (parameters: DefaultApiDeleteActRequest) =>
  getAuthenticatedApiClient()
    .deleteAct(parameters)
    .then((x) => x.data);

export const useDeleteAct = () => useMutation(deleteAct);
