import React from 'react';
import { css, ListItem, Typography } from '@mui/material';
import { IEvent } from '../api-client/autogenerated';
import FilledButton from './buttons/FilledButton';
import { useNavigate } from 'react-router-dom';

/** @jsxImportSource @emotion/react */

interface EventListItemProps {
  index: number;
  event: IEvent;
  onDelete: () => void;
}

const evenColor = 'white';
const oddColor = '#EEEEEE';

const styles = {
  listItem: css`
    height: 48px;
    width: 100%;
  `,
};

export default function EventListItem({ index, event, onDelete }: EventListItemProps) {
  const navigate = useNavigate();

  const sendEventToActsScreen = () => {
    navigate(`${event.id}/event-acts`);
  };

  const navigateToPOIScreen = () => {
    navigate(`${event.id}/points-of-interest`);
  };

  const navigateToEditScreen = () => {
    navigate(`/create-event/${event.id}`);
  };
  return (
    <ListItem sx={{ backgroundColor: index % 2 === 0 ? evenColor : oddColor }} css={styles.listItem}>
      <div style={{ paddingLeft: 32, paddingRight: 16, display: 'flex', width: '100%' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
          {event.name}
        </Typography>
        <FilledButton style={{ marginRight: 16, fontSize: 12 }} onClick={navigateToPOIScreen}>
          Manage POIs
        </FilledButton>
        <FilledButton style={{ marginRight: 16, fontSize: 12 }} onClick={sendEventToActsScreen}>
          Manage Acts
        </FilledButton>
        <FilledButton style={{ marginRight: 16, fontSize: 12 }} onClick={navigateToEditScreen}>
          Update
        </FilledButton>
        <FilledButton onClick={onDelete} style={{ fontSize: 12 }}>
          Delete
        </FilledButton>
      </div>
    </ListItem>
  );
}
