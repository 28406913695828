import {
  Card,
  CircularProgress,
  Divider,
  List,
  Pagination,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FilledButton from '../components/buttons/FilledButton';
import { useGetEvents } from '../api-client/hooks/useGetEvents';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventListItem from '../components/EventListItem';
import { IEvent, IEventSubmission } from '../api-client/autogenerated';
import { useDeleteEvent } from '../api-client/hooks/useDeleteEvent';
import { useGetEventSubmissions } from '../api-client/hooks/useGetEventSubmissions';
import EventSubmissionRow from '../components/EventSubmissionRow';
import { useDeleteEventSubmission } from '../api-client/hooks/useDeleteEventSubmission';

const pageSize = 20;

export default function EventSubmissionsPage() {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);

  const [selected, setSelected] = useState<number[]>([]);
  const { mutateAsync: deleteEventSubmission } = useDeleteEventSubmission();

  const [isDeleting, setIsDeleting] = useState(false);

  const { data: eventsData, refetch: refetchEventSubmissions } = useGetEventSubmissions({
    pageSize,
    page,
    orderBy: 'createdAt',
    searchString: searchInput,
  });
  const { mutateAsync: deleteEvent } = useDeleteEvent();

  useEffect(() => {
    setPage(1);
  }, [searchInput]);

  const events = eventsData?.data;

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  const selectRow = (submission: IEventSubmission) => {
    const newSelected = selected.filter((x) => x !== submission.id);
    if (newSelected.length === selected.length) {
      newSelected.push(submission.id);
    }
    setSelected(newSelected);
  };

  const rejectSubmission = async (id: number) => {
    await deleteEventSubmission({ id });
  };

  const rejectSelected = async () => {
    setIsDeleting(true);
    for await (const id of selected) {
      await rejectSubmission(Number(id));
    }
    setIsDeleting(false);
    refetchEventSubmissions();
    setSelected([]);
  };

  return (
    <Card sx={{ width: 959, minHeight: 64 }} elevation={4}>
      <Box
        sx={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <Typography variant="h5" style={{ color: 'black', fontWeight: 'bold' }}>
          Event Submissions
        </Typography>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginRight: 16 }}>
            <SearchIcon sx={{ position: 'absolute', left: 8 }} />
            <TextField
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Submissions"
              InputProps={{ sx: { paddingLeft: 3 } }}
              size="small"
            />
          </div>
        </div>
        {!isDeleting ? (
          <FilledButton color="error" disabled={selected.length === 0} onClick={rejectSelected}>
            Reject Selected ({selected.length})
          </FilledButton>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Divider style={{ backgroundColor: 'black' }} />
      <Table>
        <TableHead>
          <TableCell></TableCell>
          <TableCell>Event Name</TableCell>
          <TableCell>Submitter's Name</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableHead>
        {events?.map((x, index) => (
          <EventSubmissionRow selected={selected.includes(x.id)} onSelect={selectRow} submission={x} index={index} />
        ))}
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 8, paddingBottom: 16 }}>
        <Pagination count={eventsData?.totalPages} shape="rounded" page={page} onChange={handlePageChange} />
      </div>
    </Card>
  );
}
