import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiUpdatePointOfInterestRequest, IPointOfInterest } from '../autogenerated';
import { useMutation } from 'react-query';

const updatePOI = (request: DefaultApiUpdatePointOfInterestRequest): Promise<IPointOfInterest> =>
  getAuthenticatedApiClient()
    .updatePointOfInterest(request)
    .then((res) => res.data);

export const useUpdatePOI = () => useMutation('update-point-of-interest', updatePOI);
