import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { IActCreation, IMapLocationCreation } from '../autogenerated';

const createAct = (params: { act: IActCreation; mapLocation: IMapLocationCreation }) =>
  getAuthenticatedApiClient()
    .createAct({ iActCreationRequest: { act: params.act, imageGallery: {}, mapLocation: params.mapLocation } })
    .then((x) => x.data);

export const useCreateAct = () => useMutation(createAct);
