import { getAuthenticatedApiClient } from '../api-client';
import { IEventCategory, IEventCategoryCreation } from '../autogenerated';
import { useMutation } from 'react-query';

const createEventCategory = (eventCategory: IEventCategoryCreation): Promise<IEventCategory> =>
  getAuthenticatedApiClient()
    .createEventCategory({ iEventCategoryCreationRequest: { eventCategory } })
    .then((res) => res.data);

export const useCreateEventCategory = () => useMutation('create-event-category', createEventCategory);
