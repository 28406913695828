import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiDeletePointOfInterestCategoryRequest } from '../autogenerated';

const deletePointOfInterest = (parameters: DefaultApiDeletePointOfInterestCategoryRequest) =>
  getAuthenticatedApiClient()
    .deletePointOfInterest(parameters)
    .then((x) => x.data);

export const useDeletePointOfInterest = () => useMutation(deletePointOfInterest);
