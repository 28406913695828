import React from 'react';
import { css, ListItem, Typography } from '@mui/material';
import { IAct } from '../api-client/autogenerated';
import FilledButton from './buttons/FilledButton';
import { useNavigate } from 'react-router-dom';

/** @jsxImportSource @emotion/react */

interface ActListItemProps {
  index: number;
  act: IAct;
  onDelete: () => void;
}

const evenColor = 'white';
const oddColor = '#EEEEEE';

const styles = {
  listItem: css`
    height: 48px;
    width: 100%;
  `,
};

export default function ActListItem({ index, act, onDelete }: ActListItemProps) {
  const navigate = useNavigate();

  const sendEventToUpdateActsScreen = () => {
    navigate(`/events/${act.eventId}/update-event-act/${act.id}`);
  };

  return (
    <ListItem sx={{ backgroundColor: index % 2 === 0 ? evenColor : oddColor }} css={styles.listItem}>
      <div style={{ paddingLeft: 32, paddingRight: 16, display: 'flex', width: '100%' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
          {act.name}
        </Typography>
        <FilledButton style={{ marginRight: 16 }} onClick={sendEventToUpdateActsScreen}>
          Update
        </FilledButton>
        <FilledButton onClick={onDelete}>Delete</FilledButton>
      </div>
    </ListItem>
  );
}
