import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { Configuration, DefaultApi } from './autogenerated';
import { API_URL } from '../utils/env-setup';

const getCognitoJwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createApiClient = (getJwtToken?: () => Promise<string | null>) => {
  const axiosClient = axios.create({ baseURL: API_URL });
  axiosClient.interceptors.request.use(
    async (config) => {
      const jwtToken = getJwtToken ? await getJwtToken() : undefined;
      if (jwtToken && config.headers) config.headers.Authorization = `Bearer: ${jwtToken}`;
      return config;
    },
    (error) => {
      console.error('AUTH', error);
    },
  );
  return new DefaultApi(new Configuration(), API_URL, axiosClient);
};

let AuthenticatedApiClient = createApiClient(getCognitoJwtToken);

export const getAuthenticatedApiClient = (reset = false) => {
  if (!AuthenticatedApiClient || reset) AuthenticatedApiClient = createApiClient(getCognitoJwtToken);
  return AuthenticatedApiClient;
};
