import { Drawer } from '@mui/material';
import NavDrawer from './NavDrawer';

interface DashboardViewProps {
  children: any;
}

export default function DashboardView({ children }: DashboardViewProps) {
  return (
    <div style={{ display: 'flex', width: '100vw', minHeight: '100vh' }}>
      <NavDrawer />
      <div style={{ flexGrow: 1, backgroundColor: '#E3E3E3', display: 'flex', justifyContent: 'center', padding: 32 }}>
        {children}
      </div>
    </div>
  );
}
