import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiCreateManySponsorsRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const createSponsors = (request: DefaultApiCreateManySponsorsRequest) =>
  getAuthenticatedApiClient()
    .createManySponsors(request)
    .then((res) => res.data);

export const useCreateSponsors = () => useMutation('create-sponsors', createSponsors);
