import { IconButton } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { ChangeEvent, useRef } from 'react';

interface UploadExtraImage {
  onChange: (file: File) => void;
}

export default function UploadExtraImage({ onChange }: UploadExtraImage) {
  const ref = useRef<any>(null);
  const onClick = () => {
    ref?.current?.click();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.files?.[0]) onChange(e.currentTarget.files[0]);
  };
  return (
    <IconButton onClick={onClick} style={{ color: 'black', alignSelf: 'center' }}>
      <input onChange={(e) => handleChange(e)} style={{ display: 'none' }} ref={ref} type="file" accept="image/*" />
      <ControlPointIcon style={{ width: 32, height: 32 }} />
    </IconButton>
  );
}
