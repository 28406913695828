import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import LoginCard from '../components/LoginCard';
import { lightGray } from '../themes/colors';
/** @jsxImportSource @emotion/react */

const styles = {
  canvas: css`
    background-color: ${lightGray};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  space: css`
    width: 33%;
    height: 50%;
  `,
};

export default function LoginPage() {
  return (
    <div css={styles.canvas}>
      <div css={styles.space}>
        <LoginCard />
      </div>
    </div>
  );
}
