import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Typography,
} from '@mui/material';
import { getAuthenticatedApiClient } from '../api-client/api-client';
import { useEffect, useState } from 'react';
import UploadBox from '../components/UploadBox';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FilledButton from '../components/buttons/FilledButton';
import { useReplacePdf } from '../api-client/hooks/useReplacePdf';

export default function ChangePDFPage() {
  const [uploadedPdf, setUploadedPdf] = useState<File>();
  const [open, setOpen] = useState(false);
  const { mutateAsync: replacePdf, isLoading } = useReplacePdf();

  const submit = async () => {
    await replacePdf({ name: 'Gretna', aboutPdf: uploadedPdf });
    setUploadedPdf(undefined);
    setOpen(true);
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          PDF successfully replaced!
        </Alert>
      </Snackbar>
      <Card
        elevation={4}
        sx={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
          width: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!uploadedPdf ? (
          <UploadBox text="Upload New PDF" uploadPdf onChange={(e) => setUploadedPdf(e)} />
        ) : (
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <PictureAsPdfIcon style={{ width: 32, height: 32, color: 'gray', paddingRight: 16 }} />
              <Typography>{uploadedPdf.name}</Typography>
              {!isLoading && (
                <IconButton
                  onClick={() => setUploadedPdf(undefined)}
                  style={{ position: 'relative', top: -8, left: 8 }}
                  size="small"
                >
                  <CloseIcon style={{ width: 20, height: 20 }} />
                </IconButton>
              )}
            </Box>
            {!isLoading ? (
              <FilledButton onClick={submit} style={{ marginTop: 48, paddingLeft: 48, paddingRight: 48 }}>
                Submit
              </FilledButton>
            ) : (
              <Box
                style={{
                  paddingTop: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
                <Typography style={{ textAlign: 'center', paddingTop: 8 }}>Uploading new PDF...</Typography>
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
}
