import { Alert, Card, InputLabel, Snackbar, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useInviteUser } from '../api-client/hooks/useInviteUser';
import FilledButton from '../components/buttons/FilledButton';

export default function AddAdminPage() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const { mutateAsync: inviteUser } = useInviteUser();

  const handleAdd = async () => {
    if (email.includes('@') && name.length > 0) {
      await inviteUser({ createUserParams: { email, name } });
      setOpen(true);
      setName('');
      setEmail('');
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          User successfully invited!
        </Alert>
      </Snackbar>
      <Card sx={{ width: 600, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body2">
          <InputLabel sx={{ marginBottom: 4 }}>
            Enter the info below to give a user access to the admin portal.
          </InputLabel>
        </Typography>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ width: 300, marginBottom: 4 }}
          variant="outlined"
          label="Name"
        />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: 300 }}
          variant="outlined"
          label="Email"
        />
        <FilledButton onClick={handleAdd} style={{ marginTop: 48 }}>
          Add Admin
        </FilledButton>
      </Card>
    </div>
  );
}
