import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { IEvent, DefaultApiUpdateEventRequest } from '../autogenerated';

const updateEvent = (parameters: DefaultApiUpdateEventRequest): Promise<IEvent> =>
  getAuthenticatedApiClient()
    .updateEvent(parameters)
    .then((x) => x.data);

export const useUpdateEvent = () => useMutation(updateEvent);
