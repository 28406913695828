import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { IEvent, IEventCreation, IMapLocationCreation } from '../autogenerated';

const createEvent = (params: {
  event: IEventCreation;
  mapLocation: IMapLocationCreation;
  categoryIds?: number[];
}): Promise<IEvent> =>
  getAuthenticatedApiClient()
    .createEvent({
      iEventCreationRequest: {
        event: params.event,
        imageGallery: {},
        mapLocation: params.mapLocation,
        addToExistingCategoryIds: params.categoryIds,
      },
    })
    .then((x) => x.data);

export const useCreateEvent = () => useMutation(createEvent);
