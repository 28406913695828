import React, { useEffect, useState } from 'react';
import { Box, Card, IconButton, Pagination, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilledButton from '../components/buttons/FilledButton';
import { useGetEventCategories } from '../api-client/hooks/useGetEventCategories';
import { theme } from '../themes/CustomTheme';
import { css } from '@emotion/react';
import { useCreateEventCategory } from '../api-client/hooks/useCreateEventCategory';
import { useQueryClient } from 'react-query';
import { IEventCategory, IEventCategoryCount, IPointOfInterestCategory } from '../api-client/autogenerated';
import { useCreatePOICategory } from '../api-client/hooks/useCreatePOICategory';
import { useGetPOICategories } from '../api-client/hooks/useGetPOICategories';
import { useDeleteEventCategory } from '../api-client/hooks/useDeleteEventCategory';
import { useDeletePointOfInterestCategory } from '../api-client/hooks/useDeletePOICategory';
import { useUpdateEventCategory } from '../api-client/hooks/useUpdateEventCategory';
import { useUpdatePOICategory } from '../api-client/hooks/useUpdatePOICategory';
import UploadedImage from '../components/UploadedImage';
import UploadExtraImage from '../components/UploadExtraImage';
import { useReplaceEventCategoryThumbnailImage } from '../api-client/hooks/useReplaceEventCategoryThumbnailImage';

/** @jsxImportSource @emotion/react */

const styles = {
  column: css`
    display: flex;
    flex-direction: column;
    padding: 32px 10%;
  `,
  name: css`
    color: #000;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
  `,
  divider: css`
    width: 90%;
    height: 1px;
    background-color: #000;
    margin-left: 5%;
    margin-right: 5%;
  `,
};

interface GenericCategory {
  id: number;
  name: string;
  iconName?: string;
  thumbnailImageUrl?: string | null;
  _count?: IEventCategoryCount;
}

type Props = {
  type: 'event' | 'poi';
};

const PAGE_SIZE = 8;

export default function CreateTagsAndCategories(props: Props) {
  const { type } = props;

  const queryClient = useQueryClient();

  const { data: eventCategories, refetch: refreshEventCategories } = useGetEventCategories();
  const { data: poiCategories, refetch: refreshPOICategories } = useGetPOICategories();
  const { mutateAsync: createEventCategory } = useCreateEventCategory();
  const { mutateAsync: createPOICategory } = useCreatePOICategory();
  const { mutateAsync: updateEventCategory } = useUpdateEventCategory();
  const { mutateAsync: updatePOICategory } = useUpdatePOICategory();
  const { mutateAsync: deleteEventCategory } = useDeleteEventCategory();
  const { mutateAsync: deletePOICategory } = useDeletePointOfInterestCategory();
  const { mutateAsync: replaceEventCategoryThumbnailImage } = useReplaceEventCategoryThumbnailImage();

  const [value, setValue] = useState('');
  const [canEdit, setCanEdit] = useState(false);
  const [isEditingId, setIsEditingId] = useState(-1);
  const [editValue, setEditValue] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [newImage, setNewImage] = useState<File>();
  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItems] = useState<GenericCategory[]>([]);
  const [maxPage, setMaxPage] = useState(0);

  const categories = type === 'event' ? eventCategories : poiCategories;

  const handleCreateTag = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (type === 'event') {
        const newCategory = await createEventCategory({ name: value, thumbnailImageUrl: null, iconName: '' });
        queryClient.setQueryData<IEventCategory[]>('event-categories', (prev) =>
          prev ? [newCategory, ...prev] : [newCategory],
        );
      } else {
        const newCategory = await createPOICategory({ name: value });
        queryClient.setQueryData<IPointOfInterestCategory[]>('point-of-interest-categories', (prev) =>
          prev ? [newCategory, ...prev] : [newCategory],
        );
      }
      setValue('');
    }
  };

  const getFilteredEvents = () => {
    if (!categories) return [];
    let filtered = [...categories.sort((a, b) => a.name.localeCompare(b.name))];
    if (searchInput !== '') {
      filtered = filtered.filter((x) => x.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    const max = Math.ceil(filtered.length / PAGE_SIZE);
    setMaxPage(max);
    if (page >= max) {
      setPage(max - 1);
    }
    if (page < 0) {
      setPage(0);
    }

    filtered = filtered.slice(page * PAGE_SIZE, Math.min((page + 1) * PAGE_SIZE, categories!.length));
    return filtered;
  };

  useEffect(() => {
    setFilteredItems(getFilteredEvents());
  }, [page, searchInput, categories]);

  const handlePageChange = (e: any, value: number) => {
    setPage(value - 1);
  };

  const handleDelete = async (id: number, name: string) => {
    const proceed = window.confirm(`Are you sure you want to delete the category: ${name}?`);
    if (!proceed) return;
    if (type === 'event') {
      await deleteEventCategory(id);
      await refreshEventCategories();
    } else {
      await deletePOICategory(id);
      await refreshPOICategories();
    }
  };

  const handleStartEdit = (item: GenericCategory) => {
    setIsEditingId(item.id);
    setEditValue(item.name);
  };

  const handleEndEditing = () => {
    setIsEditingId(-1);
    setEditValue('');
    setNewImage(undefined);
    setCanEdit(false);
  };

  const handleSaveEdit = async (item: GenericCategory) => {
    if (type === 'event') {
      await updateEventCategory({ id: item.id, iEventCategoryUpdateRequest: { eventCategory: { name: editValue } } });
      if (newImage) {
        await replaceEventCategoryThumbnailImage({ id: item.id, thumbnailImage: newImage });
      }
      await refreshEventCategories();
    } else {
      await updatePOICategory({
        id: item.id,
        iPointOfInterestCategoryUpdateRequest: { pointOfInterestCategory: { name: editValue } },
      });
      await refreshPOICategories();
    }
    handleEndEditing();
  };

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
      <Card style={{ width: '70%', borderRadius: 15, paddingBottom: 20 }}>
        <Box
          sx={{
            height: 64,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'beige',
            paddingLeft: 4,
            paddingRight: 4,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" style={{ color: 'black', fontWeight: 'bold' }}>
            {type === 'event' ? 'Event' : 'POI'} Categories
          </Typography>
          <div>
            <TextField
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              InputProps={{
                sx: { paddingLeft: 5 },
                startAdornment: <SearchIcon sx={{ position: 'absolute', left: 8 }} />,
              }}
              size="small"
              style={{ backgroundColor: theme.palette.common.white }}
            />

            <IconButton style={{ justifyContent: 'flex-end' }}>
              <FilterListIcon />
            </IconButton>
          </div>
          <FilledButton disabled={canEdit} onClick={() => setCanEdit(true)}>
            Enable Edit
          </FilledButton>
        </Box>
        <Box css={styles.column}>
          <TextField
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            placeholder="Add a tag"
            size="small"
            onKeyDown={handleCreateTag}
          />
          <Typography>*Created tags will appear below</Typography>
        </Box>
        <Box css={styles.divider} />
        <Box css={styles.column}>
          {filteredItems?.map((item) => (
            <div
              key={item.id}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 32,
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {type === 'event' && isEditingId === item.id && (
                  <>
                    {!newImage ? (
                      <UploadExtraImage onChange={setNewImage} />
                    ) : (
                      <UploadedImage
                        src={URL.createObjectURL(newImage)}
                        imageStyle={{ width: 40, height: 40 }}
                        containerStyle={{ marginRight: 8 }}
                        onRemove={() => setNewImage(undefined)}
                      />
                    )}
                  </>
                )}
                {type === 'event' && isEditingId !== item.id && <div style={{ width: 48, height: 48 }} />}
                {type === 'event' && (
                  <UploadedImage
                    src={item.thumbnailImageUrl || undefined}
                    imageStyle={{ width: 40 }}
                    containerStyle={{ marginRight: 8 }}
                  />
                )}
                {isEditingId !== item.id ? (
                  <Typography key={item.id} css={styles.name}>
                    {item.name}
                  </Typography>
                ) : (
                  <TextField value={editValue} onChange={(e) => setEditValue(e.target.value)} />
                )}
              </Box>
              {canEdit && (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  {isEditingId !== item.id ? (
                    <FilledButton onClick={() => handleStartEdit(item)}>Edit</FilledButton>
                  ) : (
                    <FilledButton disabled={!editValue} onClick={() => handleSaveEdit(item)}>
                      Save
                    </FilledButton>
                  )}
                  <FilledButton
                    onClick={() => handleDelete(item.id, item.name)}
                    style={{ marginLeft: 8, backgroundColor: 'red' }}
                  >
                    Delete
                  </FilledButton>
                </Box>
              )}
            </div>
          ))}
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination count={maxPage} shape="rounded" page={page + 1} onChange={handlePageChange} />
        </div>
        {canEdit && (
          <FilledButton sx={{ width: '60%', marginTop: '10px', marginLeft: '20%' }} onClick={handleEndEditing}>
            Done
          </FilledButton>
        )}
      </Card>
    </Box>
  );
}
