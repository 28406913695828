export const formatMoney = (num: number | null) => {
  if (!num) return 'g';
  if (num < 100) {
    return `.${num.toFixed(2)}`;
  } else {
    const str = num.toString();
    const index = str.length - 3;
    return str.substring(0, index) + '.' + str.substring(index + 1, str.length - 1);
  }
};

export const localeDateTime = (date: Date) => {
  return date.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};
