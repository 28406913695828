import { Card, Divider, List, Pagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FilledButton from '../components/buttons/FilledButton';
import { useGetEvents } from '../api-client/hooks/useGetEvents';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IPointOfInterest } from '../api-client/autogenerated';
import { useGetPointsOfInterest } from '../api-client/hooks/useGetPointsOfInterest';
import PointOfInterestItem from '../components/PointOfInterestItem';
import { useDeletePointOfInterest } from '../api-client/hooks/useDeletePointOfInterest';

export default function PointsOfInterestPage() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [filteredPOIs, setFilteredPOIs] = useState<IPointOfInterest[]>([]);
  const [maxPage, setMaxPage] = useState(0);

  const { data: POIs, refetch: refetchPOIs } = useGetPointsOfInterest({ id: parseInt(eventId!) });
  const { data: eventsData } = useGetEvents({ id: parseInt(eventId!) });

  const events = eventsData?.data;

  const getFilteredPOIs = () => {
    if (!POIs) return [];
    let filtered = [...POIs.sort((a, b) => a.name.localeCompare(b.name))];
    if (searchInput !== '') {
      filtered = filtered.filter((x) => x.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    const max = Math.ceil(filtered.length / 20);
    setMaxPage(max);
    if (page >= max) {
      setPage(max - 1);
    }
    if (page < 0) {
      setPage(0);
    }

    filtered = filtered.slice(page * 20, Math.min((page + 1) * 20, POIs.length));
    return filtered;
  };

  const { mutateAsync: deletePOI } = useDeletePointOfInterest();

  useEffect(() => {
    setFilteredPOIs(getFilteredPOIs());
  }, [page, searchInput, POIs]);

  const handlePageChange = (e: any, value: number) => {
    setPage(value - 1);
  };

  const handleDeletePressed = async (POI: IPointOfInterest) => {
    await deletePOI(POI);
    refetchPOIs();
  };

  return (
    <Card sx={{ width: 959, minHeight: 64 }} elevation={4}>
      <Box
        sx={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <Typography variant="h5" style={{ color: 'black', fontWeight: 'bold' }}>
          {events?.[0]?.name} POIs
        </Typography>
        <FilledButton onClick={() => navigate(`/events/${eventId}/create-pois`)}>Create POI</FilledButton>
      </Box>
      <Divider style={{ backgroundColor: 'black' }} />
      <List>
        {filteredPOIs.map((x, index) => (
          <PointOfInterestItem onDelete={() => handleDeletePressed(x)} index={index} POI={x} />
        ))}
      </List>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={maxPage} shape="rounded" page={page + 1} onChange={handlePageChange} />
      </div>
    </Card>
  );
}
