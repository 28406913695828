import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiUpdateActRequest } from '../autogenerated';

const updateAct = (params: DefaultApiUpdateActRequest) =>
  getAuthenticatedApiClient()
    .updateAct(params)
    .then((x) => x.data);

export const useUpdateAct = () => useMutation(updateAct);
