/* tslint:disable */
/* eslint-disable */
/**
 * gretna-live-api-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AttributeType
 */
export interface AttributeType {
  /**
   *
   * @type {string}
   * @memberof AttributeType
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof AttributeType
   */
  Value?: string;
}
/**
 *
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserParams
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
  /**
   *
   * @type {UserType}
   * @memberof CreateUserResponse
   */
  User?: UserType;
}
/**
 *
 * @export
 * @interface DeliveryMediumType
 */
export interface DeliveryMediumType {}
/**
 *
 * @export
 * @enum {string}
 */

export const DeliveryMediumTypeAnyOf = {
  Sms: 'SMS',
  Email: 'EMAIL',
} as const;

export type DeliveryMediumTypeAnyOf = typeof DeliveryMediumTypeAnyOf[keyof typeof DeliveryMediumTypeAnyOf];

/**
 *
 * @export
 * @interface IAct
 */
export interface IAct {
  /**
   * Database Integer
   * @type {number}
   * @memberof IAct
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IAct
   */
  eventId: number;
  /**
   *
   * @type {number}
   * @memberof IAct
   */
  mapLocationId: number | null;
  /**
   *
   * @type {string}
   * @memberof IAct
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IAct
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof IAct
   */
  textLocation: string;
  /**
   *
   * @type {string}
   * @memberof IAct
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IAct
   */
  endsAt: string;
  /**
   *
   * @type {IActImageGallery}
   * @memberof IAct
   */
  imageGallery: IActImageGallery | null;
  /**
   *
   * @type {IActMapLocation}
   * @memberof IAct
   */
  mapLocation: IActMapLocation | null;
}
/**
 *
 * @export
 * @interface IActCreation
 */
export interface IActCreation {
  /**
   *
   * @type {string}
   * @memberof IActCreation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IActCreation
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof IActCreation
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IActCreation
   */
  endsAt: string;
  /**
   * Database Integer
   * @type {number}
   * @memberof IActCreation
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof IActCreation
   */
  textLocation: string;
}
/**
 *
 * @export
 * @interface IActCreationRequest
 */
export interface IActCreationRequest {
  /**
   *
   * @type {IActCreation}
   * @memberof IActCreationRequest
   */
  act: IActCreation;
  /**
   *
   * @type {object}
   * @memberof IActCreationRequest
   */
  imageGallery: object;
  /**
   *
   * @type {IMapLocationCreation}
   * @memberof IActCreationRequest
   */
  mapLocation?: IMapLocationCreation;
}
/**
 *
 * @export
 * @interface IActImageGallery
 */
export interface IActImageGallery {
  /**
   * Database Integer
   * @type {number}
   * @memberof IActImageGallery
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof IActImageGallery
   */
  actId: number | null;
  /**
   *
   * @type {number}
   * @memberof IActImageGallery
   */
  eventId: number | null;
  /**
   *
   * @type {number}
   * @memberof IActImageGallery
   */
  pointOfInterestId: number | null;
  /**
   *
   * @type {string}
   * @memberof IActImageGallery
   */
  thumbnailImageUrl: string | null;
  /**
   *
   * @type {Array<IImageGalleryImage>}
   * @memberof IActImageGallery
   */
  images: Array<IImageGalleryImage>;
}
/**
 *
 * @export
 * @interface IActMapLocation
 */
export interface IActMapLocation {
  /**
   * Database Integer
   * @type {number}
   * @memberof IActMapLocation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IActMapLocation
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof IActMapLocation
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof IActMapLocation
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface IActUpdate
 */
export interface IActUpdate {
  /**
   *
   * @type {string}
   * @memberof IActUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IActUpdate
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof IActUpdate
   */
  startsAt?: string;
  /**
   *
   * @type {string}
   * @memberof IActUpdate
   */
  endsAt?: string;
  /**
   *
   * @type {number}
   * @memberof IActUpdate
   */
  mapLocationId?: number | null;
  /**
   * Database Integer
   * @type {number}
   * @memberof IActUpdate
   */
  eventId?: number;
  /**
   *
   * @type {string}
   * @memberof IActUpdate
   */
  textLocation?: string;
}
/**
 *
 * @export
 * @interface IActUpdateRequest
 */
export interface IActUpdateRequest {
  /**
   *
   * @type {IActUpdate}
   * @memberof IActUpdateRequest
   */
  act: IActUpdate;
}
/**
 *
 * @export
 * @interface IBaseEventCategory
 */
export interface IBaseEventCategory {
  /**
   * Database Integer
   * @type {number}
   * @memberof IBaseEventCategory
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IBaseEventCategory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IBaseEventCategory
   */
  iconName: string;
  /**
   *
   * @type {string}
   * @memberof IBaseEventCategory
   */
  thumbnailImageUrl: string | null;
}
/**
 *
 * @export
 * @interface IEmailListEntry
 */
export interface IEmailListEntry {
  /**
   *
   * @type {string}
   * @memberof IEmailListEntry
   */
  email: string;
}
/**
 *
 * @export
 * @interface IEmailListEntryCreation
 */
export interface IEmailListEntryCreation {
  /**
   *
   * @type {string}
   * @memberof IEmailListEntryCreation
   */
  email: string;
}
/**
 *
 * @export
 * @interface IEmailListEntryDeletion
 */
export interface IEmailListEntryDeletion {
  /**
   *
   * @type {string}
   * @memberof IEmailListEntryDeletion
   */
  email: string;
}
/**
 *
 * @export
 * @interface IEvent
 */
export interface IEvent {
  /**
   *
   * @type {Array<IBaseEventCategory>}
   * @memberof IEvent
   */
  categories: Array<IBaseEventCategory>;
  /**
   *
   * @type {IActMapLocation}
   * @memberof IEvent
   */
  mapLocation: IActMapLocation | null;
  /**
   *
   * @type {IActImageGallery}
   * @memberof IEvent
   */
  imageGallery: IActImageGallery | null;
  /**
   *
   * @type {IEventEventSubmission}
   * @memberof IEvent
   */
  eventSubmission?: IEventEventSubmission | null;
  /**
   * Stringified hex code E.g. 1AFFa1
   * @type {string}
   * @memberof IEvent
   */
  primaryColor: string;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  secondaryColor: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  accentColor: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  complimentaryColor: string | null;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEvent
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof IEvent
   */
  ticketPriceMinCents: number | null;
  /**
   *
   * @type {number}
   * @memberof IEvent
   */
  ticketPriceMaxCents: number | null;
  /**
   *
   * @type {number}
   * @memberof IEvent
   */
  mapLocationId: number | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  hostName: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  actScheduleName: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  ticketPurchaseLink: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  instructions: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  endsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  displayAfter: string | null;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  hideAfter: string;
  /**
   *
   * @type {string}
   * @memberof IEvent
   */
  mapStaticImageUrl: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IEvent
   */
  isSubmissionDraft: boolean;
}
/**
 *
 * @export
 * @interface IEventCategory
 */
export interface IEventCategory {
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventCategory
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IEventCategory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IEventCategory
   */
  iconName: string;
  /**
   *
   * @type {string}
   * @memberof IEventCategory
   */
  thumbnailImageUrl: string | null;
  /**
   *
   * @type {IEventCategoryCount}
   * @memberof IEventCategory
   */
  _count: IEventCategoryCount;
}
/**
 *
 * @export
 * @interface IEventCategoryCount
 */
export interface IEventCategoryCount {
  /**
   *
   * @type {number}
   * @memberof IEventCategoryCount
   */
  events: number;
}
/**
 *
 * @export
 * @interface IEventCategoryCreation
 */
export interface IEventCategoryCreation {
  /**
   *
   * @type {string}
   * @memberof IEventCategoryCreation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IEventCategoryCreation
   */
  thumbnailImageUrl: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCategoryCreation
   */
  iconName: string;
}
/**
 *
 * @export
 * @interface IEventCategoryCreationRequest
 */
export interface IEventCategoryCreationRequest {
  /**
   *
   * @type {IEventCategoryCreation}
   * @memberof IEventCategoryCreationRequest
   */
  eventCategory: IEventCategoryCreation;
}
/**
 *
 * @export
 * @interface IEventCategoryUpdate
 */
export interface IEventCategoryUpdate {
  /**
   *
   * @type {string}
   * @memberof IEventCategoryUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IEventCategoryUpdate
   */
  thumbnailImageUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCategoryUpdate
   */
  iconName?: string;
}
/**
 *
 * @export
 * @interface IEventCategoryUpdateRequest
 */
export interface IEventCategoryUpdateRequest {
  /**
   *
   * @type {IEventCategoryUpdate}
   * @memberof IEventCategoryUpdateRequest
   */
  eventCategory: IEventCategoryUpdate;
}
/**
 *
 * @export
 * @interface IEventCreation
 */
export interface IEventCreation {
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  hostName: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  actScheduleName: string | null;
  /**
   *
   * @type {number}
   * @memberof IEventCreation
   */
  ticketPriceMinCents: number | null;
  /**
   *
   * @type {number}
   * @memberof IEventCreation
   */
  ticketPriceMaxCents: number | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  ticketPurchaseLink: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  instructions: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  endsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  displayAfter: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  hideAfter: string;
  /**
   * Stringified hex code E.g. 1AFFa1
   * @type {string}
   * @memberof IEventCreation
   */
  primaryColor: string;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  secondaryColor: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  accentColor: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  complimentaryColor: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventCreation
   */
  mapStaticImageUrl: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IEventCreation
   */
  isSubmissionDraft: boolean;
}
/**
 *
 * @export
 * @interface IEventCreationRequest
 */
export interface IEventCreationRequest {
  /**
   *
   * @type {IEventCreation}
   * @memberof IEventCreationRequest
   */
  event: IEventCreation;
  /**
   *
   * @type {object}
   * @memberof IEventCreationRequest
   */
  imageGallery: object;
  /**
   *
   * @type {IMapLocationCreation}
   * @memberof IEventCreationRequest
   */
  mapLocation?: IMapLocationCreation;
  /**
   *
   * @type {Array<number>}
   * @memberof IEventCreationRequest
   */
  addToExistingCategoryIds?: Array<number>;
}
/**
 *
 * @export
 * @interface IEventDay
 */
export interface IEventDay {
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventDay
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventDay
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof IEventDay
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventDay
   */
  endsAt: string;
}
/**
 *
 * @export
 * @interface IEventDayCreation
 */
export interface IEventDayCreation {
  /**
   *
   * @type {string}
   * @memberof IEventDayCreation
   */
  startsAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventDayCreation
   */
  endsAt: string;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventDayCreation
   */
  eventId: number;
}
/**
 *
 * @export
 * @interface IEventDayCreationRequest
 */
export interface IEventDayCreationRequest {
  /**
   *
   * @type {IEventDayCreation}
   * @memberof IEventDayCreationRequest
   */
  eventDay: IEventDayCreation;
}
/**
 *
 * @export
 * @interface IEventDayUpdate
 */
export interface IEventDayUpdate {
  /**
   *
   * @type {string}
   * @memberof IEventDayUpdate
   */
  startsAt?: string;
  /**
   *
   * @type {string}
   * @memberof IEventDayUpdate
   */
  endsAt?: string;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventDayUpdate
   */
  eventId?: number;
}
/**
 *
 * @export
 * @interface IEventDayUpdateRequest
 */
export interface IEventDayUpdateRequest {
  /**
   *
   * @type {IEventDayUpdate}
   * @memberof IEventDayUpdateRequest
   */
  eventDay: IEventDayUpdate;
}
/**
 *
 * @export
 * @interface IEventEventSubmission
 */
export interface IEventEventSubmission {
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventEventSubmission
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventEventSubmission
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof IEventEventSubmission
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventEventSubmission
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof IEventEventSubmission
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof IEventEventSubmission
   */
  contactPhone: string;
  /**
   *
   * @type {IEvent}
   * @memberof IEventEventSubmission
   */
  event?: IEvent;
}
/**
 *
 * @export
 * @interface IEventGetAllResponse
 */
export interface IEventGetAllResponse {
  /**
   * Positive Integer
   * @type {number}
   * @memberof IEventGetAllResponse
   */
  totalPages: number;
  /**
   * Positive Integer
   * @type {number}
   * @memberof IEventGetAllResponse
   */
  page: number;
  /**
   *
   * @type {Array<IEvent>}
   * @memberof IEventGetAllResponse
   */
  data: Array<IEvent>;
}
/**
 *
 * @export
 * @interface IEventSubmission
 */
export interface IEventSubmission {
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventSubmission
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IEventSubmission
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof IEventSubmission
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof IEventSubmission
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof IEventSubmission
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof IEventSubmission
   */
  contactPhone: string;
  /**
   *
   * @type {IEvent}
   * @memberof IEventSubmission
   */
  event?: IEvent;
}
/**
 *
 * @export
 * @interface IEventSubmissionCreation
 */
export interface IEventSubmissionCreation {
  /**
   *
   * @type {string}
   * @memberof IEventSubmissionCreation
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof IEventSubmissionCreation
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof IEventSubmissionCreation
   */
  contactPhone: string;
}
/**
 *
 * @export
 * @interface IEventSubmissionCreationRequest
 */
export interface IEventSubmissionCreationRequest {
  /**
   *
   * @type {IEventCreation}
   * @memberof IEventSubmissionCreationRequest
   */
  event: IEventCreation;
  /**
   *
   * @type {object}
   * @memberof IEventSubmissionCreationRequest
   */
  imageGallery: object;
  /**
   *
   * @type {IMapLocationCreation}
   * @memberof IEventSubmissionCreationRequest
   */
  mapLocation?: IMapLocationCreation;
  /**
   *
   * @type {Array<number>}
   * @memberof IEventSubmissionCreationRequest
   */
  addToExistingCategoryIds?: Array<number>;
  /**
   *
   * @type {IEventSubmissionCreation}
   * @memberof IEventSubmissionCreationRequest
   */
  eventSubmission: IEventSubmissionCreation;
}
/**
 *
 * @export
 * @interface IEventSubmissionGetAllResponse
 */
export interface IEventSubmissionGetAllResponse {
  /**
   * Positive Integer
   * @type {number}
   * @memberof IEventSubmissionGetAllResponse
   */
  totalPages: number;
  /**
   * Positive Integer
   * @type {number}
   * @memberof IEventSubmissionGetAllResponse
   */
  page: number;
  /**
   *
   * @type {Array<RequiredIEventSubmission>}
   * @memberof IEventSubmissionGetAllResponse
   */
  data: Array<RequiredIEventSubmission>;
}
/**
 *
 * @export
 * @interface IEventUpdate
 */
export interface IEventUpdate {
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  hostName?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  actScheduleName?: string | null;
  /**
   *
   * @type {number}
   * @memberof IEventUpdate
   */
  ticketPriceMinCents?: number | null;
  /**
   *
   * @type {number}
   * @memberof IEventUpdate
   */
  ticketPriceMaxCents?: number | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  ticketPurchaseLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  instructions?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  startsAt?: string;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  endsAt?: string;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  displayAfter?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  hideAfter?: string;
  /**
   * Stringified hex code E.g. 1AFFa1
   * @type {string}
   * @memberof IEventUpdate
   */
  primaryColor?: string;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  secondaryColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  accentColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  complimentaryColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof IEventUpdate
   */
  mapStaticImageUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof IEventUpdate
   */
  mapLocationId?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof IEventUpdate
   */
  isSubmissionDraft?: boolean;
}
/**
 *
 * @export
 * @interface IEventUpdateRequest
 */
export interface IEventUpdateRequest {
  /**
   *
   * @type {IEventUpdate}
   * @memberof IEventUpdateRequest
   */
  event: IEventUpdate;
  /**
   *
   * @type {Array<number>}
   * @memberof IEventUpdateRequest
   */
  addToCategories?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof IEventUpdateRequest
   */
  removeFromCategories?: Array<number>;
}
/**
 *
 * @export
 * @interface IGenericPage
 */
export interface IGenericPage {
  /**
   * Database Integer
   * @type {number}
   * @memberof IGenericPage
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IGenericPage
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof IGenericPage
   */
  html: string | null;
  /**
   *
   * @type {string}
   * @memberof IGenericPage
   */
  markdown: string | null;
}
/**
 *
 * @export
 * @interface IGenericPageCreation
 */
export interface IGenericPageCreation {
  /**
   *
   * @type {string}
   * @memberof IGenericPageCreation
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof IGenericPageCreation
   */
  html: string | null;
  /**
   *
   * @type {string}
   * @memberof IGenericPageCreation
   */
  markdown: string | null;
}
/**
 *
 * @export
 * @interface IGenericPageCreationRequest
 */
export interface IGenericPageCreationRequest {
  /**
   *
   * @type {IGenericPageCreation}
   * @memberof IGenericPageCreationRequest
   */
  genericPage: IGenericPageCreation;
}
/**
 *
 * @export
 * @interface IGenericPageUpdate
 */
export interface IGenericPageUpdate {
  /**
   *
   * @type {string}
   * @memberof IGenericPageUpdate
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof IGenericPageUpdate
   */
  html?: string | null;
  /**
   *
   * @type {string}
   * @memberof IGenericPageUpdate
   */
  markdown?: string | null;
}
/**
 *
 * @export
 * @interface IGenericPageUpdateRequest
 */
export interface IGenericPageUpdateRequest {
  /**
   *
   * @type {IGenericPageUpdate}
   * @memberof IGenericPageUpdateRequest
   */
  genericPage: IGenericPageUpdate;
}
/**
 *
 * @export
 * @interface IImageGallery
 */
export interface IImageGallery {
  /**
   * Database Integer
   * @type {number}
   * @memberof IImageGallery
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof IImageGallery
   */
  actId: number | null;
  /**
   *
   * @type {number}
   * @memberof IImageGallery
   */
  eventId: number | null;
  /**
   *
   * @type {number}
   * @memberof IImageGallery
   */
  pointOfInterestId: number | null;
  /**
   *
   * @type {string}
   * @memberof IImageGallery
   */
  thumbnailImageUrl: string | null;
  /**
   *
   * @type {Array<IImageGalleryImage>}
   * @memberof IImageGallery
   */
  images: Array<IImageGalleryImage>;
}
/**
 *
 * @export
 * @interface IImageGalleryDeleteManyRequest
 */
export interface IImageGalleryDeleteManyRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof IImageGalleryDeleteManyRequest
   */
  ids: Array<number>;
}
/**
 *
 * @export
 * @interface IImageGalleryImage
 */
export interface IImageGalleryImage {
  /**
   * Database Integer
   * @type {number}
   * @memberof IImageGalleryImage
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IImageGalleryImage
   */
  imageGalleryId: number;
  /**
   * Positive Integer
   * @type {number}
   * @memberof IImageGalleryImage
   */
  sortPosition: number;
  /**
   *
   * @type {string}
   * @memberof IImageGalleryImage
   */
  url: string;
}
/**
 *
 * @export
 * @interface IImageGalleryImageUpdate
 */
export interface IImageGalleryImageUpdate {
  /**
   * Positive Integer
   * @type {number}
   * @memberof IImageGalleryImageUpdate
   */
  sortPosition?: number;
}
/**
 *
 * @export
 * @interface IImageGalleryImageUpdateManyRequest
 */
export interface IImageGalleryImageUpdateManyRequest {
  /**
   *
   * @type {Array<IImageGalleryImageUpdateManyRequestImagePatchesInner>}
   * @memberof IImageGalleryImageUpdateManyRequest
   */
  imagePatches: Array<IImageGalleryImageUpdateManyRequestImagePatchesInner>;
}
/**
 *
 * @export
 * @interface IImageGalleryImageUpdateManyRequestImagePatchesInner
 */
export interface IImageGalleryImageUpdateManyRequestImagePatchesInner {
  /**
   *
   * @type {IImageGalleryImageUpdate}
   * @memberof IImageGalleryImageUpdateManyRequestImagePatchesInner
   */
  patch: IImageGalleryImageUpdate;
  /**
   * Database Integer
   * @type {number}
   * @memberof IImageGalleryImageUpdateManyRequestImagePatchesInner
   */
  id: number;
}
/**
 *
 * @export
 * @interface IMapLocation
 */
export interface IMapLocation {
  /**
   * Database Integer
   * @type {number}
   * @memberof IMapLocation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IMapLocation
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof IMapLocation
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof IMapLocation
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface IMapLocationCreation
 */
export interface IMapLocationCreation {
  /**
   *
   * @type {string}
   * @memberof IMapLocationCreation
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof IMapLocationCreation
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof IMapLocationCreation
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface IMapLocationCreationRequest
 */
export interface IMapLocationCreationRequest {
  /**
   *
   * @type {IMapLocationCreation}
   * @memberof IMapLocationCreationRequest
   */
  mapLocation: IMapLocationCreation;
}
/**
 *
 * @export
 * @interface IMapLocationNullable
 */
export interface IMapLocationNullable {
  /**
   * Database Integer
   * @type {number}
   * @memberof IMapLocationNullable
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IMapLocationNullable
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof IMapLocationNullable
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof IMapLocationNullable
   */
  longitude: number;
}
/**
 *
 * @export
 * @interface IMapLocationUpdate
 */
export interface IMapLocationUpdate {
  /**
   *
   * @type {string}
   * @memberof IMapLocationUpdate
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof IMapLocationUpdate
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof IMapLocationUpdate
   */
  longitude?: number;
}
/**
 *
 * @export
 * @interface IMapLocationUpdateRequest
 */
export interface IMapLocationUpdateRequest {
  /**
   *
   * @type {IMapLocationUpdate}
   * @memberof IMapLocationUpdateRequest
   */
  mapLocation: IMapLocationUpdate;
}
/**
 *
 * @export
 * @interface IOrganizer
 */
export interface IOrganizer {
  /**
   * Database Integer
   * @type {number}
   * @memberof IOrganizer
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IOrganizer
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IOrganizer
   */
  aboutPdfUrl: string | null;
}
/**
 *
 * @export
 * @interface IOrganizerCreation
 */
export interface IOrganizerCreation {
  /**
   *
   * @type {string}
   * @memberof IOrganizerCreation
   */
  name: string;
}
/**
 *
 * @export
 * @interface IOrganizerCreationRequest
 */
export interface IOrganizerCreationRequest {
  /**
   *
   * @type {IOrganizerCreation}
   * @memberof IOrganizerCreationRequest
   */
  organizer: IOrganizerCreation;
}
/**
 *
 * @export
 * @interface IPointOfInterest
 */
export interface IPointOfInterest {
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterest
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterest
   */
  eventId: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterest
   */
  categoryId: number;
  /**
   *
   * @type {number}
   * @memberof IPointOfInterest
   */
  mapLocationId: number | null;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterest
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterest
   */
  isStage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterest
   */
  isDisplayedOnMap: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterest
   */
  isDisplayedInList: boolean;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterest
   */
  textLocation: string | null;
  /**
   *
   * @type {IActImageGallery}
   * @memberof IPointOfInterest
   */
  imageGallery: IActImageGallery | null;
  /**
   *
   * @type {IActMapLocation}
   * @memberof IPointOfInterest
   */
  mapLocation: IActMapLocation | null;
}
/**
 *
 * @export
 * @interface IPointOfInterestCategory
 */
export interface IPointOfInterestCategory {
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterestCategory
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCategory
   */
  name: string;
}
/**
 *
 * @export
 * @interface IPointOfInterestCategoryCreation
 */
export interface IPointOfInterestCategoryCreation {
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCategoryCreation
   */
  name: string;
}
/**
 *
 * @export
 * @interface IPointOfInterestCategoryCreationRequest
 */
export interface IPointOfInterestCategoryCreationRequest {
  /**
   *
   * @type {IPointOfInterestCategoryCreation}
   * @memberof IPointOfInterestCategoryCreationRequest
   */
  pointOfInterestCategory: IPointOfInterestCategoryCreation;
}
/**
 *
 * @export
 * @interface IPointOfInterestCategoryUpdate
 */
export interface IPointOfInterestCategoryUpdate {
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCategoryUpdate
   */
  name?: string;
}
/**
 *
 * @export
 * @interface IPointOfInterestCategoryUpdateRequest
 */
export interface IPointOfInterestCategoryUpdateRequest {
  /**
   *
   * @type {IPointOfInterestCategoryUpdate}
   * @memberof IPointOfInterestCategoryUpdateRequest
   */
  pointOfInterestCategory: IPointOfInterestCategoryUpdate;
}
/**
 *
 * @export
 * @interface IPointOfInterestCreation
 */
export interface IPointOfInterestCreation {
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCreation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCreation
   */
  description: string;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterestCreation
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestCreation
   */
  textLocation: string | null;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterestCreation
   */
  categoryId: number;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestCreation
   */
  isStage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestCreation
   */
  isDisplayedOnMap: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestCreation
   */
  isDisplayedInList: boolean;
}
/**
 *
 * @export
 * @interface IPointOfInterestCreationRequest
 */
export interface IPointOfInterestCreationRequest {
  /**
   *
   * @type {IPointOfInterestCreation}
   * @memberof IPointOfInterestCreationRequest
   */
  pointOfInterest: IPointOfInterestCreation;
  /**
   *
   * @type {object}
   * @memberof IPointOfInterestCreationRequest
   */
  imageGallery: object;
  /**
   *
   * @type {IMapLocationCreation}
   * @memberof IPointOfInterestCreationRequest
   */
  mapLocation?: IMapLocationCreation;
}
/**
 *
 * @export
 * @interface IPointOfInterestUpdate
 */
export interface IPointOfInterestUpdate {
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestUpdate
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof IPointOfInterestUpdate
   */
  mapLocationId?: number | null;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterestUpdate
   */
  eventId?: number;
  /**
   *
   * @type {string}
   * @memberof IPointOfInterestUpdate
   */
  textLocation?: string | null;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPointOfInterestUpdate
   */
  categoryId?: number;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestUpdate
   */
  isStage?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestUpdate
   */
  isDisplayedOnMap?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IPointOfInterestUpdate
   */
  isDisplayedInList?: boolean;
}
/**
 *
 * @export
 * @interface IPointOfInterestUpdateRequest
 */
export interface IPointOfInterestUpdateRequest {
  /**
   *
   * @type {IPointOfInterestUpdate}
   * @memberof IPointOfInterestUpdateRequest
   */
  pointOfInterest: IPointOfInterestUpdate;
}
/**
 *
 * @export
 * @interface IPushNotificationCreation
 */
export interface IPushNotificationCreation {
  /**
   *
   * @type {PushNotificationTopic}
   * @memberof IPushNotificationCreation
   */
  topic: PushNotificationTopic;
  /**
   *
   * @type {string}
   * @memberof IPushNotificationCreation
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof IPushNotificationCreation
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof IPushNotificationCreation
   */
  imageUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof IPushNotificationCreation
   */
  dryRun: boolean;
  /**
   * Database Integer
   * @type {number}
   * @memberof IPushNotificationCreation
   */
  eventId?: number;
}
/**
 *
 * @export
 * @interface ISponsor
 */
export interface ISponsor {
  /**
   * Database Integer
   * @type {number}
   * @memberof ISponsor
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ISponsor
   */
  logoImageUrl: string;
  /**
   * Database Integer
   * @type {number}
   * @memberof ISponsor
   */
  eventId: number;
}
/**
 *
 * @export
 * @interface ISponsorUpdate
 */
export interface ISponsorUpdate {
  /**
   * Database Integer
   * @type {number}
   * @memberof ISponsorUpdate
   */
  eventId?: number;
}
/**
 *
 * @export
 * @interface ISponsorUpdateRequest
 */
export interface ISponsorUpdateRequest {
  /**
   *
   * @type {ISponsorUpdate}
   * @memberof ISponsorUpdateRequest
   */
  sponsor: ISponsorUpdate;
}
/**
 *
 * @export
 * @enum {string}
 */

export const KnownGenericPageSlug = {
  FerryInfo: 'ferry-info',
} as const;

export type KnownGenericPageSlug = typeof KnownGenericPageSlug[keyof typeof KnownGenericPageSlug];

/**
 *
 * @export
 * @interface ListUsersResponse
 */
export interface ListUsersResponse {
  /**
   *
   * @type {Array<UserType>}
   * @memberof ListUsersResponse
   */
  Users?: Array<UserType>;
  /**
   *
   * @type {string}
   * @memberof ListUsersResponse
   */
  PaginationToken?: string;
}
/**
 *
 * @export
 * @interface MFAOptionType
 */
export interface MFAOptionType {
  /**
   *
   * @type {DeliveryMediumType}
   * @memberof MFAOptionType
   */
  DeliveryMedium?: DeliveryMediumType;
  /**
   *
   * @type {string}
   * @memberof MFAOptionType
   */
  AttributeName?: string;
}
/**
 * Model Organizer
 * @export
 * @interface Organizer
 */
export interface Organizer {
  /**
   *
   * @type {string}
   * @memberof Organizer
   */
  aboutPdfUrl: string | null;
  /**
   *
   * @type {string}
   * @memberof Organizer
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Organizer
   */
  id: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrganizerNames = {
  Gretna: 'Gretna',
} as const;

export type OrganizerNames = typeof OrganizerNames[keyof typeof OrganizerNames];

/**
 * Make all properties in T optional
 * @export
 * @interface PartialIEventUpdate
 */
export interface PartialIEventUpdate {
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  hostName?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  actScheduleName?: string | null;
  /**
   *
   * @type {number}
   * @memberof PartialIEventUpdate
   */
  ticketPriceMinCents?: number | null;
  /**
   *
   * @type {number}
   * @memberof PartialIEventUpdate
   */
  ticketPriceMaxCents?: number | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  ticketPurchaseLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  instructions?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  startsAt?: string;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  endsAt?: string;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  displayAfter?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  hideAfter?: string;
  /**
   * Stringified hex code E.g. 1AFFa1
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  primaryColor?: string;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  secondaryColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  accentColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  complimentaryColor?: string | null;
  /**
   *
   * @type {string}
   * @memberof PartialIEventUpdate
   */
  mapStaticImageUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof PartialIEventUpdate
   */
  mapLocationId?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof PartialIEventUpdate
   */
  isSubmissionDraft?: boolean;
}
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickIBaseEventSubmissionExcludeKeyofIBaseEventSubmissionIdOrEventIdOrCreatedAt
 */
export interface PickIBaseEventSubmissionExcludeKeyofIBaseEventSubmissionIdOrEventIdOrCreatedAt {
  /**
   *
   * @type {string}
   * @memberof PickIBaseEventSubmissionExcludeKeyofIBaseEventSubmissionIdOrEventIdOrCreatedAt
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof PickIBaseEventSubmissionExcludeKeyofIBaseEventSubmissionIdOrEventIdOrCreatedAt
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof PickIBaseEventSubmissionExcludeKeyofIBaseEventSubmissionIdOrEventIdOrCreatedAt
   */
  contactPhone: string;
}
/**
 * Model PushNotification
 * @export
 * @interface PushNotification
 */
export interface PushNotification {
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  sentAt: string | null;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  messageId: string | null;
  /**
   *
   * @type {number}
   * @memberof PushNotification
   */
  eventId: number | null;
  /**
   *
   * @type {boolean}
   * @memberof PushNotification
   */
  dryRun: boolean;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  imageUrl: string | null;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  topic: string;
  /**
   *
   * @type {string}
   * @memberof PushNotification
   */
  createdAt: string;
  /**
   *
   * @type {number}
   * @memberof PushNotification
   */
  id: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PushNotificationTopic = {
  UpcomingEvents: 'upcoming-events',
} as const;

export type PushNotificationTopic = typeof PushNotificationTopic[keyof typeof PushNotificationTopic];

/**
 * Make all properties in T required
 * @export
 * @interface RequiredIEventSubmission
 */
export interface RequiredIEventSubmission {
  /**
   *
   * @type {IEvent}
   * @memberof RequiredIEventSubmission
   */
  event: IEvent;
  /**
   * Database Integer
   * @type {number}
   * @memberof RequiredIEventSubmission
   */
  id: number;
  /**
   * Database Integer
   * @type {number}
   * @memberof RequiredIEventSubmission
   */
  eventId: number;
  /**
   *
   * @type {string}
   * @memberof RequiredIEventSubmission
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RequiredIEventSubmission
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof RequiredIEventSubmission
   */
  contactEmail: string;
  /**
   *
   * @type {string}
   * @memberof RequiredIEventSubmission
   */
  contactPhone: string;
}
/**
 *
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserParams
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UserStatusType
 */
export interface UserStatusType {}
/**
 *
 * @export
 * @enum {string}
 */

export const UserStatusTypeAnyOf = {
  Unconfirmed: 'UNCONFIRMED',
  Confirmed: 'CONFIRMED',
  Archived: 'ARCHIVED',
  Compromised: 'COMPROMISED',
  Unknown: 'UNKNOWN',
  ResetRequired: 'RESET_REQUIRED',
  ForceChangePassword: 'FORCE_CHANGE_PASSWORD',
} as const;

export type UserStatusTypeAnyOf = typeof UserStatusTypeAnyOf[keyof typeof UserStatusTypeAnyOf];

/**
 *
 * @export
 * @interface UserType
 */
export interface UserType {
  /**
   *
   * @type {string}
   * @memberof UserType
   */
  Username?: string;
  /**
   *
   * @type {Array<AttributeType>}
   * @memberof UserType
   */
  Attributes?: Array<AttributeType>;
  /**
   *
   * @type {string}
   * @memberof UserType
   */
  UserCreateDate?: string;
  /**
   *
   * @type {string}
   * @memberof UserType
   */
  UserLastModifiedDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserType
   */
  Enabled?: boolean;
  /**
   *
   * @type {UserStatusType}
   * @memberof UserType
   */
  UserStatus?: UserStatusType;
  /**
   *
   * @type {Array<MFAOptionType>}
   * @memberof UserType
   */
  MFAOptions?: Array<MFAOptionType>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {IActCreationRequest} iActCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAct: async (
      iActCreationRequest: IActCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iActCreationRequest' is not null or undefined
      assertParamExists('createAct', 'iActCreationRequest', iActCreationRequest);
      const localVarPath = `/v1/acts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(iActCreationRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActImageGallery: async (id: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createActImageGallery', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createActImageGallery', 'body', body);
      const localVarPath = `/v1/acts/{id}/image-gallery`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEventCreationRequest} iEventCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvent: async (
      iEventCreationRequest: IEventCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEventCreationRequest' is not null or undefined
      assertParamExists('createEvent', 'iEventCreationRequest', iEventCreationRequest);
      const localVarPath = `/v1/events`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(iEventCreationRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEventCategoryCreationRequest} iEventCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventCategory: async (
      iEventCategoryCreationRequest: IEventCategoryCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEventCategoryCreationRequest' is not null or undefined
      assertParamExists('createEventCategory', 'iEventCategoryCreationRequest', iEventCategoryCreationRequest);
      const localVarPath = `/v1/event-categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEventCategoryCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEventDayCreationRequest} iEventDayCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventDay: async (
      iEventDayCreationRequest: IEventDayCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEventDayCreationRequest' is not null or undefined
      assertParamExists('createEventDay', 'iEventDayCreationRequest', iEventDayCreationRequest);
      const localVarPath = `/v1/event-days`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEventDayCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventImageGallery: async (
      id: number,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createEventImageGallery', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createEventImageGallery', 'body', body);
      const localVarPath = `/v1/events/{id}/image-gallery`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEventSubmissionCreationRequest} iEventSubmissionCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventSubmission: async (
      iEventSubmissionCreationRequest: IEventSubmissionCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEventSubmissionCreationRequest' is not null or undefined
      assertParamExists('createEventSubmission', 'iEventSubmissionCreationRequest', iEventSubmissionCreationRequest);
      const localVarPath = `/v1/event-submissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEventSubmissionCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IGenericPageCreationRequest} iGenericPageCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenericPage: async (
      iGenericPageCreationRequest: IGenericPageCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iGenericPageCreationRequest' is not null or undefined
      assertParamExists('createGenericPage', 'iGenericPageCreationRequest', iGenericPageCreationRequest);
      const localVarPath = `/v1/generic-pages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iGenericPageCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Array<any>} images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImageGalleryImages: async (
      id: number,
      images: Array<any>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createImageGalleryImages', 'id', id);
      // verify required parameter 'images' is not null or undefined
      assertParamExists('createImageGalleryImages', 'images', images);
      const localVarPath = `/v1/image-galleries/{id}/images`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (images) {
        images.forEach((element) => {
          localVarFormParams.append('images', element as any);
        });
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<any>} logoFiles
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManySponsors: async (
      logoFiles: Array<any>,
      eventId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'logoFiles' is not null or undefined
      assertParamExists('createManySponsors', 'logoFiles', logoFiles);
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('createManySponsors', 'eventId', eventId);
      const localVarPath = `/v1/sponsors/create-many`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (logoFiles) {
        logoFiles.forEach((element) => {
          localVarFormParams.append('logoFiles', element as any);
        });
      }

      if (eventId !== undefined) {
        localVarFormParams.append('eventId', eventId as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IMapLocationCreationRequest} iMapLocationCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMapLocation: async (
      iMapLocationCreationRequest: IMapLocationCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iMapLocationCreationRequest' is not null or undefined
      assertParamExists('createMapLocation', 'iMapLocationCreationRequest', iMapLocationCreationRequest);
      const localVarPath = `/v1/map-locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iMapLocationCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IOrganizerCreationRequest} iOrganizerCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganizer: async (
      iOrganizerCreationRequest: IOrganizerCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iOrganizerCreationRequest' is not null or undefined
      assertParamExists('createOrganizer', 'iOrganizerCreationRequest', iOrganizerCreationRequest);
      const localVarPath = `/organizers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOrganizerCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IPointOfInterestCreationRequest} iPointOfInterestCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterest: async (
      iPointOfInterestCreationRequest: IPointOfInterestCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iPointOfInterestCreationRequest' is not null or undefined
      assertParamExists('createPointOfInterest', 'iPointOfInterestCreationRequest', iPointOfInterestCreationRequest);
      const localVarPath = `/v1/points-of-interest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iPointOfInterestCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IPointOfInterestCategoryCreationRequest} iPointOfInterestCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterestCategory: async (
      iPointOfInterestCategoryCreationRequest: IPointOfInterestCategoryCreationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iPointOfInterestCategoryCreationRequest' is not null or undefined
      assertParamExists(
        'createPointOfInterestCategory',
        'iPointOfInterestCategoryCreationRequest',
        iPointOfInterestCategoryCreationRequest,
      );
      const localVarPath = `/v1/point-of-interest-categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iPointOfInterestCategoryCreationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterestImageGallery: async (
      id: number,
      body: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createPointOfInterestImageGallery', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('createPointOfInterestImageGallery', 'body', body);
      const localVarPath = `/v1/points-of-interest/{id}/image-gallery`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {any} logoFile
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSponsor: async (logoFile: any, eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'logoFile' is not null or undefined
      assertParamExists('createSponsor', 'logoFile', logoFile);
      // verify required parameter 'eventId' is not null or undefined
      assertParamExists('createSponsor', 'eventId', eventId);
      const localVarPath = `/v1/sponsors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (logoFile !== undefined) {
        localVarFormParams.append('logoFile', logoFile as any);
      }

      if (eventId !== undefined) {
        localVarFormParams.append('eventId', eventId as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAct', 'id', id);
      const localVarPath = `/v1/acts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEmailListEntryDeletion} iEmailListEntryDeletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmailListEntry: async (
      iEmailListEntryDeletion: IEmailListEntryDeletion,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEmailListEntryDeletion' is not null or undefined
      assertParamExists('deleteEmailListEntry', 'iEmailListEntryDeletion', iEmailListEntryDeletion);
      const localVarPath = `/email-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEmailListEntryDeletion,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEvent', 'id', id);
      const localVarPath = `/v1/events/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEventCategory', 'id', id);
      const localVarPath = `/v1/event-categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventDay: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEventDay', 'id', id);
      const localVarPath = `/v1/event-days/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventSubmission: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteEventSubmission', 'id', id);
      const localVarPath = `/v1/event-submissions/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenericPageById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteGenericPageById', 'id', id);
      const localVarPath = `/v1/generic-pages/by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenericPageBySlug: async (
      slug: KnownGenericPageSlug,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('deleteGenericPageBySlug', 'slug', slug);
      const localVarPath = `/v1/generic-pages/by-slug/{slug}`.replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IImageGalleryDeleteManyRequest} iImageGalleryDeleteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImageGalleryImages: async (
      iImageGalleryDeleteManyRequest: IImageGalleryDeleteManyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iImageGalleryDeleteManyRequest' is not null or undefined
      assertParamExists('deleteImageGalleryImages', 'iImageGalleryDeleteManyRequest', iImageGalleryDeleteManyRequest);
      const localVarPath = `/v1/image-gallery-images`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iImageGalleryDeleteManyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManySponsors: async (ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists('deleteManySponsors', 'ids', ids);
      const localVarPath = `/v1/sponsors/delete-many`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (ids) {
        localVarQueryParameter['ids'] = ids;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMapLocation: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteMapLocation', 'id', id);
      const localVarPath = `/v1/map-locations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePointOfInterest: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePointOfInterest', 'id', id);
      const localVarPath = `/v1/points-of-interest/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePointOfInterestCategory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePointOfInterestCategory', 'id', id);
      const localVarPath = `/v1/point-of-interest-categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSponsor: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteSponsor', 'id', id);
      const localVarPath = `/v1/sponsors/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('deleteUser', 'username', username);
      const localVarPath = `/v1/users/{username}`.replace(`{${'username'}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActsInEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getActsInEvent', 'id', id);
      const localVarPath = `/v1/events/{id}/acts`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDaysInEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getDaysInEvent', 'id', id);
      const localVarPath = `/v1/events/{id}/days`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/email-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/event-categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'createdAt'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventSubmissions: async (
      page?: number,
      pageSize?: number,
      orderBy?: 'createdAt',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      searchString?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/event-submissions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter['orderDirection'] = orderDirection;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (searchString !== undefined) {
        localVarQueryParameter['searchString'] = searchString;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventbriteEventById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getEventbriteEventById', 'id', id);
      const localVarPath = `/eventbrite/events/by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventbriteEventByUrl: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'url' is not null or undefined
      assertParamExists('getEventbriteEventByUrl', 'url', url);
      const localVarPath = `/eventbrite/events/by-url/{url}`.replace(`{${'url'}}`, encodeURIComponent(String(url)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvents: async (
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/events/private`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter['orderDirection'] = orderDirection;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (priceCentsMin !== undefined) {
        localVarQueryParameter['priceCentsMin'] = priceCentsMin;
      }

      if (priceCentsMax !== undefined) {
        localVarQueryParameter['priceCentsMax'] = priceCentsMax;
      }

      if (endsBefore !== undefined) {
        localVarQueryParameter['endsBefore'] =
          (endsBefore as any) instanceof Date ? (endsBefore as any).toISOString() : endsBefore;
      }

      if (startsAfter !== undefined) {
        localVarQueryParameter['startsAfter'] =
          (startsAfter as any) instanceof Date ? (startsAfter as any).toISOString() : startsAfter;
      }

      if (categoryName !== undefined) {
        localVarQueryParameter['categoryName'] = categoryName;
      }

      if (categoryId !== undefined) {
        localVarQueryParameter['categoryId'] = categoryId;
      }

      if (searchString !== undefined) {
        localVarQueryParameter['searchString'] = searchString;
      }

      if (dateRangeStart !== undefined) {
        localVarQueryParameter['dateRangeStart'] =
          (dateRangeStart as any) instanceof Date ? (dateRangeStart as any).toISOString() : dateRangeStart;
      }

      if (dateRangeEnd !== undefined) {
        localVarQueryParameter['dateRangeEnd'] =
          (dateRangeEnd as any) instanceof Date ? (dateRangeEnd as any).toISOString() : dateRangeEnd;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPageById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getGenericPageById', 'id', id);
      const localVarPath = `/v1/generic-pages/by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPageBySlug: async (
      slug: KnownGenericPageSlug,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('getGenericPageBySlug', 'slug', slug);
      const localVarPath = `/v1/generic-pages/by-slug/{slug}`.replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/generic-pages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestPushNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/firebase/push-notifications/latest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationOfEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getLocationOfEvent', 'id', id);
      const localVarPath = `/v1/events/{id}/location`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizerById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getOrganizerById', 'id', id);
      const localVarPath = `/organizers/by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizerByName: async (name: OrganizerNames, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('getOrganizerByName', 'name', name);
      const localVarPath = `/organizers/by-name/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPointOfInterestCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/point-of-interest-categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPointOfInterestsInEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPointOfInterestsInEvent', 'id', id);
      const localVarPath = `/v1/events/{id}/points-of-interest`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicEvents: async (
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/events`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter['orderBy'] = orderBy;
      }

      if (orderDirection !== undefined) {
        localVarQueryParameter['orderDirection'] = orderDirection;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (priceCentsMin !== undefined) {
        localVarQueryParameter['priceCentsMin'] = priceCentsMin;
      }

      if (priceCentsMax !== undefined) {
        localVarQueryParameter['priceCentsMax'] = priceCentsMax;
      }

      if (endsBefore !== undefined) {
        localVarQueryParameter['endsBefore'] =
          (endsBefore as any) instanceof Date ? (endsBefore as any).toISOString() : endsBefore;
      }

      if (startsAfter !== undefined) {
        localVarQueryParameter['startsAfter'] =
          (startsAfter as any) instanceof Date ? (startsAfter as any).toISOString() : startsAfter;
      }

      if (categoryName !== undefined) {
        localVarQueryParameter['categoryName'] = categoryName;
      }

      if (categoryId !== undefined) {
        localVarQueryParameter['categoryId'] = categoryId;
      }

      if (searchString !== undefined) {
        localVarQueryParameter['searchString'] = searchString;
      }

      if (dateRangeStart !== undefined) {
        localVarQueryParameter['dateRangeStart'] =
          (dateRangeStart as any) instanceof Date ? (dateRangeStart as any).toISOString() : dateRangeStart;
      }

      if (dateRangeEnd !== undefined) {
        localVarQueryParameter['dateRangeEnd'] =
          (dateRangeEnd as any) instanceof Date ? (dateRangeEnd as any).toISOString() : dateRangeEnd;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSponsorsInEvent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSponsorsInEvent', 'id', id);
      const localVarPath = `/v1/events/{id}/sponsors`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (
      limit?: number,
      paginationToken?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (paginationToken !== undefined) {
        localVarQueryParameter['paginationToken'] = paginationToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IEmailListEntryCreation} iEmailListEntryCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertEmailListEntry: async (
      iEmailListEntryCreation: IEmailListEntryCreation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iEmailListEntryCreation' is not null or undefined
      assertParamExists('insertEmailListEntry', 'iEmailListEntryCreation', iEmailListEntryCreation);
      const localVarPath = `/email-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEmailListEntryCreation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUser: async (createUserParams: CreateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createUserParams' is not null or undefined
      assertParamExists('inviteUser', 'createUserParams', createUserParams);
      const localVarPath = `/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {any} mapImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceMapStaticImageForEvent: async (
      id: number,
      mapImage: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('replaceMapStaticImageForEvent', 'id', id);
      // verify required parameter 'mapImage' is not null or undefined
      assertParamExists('replaceMapStaticImageForEvent', 'mapImage', mapImage);
      const localVarPath = `/v1/events/{id}/map-static-image`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (mapImage !== undefined) {
        localVarFormParams.append('mapImage', mapImage as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceOrganizerAboutPdfById: async (
      id: number,
      aboutPdf: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('replaceOrganizerAboutPdfById', 'id', id);
      // verify required parameter 'aboutPdf' is not null or undefined
      assertParamExists('replaceOrganizerAboutPdfById', 'aboutPdf', aboutPdf);
      const localVarPath = `/organizers/by-id/{id}/about-pdf`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (aboutPdf !== undefined) {
        localVarFormParams.append('aboutPdf', aboutPdf as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceOrganizerAboutPdfByName: async (
      name: OrganizerNames,
      aboutPdf: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('replaceOrganizerAboutPdfByName', 'name', name);
      // verify required parameter 'aboutPdf' is not null or undefined
      assertParamExists('replaceOrganizerAboutPdfByName', 'aboutPdf', aboutPdf);
      const localVarPath = `/organizers/by-name/{name}/about-pdf`.replace(
        `{${'name'}}`,
        encodeURIComponent(String(name)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (aboutPdf !== undefined) {
        localVarFormParams.append('aboutPdf', aboutPdf as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceThumbnailImageForEventCategory: async (
      id: number,
      thumbnailImage: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('replaceThumbnailImageForEventCategory', 'id', id);
      // verify required parameter 'thumbnailImage' is not null or undefined
      assertParamExists('replaceThumbnailImageForEventCategory', 'thumbnailImage', thumbnailImage);
      const localVarPath = `/v1/event-categories/{id}/thumbnail-image`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (thumbnailImage !== undefined) {
        localVarFormParams.append('thumbnailImage', thumbnailImage as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceThumbnailImageForImageGallery: async (
      id: number,
      thumbnailImage: any,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('replaceThumbnailImageForImageGallery', 'id', id);
      // verify required parameter 'thumbnailImage' is not null or undefined
      assertParamExists('replaceThumbnailImageForImageGallery', 'thumbnailImage', thumbnailImage);
      const localVarPath = `/v1/image-galleries/{id}/thumbnail-image`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (thumbnailImage !== undefined) {
        localVarFormParams.append('thumbnailImage', thumbnailImage as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    seedDatabase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/database/seed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IPushNotificationCreation} iPushNotificationCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToTopic: async (
      iPushNotificationCreation: IPushNotificationCreation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iPushNotificationCreation' is not null or undefined
      assertParamExists('sendMessageToTopic', 'iPushNotificationCreation', iPushNotificationCreation);
      const localVarPath = `/firebase/push-notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iPushNotificationCreation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    status: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testAuthentication: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/test-authentication`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IActUpdateRequest} iActUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAct: async (
      id: number,
      iActUpdateRequest: IActUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAct', 'id', id);
      // verify required parameter 'iActUpdateRequest' is not null or undefined
      assertParamExists('updateAct', 'iActUpdateRequest', iActUpdateRequest);
      const localVarPath = `/v1/acts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(iActUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IEventUpdateRequest} iEventUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvent: async (
      id: number,
      iEventUpdateRequest: IEventUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEvent', 'id', id);
      // verify required parameter 'iEventUpdateRequest' is not null or undefined
      assertParamExists('updateEvent', 'iEventUpdateRequest', iEventUpdateRequest);
      const localVarPath = `/v1/events/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(iEventUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IEventCategoryUpdateRequest} iEventCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventCategory: async (
      id: number,
      iEventCategoryUpdateRequest: IEventCategoryUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEventCategory', 'id', id);
      // verify required parameter 'iEventCategoryUpdateRequest' is not null or undefined
      assertParamExists('updateEventCategory', 'iEventCategoryUpdateRequest', iEventCategoryUpdateRequest);
      const localVarPath = `/v1/event-categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEventCategoryUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IEventDayUpdateRequest} iEventDayUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventDay: async (
      id: number,
      iEventDayUpdateRequest: IEventDayUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateEventDay', 'id', id);
      // verify required parameter 'iEventDayUpdateRequest' is not null or undefined
      assertParamExists('updateEventDay', 'iEventDayUpdateRequest', iEventDayUpdateRequest);
      const localVarPath = `/v1/event-days/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iEventDayUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenericPageById: async (
      id: number,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateGenericPageById', 'id', id);
      // verify required parameter 'iGenericPageUpdateRequest' is not null or undefined
      assertParamExists('updateGenericPageById', 'iGenericPageUpdateRequest', iGenericPageUpdateRequest);
      const localVarPath = `/v1/generic-pages/by-id/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iGenericPageUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenericPageBySlug: async (
      slug: KnownGenericPageSlug,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('updateGenericPageBySlug', 'slug', slug);
      // verify required parameter 'iGenericPageUpdateRequest' is not null or undefined
      assertParamExists('updateGenericPageBySlug', 'iGenericPageUpdateRequest', iGenericPageUpdateRequest);
      const localVarPath = `/v1/generic-pages/by-slug/{slug}`.replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iGenericPageUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {IImageGalleryImageUpdateManyRequest} iImageGalleryImageUpdateManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImageGalleryImages: async (
      iImageGalleryImageUpdateManyRequest: IImageGalleryImageUpdateManyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'iImageGalleryImageUpdateManyRequest' is not null or undefined
      assertParamExists(
        'updateImageGalleryImages',
        'iImageGalleryImageUpdateManyRequest',
        iImageGalleryImageUpdateManyRequest,
      );
      const localVarPath = `/v1/image-gallery-images`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iImageGalleryImageUpdateManyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IMapLocationUpdateRequest} iMapLocationUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMapLocation: async (
      id: number,
      iMapLocationUpdateRequest: IMapLocationUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateMapLocation', 'id', id);
      // verify required parameter 'iMapLocationUpdateRequest' is not null or undefined
      assertParamExists('updateMapLocation', 'iMapLocationUpdateRequest', iMapLocationUpdateRequest);
      const localVarPath = `/v1/map-locations/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iMapLocationUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestUpdateRequest} iPointOfInterestUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePointOfInterest: async (
      id: number,
      iPointOfInterestUpdateRequest: IPointOfInterestUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updatePointOfInterest', 'id', id);
      // verify required parameter 'iPointOfInterestUpdateRequest' is not null or undefined
      assertParamExists('updatePointOfInterest', 'iPointOfInterestUpdateRequest', iPointOfInterestUpdateRequest);
      const localVarPath = `/v1/points-of-interest/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iPointOfInterestUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestCategoryUpdateRequest} iPointOfInterestCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePointOfInterestCategory: async (
      id: number,
      iPointOfInterestCategoryUpdateRequest: IPointOfInterestCategoryUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updatePointOfInterestCategory', 'id', id);
      // verify required parameter 'iPointOfInterestCategoryUpdateRequest' is not null or undefined
      assertParamExists(
        'updatePointOfInterestCategory',
        'iPointOfInterestCategoryUpdateRequest',
        iPointOfInterestCategoryUpdateRequest,
      );
      const localVarPath = `/v1/point-of-interest-categories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iPointOfInterestCategoryUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ISponsorUpdateRequest} iSponsorUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSponsor: async (
      id: number,
      iSponsorUpdateRequest: ISponsorUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSponsor', 'id', id);
      // verify required parameter 'iSponsorUpdateRequest' is not null or undefined
      assertParamExists('updateSponsor', 'iSponsorUpdateRequest', iSponsorUpdateRequest);
      const localVarPath = `/v1/sponsors/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(iSponsorUpdateRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      username: string,
      updateUserParams: UpdateUserParams,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('updateUser', 'username', username);
      // verify required parameter 'updateUserParams' is not null or undefined
      assertParamExists('updateUser', 'updateUserParams', updateUserParams);
      const localVarPath = `/v1/users/{username}`.replace(`{${'username'}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {IActCreationRequest} iActCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAct(
      iActCreationRequest: IActCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAct(iActCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createActImageGallery(
      id: number,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createActImageGallery(id, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEventCreationRequest} iEventCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEvent(
      iEventCreationRequest: IEventCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(iEventCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEventCategoryCreationRequest} iEventCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEventCategory(
      iEventCategoryCreationRequest: IEventCategoryCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEventCategory(
        iEventCategoryCreationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEventDayCreationRequest} iEventDayCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEventDay(
      iEventDayCreationRequest: IEventDayCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventDay>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEventDay(iEventDayCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEventImageGallery(
      id: number,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEventImageGallery(id, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEventSubmissionCreationRequest} iEventSubmissionCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEventSubmission(
      iEventSubmissionCreationRequest: IEventSubmissionCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventSubmission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEventSubmission(
        iEventSubmissionCreationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IGenericPageCreationRequest} iGenericPageCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createGenericPage(
      iGenericPageCreationRequest: IGenericPageCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createGenericPage(iGenericPageCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {Array<any>} images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createImageGalleryImages(
      id: number,
      images: Array<any>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IImageGallery>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createImageGalleryImages(id, images, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<any>} logoFiles
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createManySponsors(
      logoFiles: Array<any>,
      eventId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createManySponsors(logoFiles, eventId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IMapLocationCreationRequest} iMapLocationCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMapLocation(
      iMapLocationCreationRequest: IMapLocationCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMapLocation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMapLocation(iMapLocationCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IOrganizerCreationRequest} iOrganizerCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrganizer(
      iOrganizerCreationRequest: IOrganizerCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organizer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizer(iOrganizerCreationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IPointOfInterestCreationRequest} iPointOfInterestCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPointOfInterest(
      iPointOfInterestCreationRequest: IPointOfInterestCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPointOfInterest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPointOfInterest(
        iPointOfInterestCreationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IPointOfInterestCategoryCreationRequest} iPointOfInterestCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPointOfInterestCategory(
      iPointOfInterestCategoryCreationRequest: IPointOfInterestCategoryCreationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPointOfInterestCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPointOfInterestCategory(
        iPointOfInterestCategoryCreationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPointOfInterestImageGallery(
      id: number,
      body: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPointOfInterest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPointOfInterestImageGallery(id, body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {any} logoFile
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSponsor(
      logoFile: any,
      eventId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSponsor(logoFile, eventId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAct(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAct(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEmailListEntryDeletion} iEmailListEntryDeletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEmailListEntry(
      iEmailListEntryDeletion: IEmailListEntryDeletion,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailListEntry(iEmailListEntryDeletion, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventCategory(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventCategory(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventDay(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventDay(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEventSubmission(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventSubmission(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGenericPageById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGenericPageById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteGenericPageBySlug(
      slug: KnownGenericPageSlug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGenericPageBySlug(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IImageGalleryDeleteManyRequest} iImageGalleryDeleteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteImageGalleryImages(
      iImageGalleryDeleteManyRequest: IImageGalleryDeleteManyRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImageGalleryImages(
        iImageGalleryDeleteManyRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteManySponsors(
      ids: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManySponsors(ids, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMapLocation(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMapLocation(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePointOfInterest(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePointOfInterest(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePointOfInterestCategory(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePointOfInterestCategory(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSponsor(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSponsor(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUser(
      username: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(username, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActsInEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAct>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActsInEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDaysInEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEventDay>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDaysInEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmailList(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEmailListEntry>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventCategories(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IEventCategory>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCategories(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'createdAt'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventSubmissions(
      page?: number,
      pageSize?: number,
      orderBy?: 'createdAt',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      searchString?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventSubmissionGetAllResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventSubmissions(
        page,
        pageSize,
        orderBy,
        orderDirection,
        id,
        searchString,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventbriteEventById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialIEventUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventbriteEventById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEventbriteEventByUrl(
      url: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventUpdate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEventbriteEventByUrl(url, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEvents(
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventGetAllResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(
        page,
        pageSize,
        orderBy,
        orderDirection,
        id,
        priceCentsMin,
        priceCentsMax,
        endsBefore,
        startsAfter,
        categoryName,
        categoryId,
        searchString,
        dateRangeStart,
        dateRangeEnd,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGenericPageById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericPageById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGenericPageBySlug(
      slug: KnownGenericPageSlug,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericPageBySlug(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGenericPages(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IGenericPage>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericPages(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLatestPushNotifications(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PushNotification>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPushNotifications(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationOfEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMapLocationNullable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationOfEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizerById(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizerById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizerByName(
      name: OrganizerNames,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizerByName(name, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPointOfInterestCategories(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPointOfInterestCategory>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPointOfInterestCategories(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPointOfInterestsInEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IPointOfInterest>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPointOfInterestsInEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublicEvents(
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventGetAllResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicEvents(
        page,
        pageSize,
        orderBy,
        orderDirection,
        id,
        priceCentsMin,
        priceCentsMax,
        endsBefore,
        startsAfter,
        categoryName,
        categoryId,
        searchString,
        dateRangeStart,
        dateRangeEnd,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSponsorsInEvent(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISponsor>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSponsorsInEvent(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      limit?: number,
      paginationToken?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(limit, paginationToken, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IEmailListEntryCreation} iEmailListEntryCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertEmailListEntry(
      iEmailListEntryCreation: IEmailListEntryCreation,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEmailListEntry>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertEmailListEntry(iEmailListEntryCreation, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inviteUser(
      createUserParams: CreateUserParams,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUser(createUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {any} mapImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceMapStaticImageForEvent(
      id: number,
      mapImage: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceMapStaticImageForEvent(id, mapImage, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceOrganizerAboutPdfById(
      id: number,
      aboutPdf: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOrganizerAboutPdfById(id, aboutPdf, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceOrganizerAboutPdfByName(
      name: OrganizerNames,
      aboutPdf: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOrganizerAboutPdfByName(name, aboutPdf, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceThumbnailImageForEventCategory(
      id: number,
      thumbnailImage: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceThumbnailImageForEventCategory(
        id,
        thumbnailImage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async replaceThumbnailImageForImageGallery(
      id: number,
      thumbnailImage: any,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IImageGallery>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.replaceThumbnailImageForImageGallery(
        id,
        thumbnailImage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async seedDatabase(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.seedDatabase(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IPushNotificationCreation} iPushNotificationCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendMessageToTopic(
      iPushNotificationCreation: IPushNotificationCreation,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushNotification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessageToTopic(iPushNotificationCreation, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async status(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.status(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async testAuthentication(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.testAuthentication(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IActUpdateRequest} iActUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAct(
      id: number,
      iActUpdateRequest: IActUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAct(id, iActUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IEventUpdateRequest} iEventUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEvent(
      id: number,
      iEventUpdateRequest: IEventUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(id, iEventUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IEventCategoryUpdateRequest} iEventCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEventCategory(
      id: number,
      iEventCategoryUpdateRequest: IEventCategoryUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventCategory(
        id,
        iEventCategoryUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IEventDayUpdateRequest} iEventDayUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEventDay(
      id: number,
      iEventDayUpdateRequest: IEventDayUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEventDay>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventDay(id, iEventDayUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGenericPageById(
      id: number,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGenericPageById(
        id,
        iGenericPageUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGenericPageBySlug(
      slug: KnownGenericPageSlug,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGenericPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGenericPageBySlug(
        slug,
        iGenericPageUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {IImageGalleryImageUpdateManyRequest} iImageGalleryImageUpdateManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateImageGalleryImages(
      iImageGalleryImageUpdateManyRequest: IImageGalleryImageUpdateManyRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateImageGalleryImages(
        iImageGalleryImageUpdateManyRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IMapLocationUpdateRequest} iMapLocationUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMapLocation(
      id: number,
      iMapLocationUpdateRequest: IMapLocationUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMapLocation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMapLocation(
        id,
        iMapLocationUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestUpdateRequest} iPointOfInterestUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePointOfInterest(
      id: number,
      iPointOfInterestUpdateRequest: IPointOfInterestUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPointOfInterest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePointOfInterest(
        id,
        iPointOfInterestUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestCategoryUpdateRequest} iPointOfInterestCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePointOfInterestCategory(
      id: number,
      iPointOfInterestCategoryUpdateRequest: IPointOfInterestCategoryUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IPointOfInterestCategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePointOfInterestCategory(
        id,
        iPointOfInterestCategoryUpdateRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id
     * @param {ISponsorUpdateRequest} iSponsorUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSponsor(
      id: number,
      iSponsorUpdateRequest: ISponsorUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISponsor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSponsor(id, iSponsorUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} username
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      username: string,
      updateUserParams: UpdateUserParams,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(username, updateUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {IActCreationRequest} iActCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAct(iActCreationRequest: IActCreationRequest, options?: any): AxiosPromise<IAct> {
      return localVarFp.createAct(iActCreationRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createActImageGallery(id: number, body: object, options?: any): AxiosPromise<IAct> {
      return localVarFp.createActImageGallery(id, body, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEventCreationRequest} iEventCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEvent(iEventCreationRequest: IEventCreationRequest, options?: any): AxiosPromise<IEvent> {
      return localVarFp.createEvent(iEventCreationRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEventCategoryCreationRequest} iEventCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventCategory(
      iEventCategoryCreationRequest: IEventCategoryCreationRequest,
      options?: any,
    ): AxiosPromise<IEventCategory> {
      return localVarFp
        .createEventCategory(iEventCategoryCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEventDayCreationRequest} iEventDayCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventDay(iEventDayCreationRequest: IEventDayCreationRequest, options?: any): AxiosPromise<IEventDay> {
      return localVarFp.createEventDay(iEventDayCreationRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventImageGallery(id: number, body: object, options?: any): AxiosPromise<IEvent> {
      return localVarFp.createEventImageGallery(id, body, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEventSubmissionCreationRequest} iEventSubmissionCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEventSubmission(
      iEventSubmissionCreationRequest: IEventSubmissionCreationRequest,
      options?: any,
    ): AxiosPromise<IEventSubmission> {
      return localVarFp
        .createEventSubmission(iEventSubmissionCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IGenericPageCreationRequest} iGenericPageCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createGenericPage(
      iGenericPageCreationRequest: IGenericPageCreationRequest,
      options?: any,
    ): AxiosPromise<IGenericPage> {
      return localVarFp
        .createGenericPage(iGenericPageCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Array<any>} images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createImageGalleryImages(id: number, images: Array<any>, options?: any): AxiosPromise<IImageGallery> {
      return localVarFp.createImageGalleryImages(id, images, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<any>} logoFiles
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createManySponsors(logoFiles: Array<any>, eventId: string, options?: any): AxiosPromise<void> {
      return localVarFp.createManySponsors(logoFiles, eventId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IMapLocationCreationRequest} iMapLocationCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMapLocation(
      iMapLocationCreationRequest: IMapLocationCreationRequest,
      options?: any,
    ): AxiosPromise<IMapLocation> {
      return localVarFp
        .createMapLocation(iMapLocationCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IOrganizerCreationRequest} iOrganizerCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganizer(iOrganizerCreationRequest: IOrganizerCreationRequest, options?: any): AxiosPromise<Organizer> {
      return localVarFp.createOrganizer(iOrganizerCreationRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IPointOfInterestCreationRequest} iPointOfInterestCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterest(
      iPointOfInterestCreationRequest: IPointOfInterestCreationRequest,
      options?: any,
    ): AxiosPromise<IPointOfInterest> {
      return localVarFp
        .createPointOfInterest(iPointOfInterestCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IPointOfInterestCategoryCreationRequest} iPointOfInterestCategoryCreationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterestCategory(
      iPointOfInterestCategoryCreationRequest: IPointOfInterestCategoryCreationRequest,
      options?: any,
    ): AxiosPromise<IPointOfInterestCategory> {
      return localVarFp
        .createPointOfInterestCategory(iPointOfInterestCategoryCreationRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPointOfInterestImageGallery(id: number, body: object, options?: any): AxiosPromise<IPointOfInterest> {
      return localVarFp
        .createPointOfInterestImageGallery(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {any} logoFile
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSponsor(logoFile: any, eventId: string, options?: any): AxiosPromise<void> {
      return localVarFp.createSponsor(logoFile, eventId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAct(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAct(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEmailListEntryDeletion} iEmailListEntryDeletion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEmailListEntry(iEmailListEntryDeletion: IEmailListEntryDeletion, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteEmailListEntry(iEmailListEntryDeletion, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEvent(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventCategory(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEventCategory(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventDay(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEventDay(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEventSubmission(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteEventSubmission(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenericPageById(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteGenericPageById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteGenericPageBySlug(slug: KnownGenericPageSlug, options?: any): AxiosPromise<void> {
      return localVarFp.deleteGenericPageBySlug(slug, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IImageGalleryDeleteManyRequest} iImageGalleryDeleteManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteImageGalleryImages(
      iImageGalleryDeleteManyRequest: IImageGalleryDeleteManyRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .deleteImageGalleryImages(iImageGalleryDeleteManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManySponsors(ids: Array<number>, options?: any): AxiosPromise<number> {
      return localVarFp.deleteManySponsors(ids, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMapLocation(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteMapLocation(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePointOfInterest(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deletePointOfInterest(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePointOfInterestCategory(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deletePointOfInterestCategory(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSponsor(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteSponsor(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUser(username: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteUser(username, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActsInEvent(id: number, options?: any): AxiosPromise<Array<IAct>> {
      return localVarFp.getActsInEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDaysInEvent(id: number, options?: any): AxiosPromise<Array<IEventDay>> {
      return localVarFp.getDaysInEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailList(options?: any): AxiosPromise<Array<IEmailListEntry>> {
      return localVarFp.getEmailList(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventCategories(options?: any): AxiosPromise<Array<IEventCategory>> {
      return localVarFp.getEventCategories(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'createdAt'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventSubmissions(
      page?: number,
      pageSize?: number,
      orderBy?: 'createdAt',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      searchString?: string,
      options?: any,
    ): AxiosPromise<IEventSubmissionGetAllResponse> {
      return localVarFp
        .getEventSubmissions(page, pageSize, orderBy, orderDirection, id, searchString, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventbriteEventById(id: number, options?: any): AxiosPromise<PartialIEventUpdate> {
      return localVarFp.getEventbriteEventById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEventbriteEventByUrl(url: string, options?: any): AxiosPromise<IEventUpdate> {
      return localVarFp.getEventbriteEventByUrl(url, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEvents(
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options?: any,
    ): AxiosPromise<IEventGetAllResponse> {
      return localVarFp
        .getEvents(
          page,
          pageSize,
          orderBy,
          orderDirection,
          id,
          priceCentsMin,
          priceCentsMax,
          endsBefore,
          startsAfter,
          categoryName,
          categoryId,
          searchString,
          dateRangeStart,
          dateRangeEnd,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPageById(id: number, options?: any): AxiosPromise<IGenericPage> {
      return localVarFp.getGenericPageById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPageBySlug(slug: KnownGenericPageSlug, options?: any): AxiosPromise<IGenericPage> {
      return localVarFp.getGenericPageBySlug(slug, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGenericPages(options?: any): AxiosPromise<Array<IGenericPage>> {
      return localVarFp.getGenericPages(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestPushNotifications(options?: any): AxiosPromise<Array<PushNotification>> {
      return localVarFp.getLatestPushNotifications(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationOfEvent(id: number, options?: any): AxiosPromise<IMapLocationNullable> {
      return localVarFp.getLocationOfEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizerById(id: number, options?: any): AxiosPromise<IOrganizer> {
      return localVarFp.getOrganizerById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizerByName(name: OrganizerNames, options?: any): AxiosPromise<IOrganizer> {
      return localVarFp.getOrganizerByName(name, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPointOfInterestCategories(options?: any): AxiosPromise<Array<IPointOfInterestCategory>> {
      return localVarFp.getPointOfInterestCategories(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPointOfInterestsInEvent(id: number, options?: any): AxiosPromise<Array<IPointOfInterest>> {
      return localVarFp.getPointOfInterestsInEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {'startsAt' | 'endsAt' | 'name'} [orderBy]
     * @param {'asc' | 'desc'} [orderDirection]
     * @param {number} [id]
     * @param {number} [priceCentsMin]
     * @param {number} [priceCentsMax]
     * @param {string} [endsBefore]
     * @param {string} [startsAfter]
     * @param {string} [categoryName]
     * @param {number} [categoryId]
     * @param {string} [searchString]
     * @param {string} [dateRangeStart]
     * @param {string} [dateRangeEnd]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublicEvents(
      page?: number,
      pageSize?: number,
      orderBy?: 'startsAt' | 'endsAt' | 'name',
      orderDirection?: 'asc' | 'desc',
      id?: number,
      priceCentsMin?: number,
      priceCentsMax?: number,
      endsBefore?: string,
      startsAfter?: string,
      categoryName?: string,
      categoryId?: number,
      searchString?: string,
      dateRangeStart?: string,
      dateRangeEnd?: string,
      options?: any,
    ): AxiosPromise<IEventGetAllResponse> {
      return localVarFp
        .getPublicEvents(
          page,
          pageSize,
          orderBy,
          orderDirection,
          id,
          priceCentsMin,
          priceCentsMax,
          endsBefore,
          startsAfter,
          categoryName,
          categoryId,
          searchString,
          dateRangeStart,
          dateRangeEnd,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSponsorsInEvent(id: number, options?: any): AxiosPromise<Array<ISponsor>> {
      return localVarFp.getSponsorsInEvent(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit]
     * @param {string} [paginationToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(limit?: number, paginationToken?: string, options?: any): AxiosPromise<ListUsersResponse> {
      return localVarFp.getUsers(limit, paginationToken, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IEmailListEntryCreation} iEmailListEntryCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertEmailListEntry(
      iEmailListEntryCreation: IEmailListEntryCreation,
      options?: any,
    ): AxiosPromise<IEmailListEntry> {
      return localVarFp
        .insertEmailListEntry(iEmailListEntryCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUser(createUserParams: CreateUserParams, options?: any): AxiosPromise<CreateUserResponse> {
      return localVarFp.inviteUser(createUserParams, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {any} mapImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceMapStaticImageForEvent(id: number, mapImage: any, options?: any): AxiosPromise<IEvent> {
      return localVarFp
        .replaceMapStaticImageForEvent(id, mapImage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceOrganizerAboutPdfById(id: number, aboutPdf: any, options?: any): AxiosPromise<IOrganizer> {
      return localVarFp.replaceOrganizerAboutPdfById(id, aboutPdf, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {OrganizerNames} name
     * @param {any} aboutPdf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceOrganizerAboutPdfByName(name: OrganizerNames, aboutPdf: any, options?: any): AxiosPromise<IOrganizer> {
      return localVarFp
        .replaceOrganizerAboutPdfByName(name, aboutPdf, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceThumbnailImageForEventCategory(
      id: number,
      thumbnailImage: any,
      options?: any,
    ): AxiosPromise<IEventCategory> {
      return localVarFp
        .replaceThumbnailImageForEventCategory(id, thumbnailImage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {any} thumbnailImage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    replaceThumbnailImageForImageGallery(id: number, thumbnailImage: any, options?: any): AxiosPromise<IImageGallery> {
      return localVarFp
        .replaceThumbnailImageForImageGallery(id, thumbnailImage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    seedDatabase(options?: any): AxiosPromise<void> {
      return localVarFp.seedDatabase(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IPushNotificationCreation} iPushNotificationCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToTopic(
      iPushNotificationCreation: IPushNotificationCreation,
      options?: any,
    ): AxiosPromise<PushNotification> {
      return localVarFp
        .sendMessageToTopic(iPushNotificationCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    status(options?: any): AxiosPromise<string> {
      return localVarFp.status(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testAuthentication(options?: any): AxiosPromise<string> {
      return localVarFp.testAuthentication(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IActUpdateRequest} iActUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAct(id: number, iActUpdateRequest: IActUpdateRequest, options?: any): AxiosPromise<IAct> {
      return localVarFp.updateAct(id, iActUpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IEventUpdateRequest} iEventUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEvent(id: number, iEventUpdateRequest: IEventUpdateRequest, options?: any): AxiosPromise<IEvent> {
      return localVarFp.updateEvent(id, iEventUpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IEventCategoryUpdateRequest} iEventCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventCategory(
      id: number,
      iEventCategoryUpdateRequest: IEventCategoryUpdateRequest,
      options?: any,
    ): AxiosPromise<IEventCategory> {
      return localVarFp
        .updateEventCategory(id, iEventCategoryUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IEventDayUpdateRequest} iEventDayUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEventDay(id: number, iEventDayUpdateRequest: IEventDayUpdateRequest, options?: any): AxiosPromise<IEventDay> {
      return localVarFp.updateEventDay(id, iEventDayUpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenericPageById(
      id: number,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options?: any,
    ): AxiosPromise<IGenericPage> {
      return localVarFp
        .updateGenericPageById(id, iGenericPageUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {KnownGenericPageSlug} slug
     * @param {IGenericPageUpdateRequest} iGenericPageUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGenericPageBySlug(
      slug: KnownGenericPageSlug,
      iGenericPageUpdateRequest: IGenericPageUpdateRequest,
      options?: any,
    ): AxiosPromise<IGenericPage> {
      return localVarFp
        .updateGenericPageBySlug(slug, iGenericPageUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {IImageGalleryImageUpdateManyRequest} iImageGalleryImageUpdateManyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateImageGalleryImages(
      iImageGalleryImageUpdateManyRequest: IImageGalleryImageUpdateManyRequest,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .updateImageGalleryImages(iImageGalleryImageUpdateManyRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IMapLocationUpdateRequest} iMapLocationUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMapLocation(
      id: number,
      iMapLocationUpdateRequest: IMapLocationUpdateRequest,
      options?: any,
    ): AxiosPromise<IMapLocation> {
      return localVarFp
        .updateMapLocation(id, iMapLocationUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestUpdateRequest} iPointOfInterestUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePointOfInterest(
      id: number,
      iPointOfInterestUpdateRequest: IPointOfInterestUpdateRequest,
      options?: any,
    ): AxiosPromise<IPointOfInterest> {
      return localVarFp
        .updatePointOfInterest(id, iPointOfInterestUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IPointOfInterestCategoryUpdateRequest} iPointOfInterestCategoryUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePointOfInterestCategory(
      id: number,
      iPointOfInterestCategoryUpdateRequest: IPointOfInterestCategoryUpdateRequest,
      options?: any,
    ): AxiosPromise<IPointOfInterestCategory> {
      return localVarFp
        .updatePointOfInterestCategory(id, iPointOfInterestCategoryUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ISponsorUpdateRequest} iSponsorUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSponsor(id: number, iSponsorUpdateRequest: ISponsorUpdateRequest, options?: any): AxiosPromise<ISponsor> {
      return localVarFp.updateSponsor(id, iSponsorUpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(username: string, updateUserParams: UpdateUserParams, options?: any): AxiosPromise<object> {
      return localVarFp.updateUser(username, updateUserParams, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createAct operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateActRequest
 */
export interface DefaultApiCreateActRequest {
  /**
   *
   * @type {IActCreationRequest}
   * @memberof DefaultApiCreateAct
   */
  readonly iActCreationRequest: IActCreationRequest;
}

/**
 * Request parameters for createActImageGallery operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateActImageGalleryRequest
 */
export interface DefaultApiCreateActImageGalleryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiCreateActImageGallery
   */
  readonly id: number;

  /**
   *
   * @type {object}
   * @memberof DefaultApiCreateActImageGallery
   */
  readonly body: object;
}

/**
 * Request parameters for createEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateEventRequest
 */
export interface DefaultApiCreateEventRequest {
  /**
   *
   * @type {IEventCreationRequest}
   * @memberof DefaultApiCreateEvent
   */
  readonly iEventCreationRequest: IEventCreationRequest;
}

/**
 * Request parameters for createEventCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateEventCategoryRequest
 */
export interface DefaultApiCreateEventCategoryRequest {
  /**
   *
   * @type {IEventCategoryCreationRequest}
   * @memberof DefaultApiCreateEventCategory
   */
  readonly iEventCategoryCreationRequest: IEventCategoryCreationRequest;
}

/**
 * Request parameters for createEventDay operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateEventDayRequest
 */
export interface DefaultApiCreateEventDayRequest {
  /**
   *
   * @type {IEventDayCreationRequest}
   * @memberof DefaultApiCreateEventDay
   */
  readonly iEventDayCreationRequest: IEventDayCreationRequest;
}

/**
 * Request parameters for createEventImageGallery operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateEventImageGalleryRequest
 */
export interface DefaultApiCreateEventImageGalleryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiCreateEventImageGallery
   */
  readonly id: number;

  /**
   *
   * @type {object}
   * @memberof DefaultApiCreateEventImageGallery
   */
  readonly body: object;
}

/**
 * Request parameters for createEventSubmission operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateEventSubmissionRequest
 */
export interface DefaultApiCreateEventSubmissionRequest {
  /**
   *
   * @type {IEventSubmissionCreationRequest}
   * @memberof DefaultApiCreateEventSubmission
   */
  readonly iEventSubmissionCreationRequest: IEventSubmissionCreationRequest;
}

/**
 * Request parameters for createGenericPage operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateGenericPageRequest
 */
export interface DefaultApiCreateGenericPageRequest {
  /**
   *
   * @type {IGenericPageCreationRequest}
   * @memberof DefaultApiCreateGenericPage
   */
  readonly iGenericPageCreationRequest: IGenericPageCreationRequest;
}

/**
 * Request parameters for createImageGalleryImages operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateImageGalleryImagesRequest
 */
export interface DefaultApiCreateImageGalleryImagesRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiCreateImageGalleryImages
   */
  readonly id: number;

  /**
   *
   * @type {Array<any>}
   * @memberof DefaultApiCreateImageGalleryImages
   */
  readonly images: Array<any>;
}

/**
 * Request parameters for createManySponsors operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateManySponsorsRequest
 */
export interface DefaultApiCreateManySponsorsRequest {
  /**
   *
   * @type {Array<any>}
   * @memberof DefaultApiCreateManySponsors
   */
  readonly logoFiles: Array<any>;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCreateManySponsors
   */
  readonly eventId: string;
}

/**
 * Request parameters for createMapLocation operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateMapLocationRequest
 */
export interface DefaultApiCreateMapLocationRequest {
  /**
   *
   * @type {IMapLocationCreationRequest}
   * @memberof DefaultApiCreateMapLocation
   */
  readonly iMapLocationCreationRequest: IMapLocationCreationRequest;
}

/**
 * Request parameters for createOrganizer operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrganizerRequest
 */
export interface DefaultApiCreateOrganizerRequest {
  /**
   *
   * @type {IOrganizerCreationRequest}
   * @memberof DefaultApiCreateOrganizer
   */
  readonly iOrganizerCreationRequest: IOrganizerCreationRequest;
}

/**
 * Request parameters for createPointOfInterest operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePointOfInterestRequest
 */
export interface DefaultApiCreatePointOfInterestRequest {
  /**
   *
   * @type {IPointOfInterestCreationRequest}
   * @memberof DefaultApiCreatePointOfInterest
   */
  readonly iPointOfInterestCreationRequest: IPointOfInterestCreationRequest;
}

/**
 * Request parameters for createPointOfInterestCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePointOfInterestCategoryRequest
 */
export interface DefaultApiCreatePointOfInterestCategoryRequest {
  /**
   *
   * @type {IPointOfInterestCategoryCreationRequest}
   * @memberof DefaultApiCreatePointOfInterestCategory
   */
  readonly iPointOfInterestCategoryCreationRequest: IPointOfInterestCategoryCreationRequest;
}

/**
 * Request parameters for createPointOfInterestImageGallery operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePointOfInterestImageGalleryRequest
 */
export interface DefaultApiCreatePointOfInterestImageGalleryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiCreatePointOfInterestImageGallery
   */
  readonly id: number;

  /**
   *
   * @type {object}
   * @memberof DefaultApiCreatePointOfInterestImageGallery
   */
  readonly body: object;
}

/**
 * Request parameters for createSponsor operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateSponsorRequest
 */
export interface DefaultApiCreateSponsorRequest {
  /**
   *
   * @type {any}
   * @memberof DefaultApiCreateSponsor
   */
  readonly logoFile: any;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCreateSponsor
   */
  readonly eventId: string;
}

/**
 * Request parameters for deleteAct operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteActRequest
 */
export interface DefaultApiDeleteActRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteAct
   */
  readonly id: number;
}

/**
 * Request parameters for deleteEmailListEntry operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteEmailListEntryRequest
 */
export interface DefaultApiDeleteEmailListEntryRequest {
  /**
   *
   * @type {IEmailListEntryDeletion}
   * @memberof DefaultApiDeleteEmailListEntry
   */
  readonly iEmailListEntryDeletion: IEmailListEntryDeletion;
}

/**
 * Request parameters for deleteEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteEventRequest
 */
export interface DefaultApiDeleteEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteEvent
   */
  readonly id: number;
}

/**
 * Request parameters for deleteEventCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteEventCategoryRequest
 */
export interface DefaultApiDeleteEventCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteEventCategory
   */
  readonly id: number;
}

/**
 * Request parameters for deleteEventDay operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteEventDayRequest
 */
export interface DefaultApiDeleteEventDayRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteEventDay
   */
  readonly id: number;
}

/**
 * Request parameters for deleteEventSubmission operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteEventSubmissionRequest
 */
export interface DefaultApiDeleteEventSubmissionRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteEventSubmission
   */
  readonly id: number;
}

/**
 * Request parameters for deleteGenericPageById operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteGenericPageByIdRequest
 */
export interface DefaultApiDeleteGenericPageByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteGenericPageById
   */
  readonly id: number;
}

/**
 * Request parameters for deleteGenericPageBySlug operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteGenericPageBySlugRequest
 */
export interface DefaultApiDeleteGenericPageBySlugRequest {
  /**
   *
   * @type {KnownGenericPageSlug}
   * @memberof DefaultApiDeleteGenericPageBySlug
   */
  readonly slug: KnownGenericPageSlug;
}

/**
 * Request parameters for deleteImageGalleryImages operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteImageGalleryImagesRequest
 */
export interface DefaultApiDeleteImageGalleryImagesRequest {
  /**
   *
   * @type {IImageGalleryDeleteManyRequest}
   * @memberof DefaultApiDeleteImageGalleryImages
   */
  readonly iImageGalleryDeleteManyRequest: IImageGalleryDeleteManyRequest;
}

/**
 * Request parameters for deleteManySponsors operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteManySponsorsRequest
 */
export interface DefaultApiDeleteManySponsorsRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof DefaultApiDeleteManySponsors
   */
  readonly ids: Array<number>;
}

/**
 * Request parameters for deleteMapLocation operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteMapLocationRequest
 */
export interface DefaultApiDeleteMapLocationRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteMapLocation
   */
  readonly id: number;
}

/**
 * Request parameters for deletePointOfInterest operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePointOfInterestRequest
 */
export interface DefaultApiDeletePointOfInterestRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeletePointOfInterest
   */
  readonly id: number;
}

/**
 * Request parameters for deletePointOfInterestCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePointOfInterestCategoryRequest
 */
export interface DefaultApiDeletePointOfInterestCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeletePointOfInterestCategory
   */
  readonly id: number;
}

/**
 * Request parameters for deleteSponsor operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteSponsorRequest
 */
export interface DefaultApiDeleteSponsorRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiDeleteSponsor
   */
  readonly id: number;
}

/**
 * Request parameters for deleteUser operation in DefaultApi.
 * @export
 * @interface DefaultApiDeleteUserRequest
 */
export interface DefaultApiDeleteUserRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiDeleteUser
   */
  readonly username: string;
}

/**
 * Request parameters for getActsInEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetActsInEventRequest
 */
export interface DefaultApiGetActsInEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetActsInEvent
   */
  readonly id: number;
}

/**
 * Request parameters for getDaysInEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetDaysInEventRequest
 */
export interface DefaultApiGetDaysInEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetDaysInEvent
   */
  readonly id: number;
}

/**
 * Request parameters for getEventSubmissions operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventSubmissionsRequest
 */
export interface DefaultApiGetEventSubmissionsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'createdAt'}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly orderBy?: 'createdAt';

  /**
   *
   * @type {'asc' | 'desc'}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly orderDirection?: 'asc' | 'desc';

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly id?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEventSubmissions
   */
  readonly searchString?: string;
}

/**
 * Request parameters for getEventbriteEventById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventbriteEventByIdRequest
 */
export interface DefaultApiGetEventbriteEventByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEventbriteEventById
   */
  readonly id: number;
}

/**
 * Request parameters for getEventbriteEventByUrl operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventbriteEventByUrlRequest
 */
export interface DefaultApiGetEventbriteEventByUrlRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEventbriteEventByUrl
   */
  readonly url: string;
}

/**
 * Request parameters for getEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiGetEventsRequest
 */
export interface DefaultApiGetEventsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'startsAt' | 'endsAt' | 'name'}
   * @memberof DefaultApiGetEvents
   */
  readonly orderBy?: 'startsAt' | 'endsAt' | 'name';

  /**
   *
   * @type {'asc' | 'desc'}
   * @memberof DefaultApiGetEvents
   */
  readonly orderDirection?: 'asc' | 'desc';

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly id?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly priceCentsMin?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly priceCentsMax?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly endsBefore?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly startsAfter?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly categoryName?: string;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetEvents
   */
  readonly categoryId?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly searchString?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly dateRangeStart?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetEvents
   */
  readonly dateRangeEnd?: string;
}

/**
 * Request parameters for getGenericPageById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetGenericPageByIdRequest
 */
export interface DefaultApiGetGenericPageByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetGenericPageById
   */
  readonly id: number;
}

/**
 * Request parameters for getGenericPageBySlug operation in DefaultApi.
 * @export
 * @interface DefaultApiGetGenericPageBySlugRequest
 */
export interface DefaultApiGetGenericPageBySlugRequest {
  /**
   *
   * @type {KnownGenericPageSlug}
   * @memberof DefaultApiGetGenericPageBySlug
   */
  readonly slug: KnownGenericPageSlug;
}

/**
 * Request parameters for getLocationOfEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetLocationOfEventRequest
 */
export interface DefaultApiGetLocationOfEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetLocationOfEvent
   */
  readonly id: number;
}

/**
 * Request parameters for getOrganizerById operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOrganizerByIdRequest
 */
export interface DefaultApiGetOrganizerByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetOrganizerById
   */
  readonly id: number;
}

/**
 * Request parameters for getOrganizerByName operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOrganizerByNameRequest
 */
export interface DefaultApiGetOrganizerByNameRequest {
  /**
   *
   * @type {OrganizerNames}
   * @memberof DefaultApiGetOrganizerByName
   */
  readonly name: OrganizerNames;
}

/**
 * Request parameters for getPointOfInterestsInEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPointOfInterestsInEventRequest
 */
export interface DefaultApiGetPointOfInterestsInEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPointOfInterestsInEvent
   */
  readonly id: number;
}

/**
 * Request parameters for getPublicEvents operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPublicEventsRequest
 */
export interface DefaultApiGetPublicEventsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly page?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly pageSize?: number;

  /**
   *
   * @type {'startsAt' | 'endsAt' | 'name'}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly orderBy?: 'startsAt' | 'endsAt' | 'name';

  /**
   *
   * @type {'asc' | 'desc'}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly orderDirection?: 'asc' | 'desc';

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly id?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly priceCentsMin?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly priceCentsMax?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly endsBefore?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly startsAfter?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly categoryName?: string;

  /**
   *
   * @type {number}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly categoryId?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly searchString?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly dateRangeStart?: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetPublicEvents
   */
  readonly dateRangeEnd?: string;
}

/**
 * Request parameters for getSponsorsInEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiGetSponsorsInEventRequest
 */
export interface DefaultApiGetSponsorsInEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetSponsorsInEvent
   */
  readonly id: number;
}

/**
 * Request parameters for getUsers operation in DefaultApi.
 * @export
 * @interface DefaultApiGetUsersRequest
 */
export interface DefaultApiGetUsersRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiGetUsers
   */
  readonly limit?: number;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetUsers
   */
  readonly paginationToken?: string;
}

/**
 * Request parameters for insertEmailListEntry operation in DefaultApi.
 * @export
 * @interface DefaultApiInsertEmailListEntryRequest
 */
export interface DefaultApiInsertEmailListEntryRequest {
  /**
   *
   * @type {IEmailListEntryCreation}
   * @memberof DefaultApiInsertEmailListEntry
   */
  readonly iEmailListEntryCreation: IEmailListEntryCreation;
}

/**
 * Request parameters for inviteUser operation in DefaultApi.
 * @export
 * @interface DefaultApiInviteUserRequest
 */
export interface DefaultApiInviteUserRequest {
  /**
   *
   * @type {CreateUserParams}
   * @memberof DefaultApiInviteUser
   */
  readonly createUserParams: CreateUserParams;
}

/**
 * Request parameters for replaceMapStaticImageForEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceMapStaticImageForEventRequest
 */
export interface DefaultApiReplaceMapStaticImageForEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiReplaceMapStaticImageForEvent
   */
  readonly id: number;

  /**
   *
   * @type {any}
   * @memberof DefaultApiReplaceMapStaticImageForEvent
   */
  readonly mapImage: any;
}

/**
 * Request parameters for replaceOrganizerAboutPdfById operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceOrganizerAboutPdfByIdRequest
 */
export interface DefaultApiReplaceOrganizerAboutPdfByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiReplaceOrganizerAboutPdfById
   */
  readonly id: number;

  /**
   *
   * @type {any}
   * @memberof DefaultApiReplaceOrganizerAboutPdfById
   */
  readonly aboutPdf: any;
}

/**
 * Request parameters for replaceOrganizerAboutPdfByName operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceOrganizerAboutPdfByNameRequest
 */
export interface DefaultApiReplaceOrganizerAboutPdfByNameRequest {
  /**
   *
   * @type {OrganizerNames}
   * @memberof DefaultApiReplaceOrganizerAboutPdfByName
   */
  readonly name: OrganizerNames;

  /**
   *
   * @type {any}
   * @memberof DefaultApiReplaceOrganizerAboutPdfByName
   */
  readonly aboutPdf: any;
}

/**
 * Request parameters for replaceThumbnailImageForEventCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceThumbnailImageForEventCategoryRequest
 */
export interface DefaultApiReplaceThumbnailImageForEventCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiReplaceThumbnailImageForEventCategory
   */
  readonly id: number;

  /**
   *
   * @type {any}
   * @memberof DefaultApiReplaceThumbnailImageForEventCategory
   */
  readonly thumbnailImage: any;
}

/**
 * Request parameters for replaceThumbnailImageForImageGallery operation in DefaultApi.
 * @export
 * @interface DefaultApiReplaceThumbnailImageForImageGalleryRequest
 */
export interface DefaultApiReplaceThumbnailImageForImageGalleryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiReplaceThumbnailImageForImageGallery
   */
  readonly id: number;

  /**
   *
   * @type {any}
   * @memberof DefaultApiReplaceThumbnailImageForImageGallery
   */
  readonly thumbnailImage: any;
}

/**
 * Request parameters for sendMessageToTopic operation in DefaultApi.
 * @export
 * @interface DefaultApiSendMessageToTopicRequest
 */
export interface DefaultApiSendMessageToTopicRequest {
  /**
   *
   * @type {IPushNotificationCreation}
   * @memberof DefaultApiSendMessageToTopic
   */
  readonly iPushNotificationCreation: IPushNotificationCreation;
}

/**
 * Request parameters for updateAct operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateActRequest
 */
export interface DefaultApiUpdateActRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateAct
   */
  readonly id: number;

  /**
   *
   * @type {IActUpdateRequest}
   * @memberof DefaultApiUpdateAct
   */
  readonly iActUpdateRequest: IActUpdateRequest;
}

/**
 * Request parameters for updateEvent operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateEventRequest
 */
export interface DefaultApiUpdateEventRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateEvent
   */
  readonly id: number;

  /**
   *
   * @type {IEventUpdateRequest}
   * @memberof DefaultApiUpdateEvent
   */
  readonly iEventUpdateRequest: IEventUpdateRequest;
}

/**
 * Request parameters for updateEventCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateEventCategoryRequest
 */
export interface DefaultApiUpdateEventCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateEventCategory
   */
  readonly id: number;

  /**
   *
   * @type {IEventCategoryUpdateRequest}
   * @memberof DefaultApiUpdateEventCategory
   */
  readonly iEventCategoryUpdateRequest: IEventCategoryUpdateRequest;
}

/**
 * Request parameters for updateEventDay operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateEventDayRequest
 */
export interface DefaultApiUpdateEventDayRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateEventDay
   */
  readonly id: number;

  /**
   *
   * @type {IEventDayUpdateRequest}
   * @memberof DefaultApiUpdateEventDay
   */
  readonly iEventDayUpdateRequest: IEventDayUpdateRequest;
}

/**
 * Request parameters for updateGenericPageById operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateGenericPageByIdRequest
 */
export interface DefaultApiUpdateGenericPageByIdRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateGenericPageById
   */
  readonly id: number;

  /**
   *
   * @type {IGenericPageUpdateRequest}
   * @memberof DefaultApiUpdateGenericPageById
   */
  readonly iGenericPageUpdateRequest: IGenericPageUpdateRequest;
}

/**
 * Request parameters for updateGenericPageBySlug operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateGenericPageBySlugRequest
 */
export interface DefaultApiUpdateGenericPageBySlugRequest {
  /**
   *
   * @type {KnownGenericPageSlug}
   * @memberof DefaultApiUpdateGenericPageBySlug
   */
  readonly slug: KnownGenericPageSlug;

  /**
   *
   * @type {IGenericPageUpdateRequest}
   * @memberof DefaultApiUpdateGenericPageBySlug
   */
  readonly iGenericPageUpdateRequest: IGenericPageUpdateRequest;
}

/**
 * Request parameters for updateImageGalleryImages operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateImageGalleryImagesRequest
 */
export interface DefaultApiUpdateImageGalleryImagesRequest {
  /**
   *
   * @type {IImageGalleryImageUpdateManyRequest}
   * @memberof DefaultApiUpdateImageGalleryImages
   */
  readonly iImageGalleryImageUpdateManyRequest: IImageGalleryImageUpdateManyRequest;
}

/**
 * Request parameters for updateMapLocation operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateMapLocationRequest
 */
export interface DefaultApiUpdateMapLocationRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateMapLocation
   */
  readonly id: number;

  /**
   *
   * @type {IMapLocationUpdateRequest}
   * @memberof DefaultApiUpdateMapLocation
   */
  readonly iMapLocationUpdateRequest: IMapLocationUpdateRequest;
}

/**
 * Request parameters for updatePointOfInterest operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdatePointOfInterestRequest
 */
export interface DefaultApiUpdatePointOfInterestRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdatePointOfInterest
   */
  readonly id: number;

  /**
   *
   * @type {IPointOfInterestUpdateRequest}
   * @memberof DefaultApiUpdatePointOfInterest
   */
  readonly iPointOfInterestUpdateRequest: IPointOfInterestUpdateRequest;
}

/**
 * Request parameters for updatePointOfInterestCategory operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdatePointOfInterestCategoryRequest
 */
export interface DefaultApiUpdatePointOfInterestCategoryRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdatePointOfInterestCategory
   */
  readonly id: number;

  /**
   *
   * @type {IPointOfInterestCategoryUpdateRequest}
   * @memberof DefaultApiUpdatePointOfInterestCategory
   */
  readonly iPointOfInterestCategoryUpdateRequest: IPointOfInterestCategoryUpdateRequest;
}

/**
 * Request parameters for updateSponsor operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateSponsorRequest
 */
export interface DefaultApiUpdateSponsorRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultApiUpdateSponsor
   */
  readonly id: number;

  /**
   *
   * @type {ISponsorUpdateRequest}
   * @memberof DefaultApiUpdateSponsor
   */
  readonly iSponsorUpdateRequest: ISponsorUpdateRequest;
}

/**
 * Request parameters for updateUser operation in DefaultApi.
 * @export
 * @interface DefaultApiUpdateUserRequest
 */
export interface DefaultApiUpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiUpdateUser
   */
  readonly username: string;

  /**
   *
   * @type {UpdateUserParams}
   * @memberof DefaultApiUpdateUser
   */
  readonly updateUserParams: UpdateUserParams;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {DefaultApiCreateActRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createAct(requestParameters: DefaultApiCreateActRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createAct(requestParameters.iActCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateActImageGalleryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createActImageGallery(
    requestParameters: DefaultApiCreateActImageGalleryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createActImageGallery(requestParameters.id, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createEvent(requestParameters: DefaultApiCreateEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createEvent(requestParameters.iEventCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateEventCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createEventCategory(requestParameters: DefaultApiCreateEventCategoryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createEventCategory(requestParameters.iEventCategoryCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateEventDayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createEventDay(requestParameters: DefaultApiCreateEventDayRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createEventDay(requestParameters.iEventDayCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateEventImageGalleryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createEventImageGallery(
    requestParameters: DefaultApiCreateEventImageGalleryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createEventImageGallery(requestParameters.id, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateEventSubmissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createEventSubmission(
    requestParameters: DefaultApiCreateEventSubmissionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createEventSubmission(requestParameters.iEventSubmissionCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateGenericPageRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createGenericPage(requestParameters: DefaultApiCreateGenericPageRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createGenericPage(requestParameters.iGenericPageCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateImageGalleryImagesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createImageGalleryImages(
    requestParameters: DefaultApiCreateImageGalleryImagesRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createImageGalleryImages(requestParameters.id, requestParameters.images, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateManySponsorsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createManySponsors(requestParameters: DefaultApiCreateManySponsorsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createManySponsors(requestParameters.logoFiles, requestParameters.eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateMapLocationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createMapLocation(requestParameters: DefaultApiCreateMapLocationRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createMapLocation(requestParameters.iMapLocationCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateOrganizerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createOrganizer(requestParameters: DefaultApiCreateOrganizerRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createOrganizer(requestParameters.iOrganizerCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreatePointOfInterestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createPointOfInterest(
    requestParameters: DefaultApiCreatePointOfInterestRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createPointOfInterest(requestParameters.iPointOfInterestCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreatePointOfInterestCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createPointOfInterestCategory(
    requestParameters: DefaultApiCreatePointOfInterestCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createPointOfInterestCategory(requestParameters.iPointOfInterestCategoryCreationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreatePointOfInterestImageGalleryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createPointOfInterestImageGallery(
    requestParameters: DefaultApiCreatePointOfInterestImageGalleryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createPointOfInterestImageGallery(requestParameters.id, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiCreateSponsorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createSponsor(requestParameters: DefaultApiCreateSponsorRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createSponsor(requestParameters.logoFile, requestParameters.eventId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteActRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteAct(requestParameters: DefaultApiDeleteActRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteAct(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteEmailListEntryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteEmailListEntry(requestParameters: DefaultApiDeleteEmailListEntryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteEmailListEntry(requestParameters.iEmailListEntryDeletion, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteEvent(requestParameters: DefaultApiDeleteEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteEventCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteEventCategory(requestParameters: DefaultApiDeleteEventCategoryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteEventCategory(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteEventDayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteEventDay(requestParameters: DefaultApiDeleteEventDayRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteEventDay(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteEventSubmissionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteEventSubmission(
    requestParameters: DefaultApiDeleteEventSubmissionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deleteEventSubmission(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteGenericPageByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteGenericPageById(
    requestParameters: DefaultApiDeleteGenericPageByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deleteGenericPageById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteGenericPageBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteGenericPageBySlug(
    requestParameters: DefaultApiDeleteGenericPageBySlugRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deleteGenericPageBySlug(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteImageGalleryImagesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteImageGalleryImages(
    requestParameters: DefaultApiDeleteImageGalleryImagesRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deleteImageGalleryImages(requestParameters.iImageGalleryDeleteManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteManySponsorsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteManySponsors(requestParameters: DefaultApiDeleteManySponsorsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteManySponsors(requestParameters.ids, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteMapLocationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteMapLocation(requestParameters: DefaultApiDeleteMapLocationRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteMapLocation(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeletePointOfInterestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deletePointOfInterest(
    requestParameters: DefaultApiDeletePointOfInterestRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deletePointOfInterest(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeletePointOfInterestCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deletePointOfInterestCategory(
    requestParameters: DefaultApiDeletePointOfInterestCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deletePointOfInterestCategory(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteSponsorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteSponsor(requestParameters: DefaultApiDeleteSponsorRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteSponsor(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiDeleteUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteUser(requestParameters: DefaultApiDeleteUserRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteUser(requestParameters.username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetActsInEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getActsInEvent(requestParameters: DefaultApiGetActsInEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getActsInEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetDaysInEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getDaysInEvent(requestParameters: DefaultApiGetDaysInEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getDaysInEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEmailList(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getEmailList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEventCategories(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getEventCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetEventSubmissionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEventSubmissions(
    requestParameters: DefaultApiGetEventSubmissionsRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getEventSubmissions(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.orderBy,
        requestParameters.orderDirection,
        requestParameters.id,
        requestParameters.searchString,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetEventbriteEventByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEventbriteEventById(
    requestParameters: DefaultApiGetEventbriteEventByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getEventbriteEventById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetEventbriteEventByUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEventbriteEventByUrl(
    requestParameters: DefaultApiGetEventbriteEventByUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getEventbriteEventByUrl(requestParameters.url, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetEventsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEvents(requestParameters: DefaultApiGetEventsRequest = {}, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getEvents(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.orderBy,
        requestParameters.orderDirection,
        requestParameters.id,
        requestParameters.priceCentsMin,
        requestParameters.priceCentsMax,
        requestParameters.endsBefore,
        requestParameters.startsAfter,
        requestParameters.categoryName,
        requestParameters.categoryId,
        requestParameters.searchString,
        requestParameters.dateRangeStart,
        requestParameters.dateRangeEnd,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetGenericPageByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getGenericPageById(requestParameters: DefaultApiGetGenericPageByIdRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getGenericPageById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetGenericPageBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getGenericPageBySlug(requestParameters: DefaultApiGetGenericPageBySlugRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getGenericPageBySlug(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getGenericPages(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getGenericPages(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getLatestPushNotifications(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getLatestPushNotifications(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetLocationOfEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getLocationOfEvent(requestParameters: DefaultApiGetLocationOfEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getLocationOfEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetOrganizerByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOrganizerById(requestParameters: DefaultApiGetOrganizerByIdRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOrganizerById(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetOrganizerByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOrganizerByName(requestParameters: DefaultApiGetOrganizerByNameRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOrganizerByName(requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPointOfInterestCategories(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPointOfInterestCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetPointOfInterestsInEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPointOfInterestsInEvent(
    requestParameters: DefaultApiGetPointOfInterestsInEventRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getPointOfInterestsInEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetPublicEventsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPublicEvents(requestParameters: DefaultApiGetPublicEventsRequest = {}, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPublicEvents(
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.orderBy,
        requestParameters.orderDirection,
        requestParameters.id,
        requestParameters.priceCentsMin,
        requestParameters.priceCentsMax,
        requestParameters.endsBefore,
        requestParameters.startsAfter,
        requestParameters.categoryName,
        requestParameters.categoryId,
        requestParameters.searchString,
        requestParameters.dateRangeStart,
        requestParameters.dateRangeEnd,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetSponsorsInEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getSponsorsInEvent(requestParameters: DefaultApiGetSponsorsInEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getSponsorsInEvent(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiGetUsersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getUsers(requestParameters: DefaultApiGetUsersRequest = {}, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getUsers(requestParameters.limit, requestParameters.paginationToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiInsertEmailListEntryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public insertEmailListEntry(requestParameters: DefaultApiInsertEmailListEntryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .insertEmailListEntry(requestParameters.iEmailListEntryCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiInviteUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public inviteUser(requestParameters: DefaultApiInviteUserRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .inviteUser(requestParameters.createUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReplaceMapStaticImageForEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public replaceMapStaticImageForEvent(
    requestParameters: DefaultApiReplaceMapStaticImageForEventRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .replaceMapStaticImageForEvent(requestParameters.id, requestParameters.mapImage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReplaceOrganizerAboutPdfByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public replaceOrganizerAboutPdfById(
    requestParameters: DefaultApiReplaceOrganizerAboutPdfByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .replaceOrganizerAboutPdfById(requestParameters.id, requestParameters.aboutPdf, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReplaceOrganizerAboutPdfByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public replaceOrganizerAboutPdfByName(
    requestParameters: DefaultApiReplaceOrganizerAboutPdfByNameRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .replaceOrganizerAboutPdfByName(requestParameters.name, requestParameters.aboutPdf, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReplaceThumbnailImageForEventCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public replaceThumbnailImageForEventCategory(
    requestParameters: DefaultApiReplaceThumbnailImageForEventCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .replaceThumbnailImageForEventCategory(requestParameters.id, requestParameters.thumbnailImage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiReplaceThumbnailImageForImageGalleryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public replaceThumbnailImageForImageGallery(
    requestParameters: DefaultApiReplaceThumbnailImageForImageGalleryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .replaceThumbnailImageForImageGallery(requestParameters.id, requestParameters.thumbnailImage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public seedDatabase(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .seedDatabase(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiSendMessageToTopicRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public sendMessageToTopic(requestParameters: DefaultApiSendMessageToTopicRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .sendMessageToTopic(requestParameters.iPushNotificationCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public status(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .status(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public testAuthentication(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .testAuthentication(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateActRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateAct(requestParameters: DefaultApiUpdateActRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateAct(requestParameters.id, requestParameters.iActUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateEventRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateEvent(requestParameters: DefaultApiUpdateEventRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateEvent(requestParameters.id, requestParameters.iEventUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateEventCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateEventCategory(requestParameters: DefaultApiUpdateEventCategoryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateEventCategory(requestParameters.id, requestParameters.iEventCategoryUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateEventDayRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateEventDay(requestParameters: DefaultApiUpdateEventDayRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateEventDay(requestParameters.id, requestParameters.iEventDayUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateGenericPageByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateGenericPageById(
    requestParameters: DefaultApiUpdateGenericPageByIdRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updateGenericPageById(requestParameters.id, requestParameters.iGenericPageUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateGenericPageBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateGenericPageBySlug(
    requestParameters: DefaultApiUpdateGenericPageBySlugRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updateGenericPageBySlug(requestParameters.slug, requestParameters.iGenericPageUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateImageGalleryImagesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateImageGalleryImages(
    requestParameters: DefaultApiUpdateImageGalleryImagesRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updateImageGalleryImages(requestParameters.iImageGalleryImageUpdateManyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateMapLocationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateMapLocation(requestParameters: DefaultApiUpdateMapLocationRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateMapLocation(requestParameters.id, requestParameters.iMapLocationUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdatePointOfInterestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updatePointOfInterest(
    requestParameters: DefaultApiUpdatePointOfInterestRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updatePointOfInterest(requestParameters.id, requestParameters.iPointOfInterestUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdatePointOfInterestCategoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updatePointOfInterestCategory(
    requestParameters: DefaultApiUpdatePointOfInterestCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updatePointOfInterestCategory(
        requestParameters.id,
        requestParameters.iPointOfInterestCategoryUpdateRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateSponsorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateSponsor(requestParameters: DefaultApiUpdateSponsorRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateSponsor(requestParameters.id, requestParameters.iSponsorUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DefaultApiUpdateUserRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateUser(requestParameters: DefaultApiUpdateUserRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .updateUser(requestParameters.username, requestParameters.updateUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
