import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { PlaceType } from './GooglePlacesAutocomplete';

const libraries: Libraries = ['places'];

interface GeocoderType {
  current: google.maps.Geocoder | null;
}

const geocoder = { current: null } as GeocoderType;

const containerStyle = {
  width: '350px',
  height: '350px',
};

interface MapProps {
  selectedAddressDetails?: google.maps.places.PlaceResult | null;
  onClickAndReverseGeocode?: (place: PlaceType, latLng: LatLng) => void;
  markerPosition: LatLng | null;
  center: LatLng;
}

export interface LatLng {
  lat: number;
  lng: number;
}

function Map({ selectedAddressDetails, onClickAndReverseGeocode, markerPosition, center }: MapProps) {
  const [map, setMap] = React.useState<google.maps.Map>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_WEB_GEO_KEY!,
    libraries,
  });

  const onUnmount = React.useCallback(function callback() {
    setMap(undefined);
  }, []);

  const reverseGeocode = async (latLng: LatLng) => {
    const res = await geocoder.current?.geocode({ location: latLng });
    if (res?.results?.[0]) {
      const result = res!.results![0];
      onClickAndReverseGeocode?.(
        {
          description: result.formatted_address,
          structured_formatting: null,
          place_id: result.place_id,
        },
        latLng,
      );
    }
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (!geocoder.current && (window as any).google) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }
    if (!geocoder.current) {
      return undefined;
    }
    if (e.latLng) reverseGeocode({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  if (!isLoaded) return null;

  return (
    <GoogleMap
      id="google-map"
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onUnmount={onUnmount}
      onClick={onClick}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
}

export default React.memo(Map);
