import {
  Card,
  Checkbox,
  CircularProgress,
  css,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  IEvent,
  IEventCategory,
  IEventCreation,
  IEventSubmission,
  IEventUpdate,
  IImageGalleryImage,
  ISponsor,
} from '../api-client/autogenerated';
import { useGetEventCategories } from '../api-client/hooks/useGetEventCategories';
import GooglePlacesAutocomplete, { PlaceType } from '../components/GooglePlacesAutocomplete';
import Map, { LatLng } from '../components/Map';
import UploadBox from '../components/UploadBox';
import UploadedImage from '../components/UploadedImage';
import UploadExtraImage from '../components/UploadExtraImage';
import ColorItem from '../components/ColorItem';
import FilledButton from '../components/buttons/FilledButton';
import { getAuthenticatedApiClient } from '../api-client/api-client';
import { useCreateEvent } from '../api-client/hooks/useCreateEvent';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateMapLocation } from '../api-client/hooks/useCreateMapLocation';
import { useUpdateEvent } from '../api-client/hooks/useUpdateEvent';
import { useGetEvents } from '../api-client/hooks/useGetEvents';
import { useDeleteImages } from '../api-client/hooks/useDeleteImages';
import { useUploadImages } from '../api-client/hooks/useUploadImages';
import { useCreateSponsors } from '../api-client/hooks/useCreateSponsors';
import { useDeleteSponsor } from '../api-client/hooks/useDeleteSponsor';
import { useGetSponsors } from '../api-client/hooks/useGetSponsors';
import { useReplaceThumbnailImage } from '../api-client/hooks/useReplaceThumbnailImage';
import { useReplaceMapImage } from '../api-client/hooks/useReplaceMapImage';
import { useGetEventSubmissions } from '../api-client/hooks/useGetEventSubmissions';
import { View } from '@aws-amplify/ui-react';
import { useDeleteEventSubmission } from '../api-client/hooks/useDeleteEventSubmission';

/** @jsxImportSource @emotion/react */

const styles = {
  textfield: css`
    width: 350px;
    margin-bottom: 32px;
  `,
};

export default function CreateEventPage() {
  const { eventId, submissionId } = useParams();
  const navigate = useNavigate();

  const [submission, setSubmission] = useState<IEventSubmission>();
  const [submissionContactInfo, setSubmissionContactInfo] = useState<IEventSubmission>();

  const { data: eventsData } = useGetEvents({ id: Number(eventId) }, { enabled: !!eventId });
  const { data: submissions } = useGetEventSubmissions({ id: Number(submissionId) }, { enabled: !!submissionId });
  const { data: categories } = useGetEventCategories();
  const { data: sponsors } = useGetSponsors(Number(eventId), {
    enabled: !!eventId,
  });
  const { data: sponsorsFromSubmission } = useGetSponsors(submission?.eventId || -1, { enabled: !!submission });

  const events = eventsData?.data;

  const { mutateAsync: createEvent, isLoading } = useCreateEvent();
  const { mutateAsync: updateEvent } = useUpdateEvent();
  const { mutateAsync: createMapLocation } = useCreateMapLocation();
  const { mutateAsync: uploadImages } = useUploadImages();
  const { mutateAsync: deleteImages } = useDeleteImages();
  const { mutateAsync: replaceThumbnailImage } = useReplaceThumbnailImage();
  const { mutateAsync: replaceMapImage } = useReplaceMapImage();
  const { mutateAsync: createSponsors } = useCreateSponsors();
  const { mutateAsync: deleteSponsor } = useDeleteSponsor();
  const { mutateAsync: deleteEventSubmission, isLoading: isDeleting } = useDeleteEventSubmission();

  const [eventbriteLink, setEventbriteLink] = useState('');
  const [name, setName] = useState('');
  const [scheduleName, setScheduleName] = useState('');
  const [description, setDescription] = useState('');
  const [hostName, setHostName] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(null);
  const [ticketPurchaseLink, setTicketPurchaseLink] = useState<string>('');
  const [showDate, setShowDate] = useState<Moment | null>(null);
  const [hideDate, setHideDate] = useState<Moment | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(null);
  const [detailedAddress, setDetailedAddress] = useState<google.maps.places.PlaceResult | null>(null);
  const [existingThumbnailImageURL, setExistingThumbnailImageUrl] = useState<string>();
  const [newThumbnailImage, setNewThumbnailImage] = useState<File | null>(null);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<IImageGalleryImage[]>([]);
  const [newSponsorImages, setNewSponsorImages] = useState<File[]>([]);
  const [existingSponsorImages, setExistingSponsorImages] = useState<ISponsor[]>([]);
  const [newMapImage, setNewMapImage] = useState<File | null>(null);
  const [existingMapImage, setExistingMapImage] = useState<string>();
  const [existingMapImageDeleted, setExistingMapImageDeleted] = useState(false);
  const [primaryColor, setPrimaryColor] = useState<string>('#0B6F49');
  const [secondaryColor, setSecondaryColor] = useState<string>('#F1EFDE');
  const [accentColor, setAccentColor] = useState<string>('FFFFFF');
  const [complimentaryColor, setComplimentaryColor] = useState<string>('#F1EFDE');
  const [doNotDisplay, setDoNotDisplay] = useState(false);
  const [ticketPriceMinCents, setTicketPriceMinCents] = useState<string>('');
  const [ticketPriceMaxCents, setTicketPriceMaxCents] = useState<string>('');
  const [mapMarkerPosition, setMapMarkerPosition] = useState<LatLng | null>(null);
  const [doNotMoveMarkerFlag, setDoNotMoveMarkerFlag] = useState(false);
  const [mapCenter, setMapCenter] = useState<LatLng>({ lat: 29.918, lng: -90.063 });

  const selectRef = useRef<any>(null);
  const originalEvent = useRef<IEvent | null>(null);

  //when updating event, need to keep track of which events were initially selected
  const [initialCategoriesBeforeUpdate, setInitialCategoriesBeforeUpdate] = useState<number[]>();

  const populateFields = async (event: IEvent) => {
    console.log('Event populating: ', event);
    if (!event) return;
    if (event.eventSubmission) {
      setSubmissionContactInfo(event.eventSubmission);
    }
    originalEvent.current = event;
    setName(event.name);
    setScheduleName(event.actScheduleName || '');
    setDescription(event.description || '');
    setHostName(event.hostName || '');
    const cats: IEventCategory[] = categories!
      .filter((x) => event.categories!.map((y) => y.name).includes(x.name))
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          thumbnailImageUrl: x.thumbnailImageUrl,
          iconName: x.iconName,
          _count: { events: 0 },
        };
      });
    setSelectedCategories(cats.map((x) => x.id));
    setInitialCategoriesBeforeUpdate(cats.map((x) => x.id));
    setSelectedStartDate(moment(event.startsAt));
    setSelectedEndDate(moment(event.endsAt));
    setTicketPurchaseLink(event.ticketPurchaseLink || '');
    setShowDate(moment(event.displayAfter));
    setHideDate(moment(event.hideAfter));
    setSelectedAddress(
      event.mapLocation?.address
        ? { description: event.mapLocation?.address, structured_formatting: null, place_id: '0' }
        : null,
    );
    setPrimaryColor(`#${event.primaryColor}`);
    setSecondaryColor(event.secondaryColor ? `#${event.secondaryColor}` : '');
    setComplimentaryColor(event.complimentaryColor ? `#${event.complimentaryColor}` : '');
    setAccentColor(event.accentColor ? `#${event.accentColor}` : '');
    setDoNotDisplay(moment() < moment(event.displayAfter));
    if (event.ticketPriceMaxCents) {
      setConvertedPrice((event.ticketPriceMaxCents / 100).toString(), setTicketPriceMaxCents);
    }
    if (event.ticketPriceMinCents) {
      setConvertedPrice((event.ticketPriceMinCents / 100).toString(), setTicketPriceMinCents);
    }
    setMapMarkerPosition({ lat: event.mapLocation?.latitude || 0, lng: event.mapLocation?.longitude || 0 });
    setMapCenter({ lat: event.mapLocation?.latitude || 0, lng: event.mapLocation?.longitude || 0 });

    setExistingImages(event.imageGallery?.images || []);
    setExistingThumbnailImageUrl(event.imageGallery?.thumbnailImageUrl || undefined);
    setExistingMapImage(event.mapStaticImageUrl || undefined);

    selectRef?.current?.forceUpdate();
  };

  useEffect(() => {
    if (events && eventId && categories) {
      const event = events?.find((e) => e.id === Number(eventId));
      if (event) {
        populateFields(event);
      }
    }
  }, [eventId, categories, events]);

  useEffect(() => {
    if (submissions) {
      const sub = submissions.data.find((x) => x.id === Number(submissionId));
      if (sub) {
        setSubmission(sub);
        setSubmissionContactInfo(sub);
        populateFields(sub!.event);
      }
    }
  }, [submissionId, submissions]);

  useEffect(() => {
    if (sponsors) {
      setExistingSponsorImages(sponsors);
    }
  }, [sponsors]);

  useEffect(() => {
    if (sponsorsFromSubmission) {
      setExistingSponsorImages(sponsorsFromSubmission);
    }
  }, [sponsorsFromSubmission]);

  const createOrUpdateEvent = async () => {
    let response: IEvent;
    const mapData = {
      address: selectedAddress!.description,
      latitude: mapMarkerPosition!.lat,
      longitude: mapMarkerPosition!.lng,
    };
    if (!originalEvent.current) {
      const event: IEventCreation = {
        primaryColor: primaryColor.replace('#', ''),
        secondaryColor: secondaryColor.replace('#', '') || null,
        accentColor: accentColor.replace('#', '') || null,
        complimentaryColor: complimentaryColor.replace('#', '') || null,
        name,
        description,
        ticketPurchaseLink,
        startsAt: selectedStartDate!.toISOString(),
        endsAt: selectedEndDate!.toISOString(),
        displayAfter: doNotDisplay ? showDate?.toISOString() || moment().toISOString() : moment().toISOString(),
        hideAfter: hideDate?.toISOString() || selectedEndDate!.toISOString(),
        ticketPriceMinCents: Number(ticketPriceMinCents.replace(/[^0-9]/gi, '')),
        ticketPriceMaxCents: Number(ticketPriceMaxCents.replace(/[^0-9]/gi, '')),
        hostName,
        mapStaticImageUrl: '',
        actScheduleName: scheduleName,
        instructions: null,
        isSubmissionDraft: false,
      };
      response = await createEvent({ event, mapLocation: mapData, categoryIds: selectedCategories });
    } else {
      const mapLocation =
        mapData.latitude !== originalEvent.current.mapLocation?.latitude ||
        mapData.longitude !== originalEvent.current.mapLocation?.longitude
          ? await createMapLocation(mapData)
          : null;
      const update: IEventUpdate = {
        primaryColor:
          primaryColor.replace('#', '') !== originalEvent.current.primaryColor
            ? primaryColor.replace('#', '')
            : undefined,
        secondaryColor:
          secondaryColor.replace('#', '') !== originalEvent.current.secondaryColor
            ? secondaryColor.replace('#', '') || null
            : undefined,
        accentColor:
          accentColor.replace('#', '') !== originalEvent.current.accentColor
            ? accentColor.replace('#', '') || null
            : undefined,
        complimentaryColor:
          complimentaryColor.replace('#', '') !== originalEvent.current.complimentaryColor
            ? complimentaryColor.replace('#', '') || null
            : undefined,
        name: name !== originalEvent.current.name ? name : undefined,
        description: description !== originalEvent.current.description ? description : undefined,
        hostName: hostName !== originalEvent.current.hostName ? hostName : undefined,
        ticketPurchaseLink:
          ticketPurchaseLink !== originalEvent.current.ticketPurchaseLink ? ticketPurchaseLink : undefined,
        ticketPriceMinCents: Number(ticketPriceMinCents.replace(/[^0-9]/gi, '')),
        ticketPriceMaxCents: Number(ticketPriceMaxCents.replace(/[^0-9]/gi, '')),
        startsAt:
          selectedStartDate?.toISOString() !== originalEvent.current.startsAt
            ? selectedStartDate?.toISOString()
            : undefined,
        endsAt:
          selectedEndDate?.toISOString() !== originalEvent.current.endsAt ? selectedEndDate?.toISOString() : undefined,
        displayAfter:
          showDate?.toISOString() !== originalEvent.current.displayAfter ? showDate?.toISOString() : undefined,
        hideAfter: hideDate?.toISOString() !== originalEvent.current.hideAfter ? hideDate?.toISOString() : undefined,
        mapLocationId: mapLocation?.id || undefined,
        mapStaticImageUrl: existingMapImageDeleted ? null : undefined,
        actScheduleName: scheduleName,
        isSubmissionDraft: false,
      };
      const categoriesToAdd: number[] = selectedCategories?.filter((x) => !initialCategoriesBeforeUpdate!.includes(x));
      const categoriesToRemove: number[] = initialCategoriesBeforeUpdate!.filter(
        (x) => !selectedCategories!.includes(x),
      );
      response = await updateEvent({
        id: originalEvent.current.id,
        iEventUpdateRequest: {
          addToCategories: categoriesToAdd,
          removeFromCategories: categoriesToRemove,
          event: update,
        },
      });
    }
    if (response.imageGallery) {
      if (newImages.length > 0) {
        await uploadImages({ id: response.imageGallery.id, images: newImages });
      }
      if (newSponsorImages.length > 0) {
        await createSponsors({ eventId: response.id.toString(), logoFiles: newSponsorImages });
      }
      if (newThumbnailImage) {
        await replaceThumbnailImage({ id: response.imageGallery.id, thumbnailImage: newThumbnailImage });
      }
      if (newMapImage) {
        await replaceMapImage({ id: response.id, mapImage: newMapImage });
      }
    }
    navigate(-1);
    originalEvent.current = null;
  };

  useEffect(() => {
    if (selectedEndDate) setHideDate(moment(selectedEndDate));
    else setHideDate(null);
  }, [selectedEndDate]);

  const handleCategoryChange = (e: SelectChangeEvent<number[]>) => {
    setSelectedCategories(e.target.value as number[]);
  };

  const renderCategories = (ids: number[]) => {
    const cats = ids.map((x) => categories!.find((y) => y.id === x)!);
    const names = cats.map((x) => x.name);
    return names.join(', ');
  };

  const handleDeleteSponsor = async (id: number) => {
    await deleteSponsor(id);
    setExistingSponsorImages((prev) => prev.filter((x) => x.id !== id));
  };

  const deleteImage = async (id: number) => {
    await deleteImages([id]);
    setExistingImages((prev) => prev.filter((x) => x.id !== id));
  };

  const removeImage = (file: File) => {
    const remove = newImages.findIndex((x) => file === x);
    const imagesTemp = [...newImages];
    imagesTemp.splice(remove, 1);
    setNewImages(imagesTemp);
  };

  const addImage = (file: File) => {
    const imagesTemp = [...newImages];
    imagesTemp.push(file);
    setNewImages(imagesTemp);
  };

  const removeSponsorImage = (file: File) => {
    const remove = newSponsorImages.findIndex((x) => file === x);
    const sponsorImagesTemp = [...newSponsorImages];
    sponsorImagesTemp.splice(remove, 1);
    setNewSponsorImages(sponsorImagesTemp);
  };

  const addSponsorImage = (file: File) => {
    const sponsorImagesTemp = [...newSponsorImages];
    sponsorImagesTemp.push(file);
    setNewSponsorImages(sponsorImagesTemp);
  };

  const importEventbrite = () => {
    getAuthenticatedApiClient()
      .getEventbriteEventByUrl({ url: eventbriteLink })
      .then((x) => {
        setName(x.data.name || '');
        setDescription(x.data.description || '');
        setSelectedStartDate(moment(x.data.startsAt));
        setSelectedEndDate(moment(x.data.endsAt));
      });
  };

  const setPrice = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    updateState: Dispatch<SetStateAction<string>>,
  ) => {
    updateState(e.target.value.replace(/[^0-9\.]/gi, ''));
  };

  const convertPrice = (price: string) => {
    return Number(price).toLocaleString('en-EN', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const setConvertedPrice = (price: string, updateState: Dispatch<SetStateAction<string>>) => {
    let tempPrice = convertPrice(price);
    updateState(tempPrice);
  };

  const handleMapClicked = (place: PlaceType, latLng: LatLng) => {
    setSelectedAddress(place);
    setDoNotMoveMarkerFlag(true);
    setMapMarkerPosition(latLng);
  };

  const onAutocompleteSelectPlace = (place: google.maps.places.PlaceResult | null) => {
    setDetailedAddress(place);
    if (!doNotMoveMarkerFlag) {
      setMapCenter({ lat: place?.geometry?.location?.lat() || 0, lng: place?.geometry?.location?.lng() || 0 });
      setMapMarkerPosition({
        lat: place?.geometry?.location?.lat() || 0,
        lng: place?.geometry?.location?.lng() || 0,
      });
    }
    setDoNotMoveMarkerFlag(false);
  };

  const acceptSubmission = async () => {
    await createOrUpdateEvent();
    //await deleteEventSubmission({ id: submission!.id });
    navigate('/submissions');
  };

  const rejectSubmission = async () => {
    await deleteEventSubmission({ id: submission!.id });
    navigate('/submissions');
  };

  const eventButton = () =>
    !isLoading ? (
      <FilledButton
        onClick={createOrUpdateEvent}
        style={{ paddingLeft: 48, paddingTop: 16, paddingRight: 48, paddingBottom: 16 }}
      >
        {eventId ? 'Update' : 'Create'}
      </FilledButton>
    ) : (
      <CircularProgress />
    );

  const submissionButtons = () =>
    !isLoading && !isDeleting ? (
      <View style={{ display: 'flex' }}>
        <FilledButton
          onClick={acceptSubmission}
          style={{ paddingLeft: 32, paddingTop: 8, paddingRight: 32, paddingBottom: 8, marginRight: 48 }}
        >
          Accept
        </FilledButton>
        <FilledButton
          onClick={rejectSubmission}
          color="error"
          style={{ paddingLeft: 32, paddingTop: 8, paddingRight: 32, paddingBottom: 8 }}
        >
          Reject
        </FilledButton>
      </View>
    ) : (
      <CircularProgress />
    );

  return (
    <View>
      {submissionContactInfo && (
        <Card
          sx={{
            marginBottom: 4,
            width: 600,
            minHeight: 64,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 2,
            paddingBottom: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          elevation={4}
        >
          <Typography variant="h5">Contact Info</Typography>
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography>Name: {submissionContactInfo.contactName}</Typography>
            <Typography>Email: {submissionContactInfo.contactEmail}</Typography>
            <Typography>Phone: {submissionContactInfo.contactPhone}</Typography>
          </View>
        </Card>
      )}
      <Card
        sx={{ width: 600, minHeight: 64, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        elevation={4}
      >
        <TextField
          value={eventbriteLink}
          onChange={(e) => setEventbriteLink(e.target.value)}
          css={styles.textfield}
          size="small"
          label="Eventbrite Link"
        />
        <FilledButton onClick={() => importEventbrite()} style={{ marginBottom: 32 }}>
          Load Data
        </FilledButton>
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          css={styles.textfield}
          size="small"
          label="Name"
        />
        <TextField
          value={scheduleName}
          onChange={(e) => setScheduleName(e.target.value)}
          css={styles.textfield}
          size="small"
          label="Schedule Name"
        />
        <TextField
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          css={styles.textfield}
          size="small"
          multiline
          minRows={6}
          label="Description"
        />
        <TextField
          value={hostName}
          onChange={(e) => setHostName(e.target.value)}
          css={styles.textfield}
          size="small"
          label="Host Name"
        />
        <FormControl>
          <InputLabel style={{ backgroundColor: 'white' }}>Category</InputLabel>
          <Select
            ref={selectRef}
            value={selectedCategories}
            multiple
            css={styles.textfield}
            sx={{ color: 'black' }}
            onChange={(e) => handleCategoryChange(e)}
            renderValue={(selected) => renderCategories(selected)}
          >
            {categories?.map((x) => (
              <MenuItem key={x.id} value={x.id}>
                <Checkbox checked={selectedCategories.indexOf(x.id) > -1} />
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          value={ticketPurchaseLink}
          onChange={(e) => setTicketPurchaseLink(e.target.value)}
          css={styles.textfield}
          size="small"
          label="Ticket Purchase Link"
        />
        <TextField
          value={ticketPriceMinCents}
          onChange={(e) => setPrice(e, setTicketPriceMinCents)}
          onBlur={() => setConvertedPrice(ticketPriceMinCents, setTicketPriceMinCents)}
          onFocus={(e) => setPrice(e, setTicketPriceMinCents)}
          css={styles.textfield}
          size="small"
          label="Minimum Ticket Price"
        />
        <TextField
          value={ticketPriceMaxCents}
          onChange={(e) => setPrice(e, setTicketPriceMaxCents)}
          onBlur={() => setConvertedPrice(ticketPriceMaxCents, setTicketPriceMaxCents)}
          onFocus={(e) => setPrice(e, setTicketPriceMaxCents)}
          css={styles.textfield}
          size="small"
          label="Maximum Ticket Price"
        />
        <DesktopDateTimePicker
          label="Start Time"
          inputFormat="MM/DD/YYYY h:mm A"
          css={styles.textfield}
          value={selectedStartDate}
          onChange={(value) => setSelectedStartDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDateTimePicker
          label="End Time"
          inputFormat="MM/DD/YYYY h:mm A"
          css={styles.textfield}
          value={selectedEndDate}
          onChange={(value) => setSelectedEndDate(value)}
          renderInput={(params) => <TextField {...params} />}
        />
        <GooglePlacesAutocomplete
          onDetailedInfoLoad={onAutocompleteSelectPlace}
          onChange={(address) => setSelectedAddress(address)}
          markerPosition={mapMarkerPosition}
          value={selectedAddress}
        />
        <div style={{ height: 16 }} />
        <Map
          selectedAddressDetails={detailedAddress}
          onClickAndReverseGeocode={handleMapClicked}
          markerPosition={mapMarkerPosition}
          center={mapCenter}
        />
        <div style={{ height: 16 }} />
        {!existingThumbnailImageURL && !newThumbnailImage && (
          <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Upload Thumbnail" />
        )}
        {!!existingThumbnailImageURL && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">Existing Thumbnail</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 16 }}>
                <UploadedImage imageStyle={{ maxWidth: 350 }} src={existingThumbnailImageURL} />
              </div>
            </div>
            {!newThumbnailImage && (
              <UploadBox onChange={(file) => setNewThumbnailImage(file)} text="Replace Thumbnail" />
            )}
          </>
        )}
        {newThumbnailImage && (
          <div>
            <Typography variant="h6">New Thumbnail</Typography>
            <UploadedImage
              imageStyle={{ maxWidth: 350 }}
              onRemove={() => setNewThumbnailImage(null)}
              src={URL.createObjectURL(newThumbnailImage)}
            />
          </div>
        )}
        {existingImages.length > 0 && <div style={{ height: 32 }} />}
        {existingImages.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Existing Images</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {existingImages.map((x) => (
                <div style={{ marginBottom: 16 }}>
                  <UploadedImage imageStyle={{ maxWidth: 350 }} onRemove={() => deleteImage(x.id)} src={x.url} />
                </div>
              ))}
            </div>
          </div>
        )}
        <div style={{ height: 32 }} />
        {newImages.length === 0 ? (
          <UploadBox onChange={(file) => setNewImages([file])} text="Upload Images" />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">New Images</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {newImages.map((x) => (
                <div style={{ marginBottom: 16 }}>
                  <UploadedImage
                    imageStyle={{ maxWidth: 350 }}
                    onRemove={() => removeImage(x)}
                    src={URL.createObjectURL(x)}
                  />
                </div>
              ))}
            </div>
            <UploadExtraImage onChange={(file) => addImage(file)} />
          </div>
        )}
        {!!existingSponsorImages.length && <div style={{ height: 32 }} />}
        {!!existingSponsorImages.length && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">Existing Sponsor Images</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {existingSponsorImages.map((x) => (
                <div style={{ marginBottom: 16 }}>
                  <UploadedImage
                    imageStyle={{ maxWidth: 350 }}
                    onRemove={() => handleDeleteSponsor(x.id)}
                    src={x.logoImageUrl}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div style={{ height: 32 }} />
        {newSponsorImages.length === 0 ? (
          <UploadBox onChange={(file) => setNewSponsorImages([file])} text="Upload Sponsor Images" />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">New Sponsor Images</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {newSponsorImages.map((x) => (
                <div style={{ marginBottom: 16 }}>
                  <UploadedImage
                    imageStyle={{ maxWidth: 350 }}
                    onRemove={() => removeSponsorImage(x)}
                    src={URL.createObjectURL(x)}
                  />
                </div>
              ))}
            </div>
            <UploadExtraImage onChange={(file) => addSponsorImage(file)} />
          </div>
        )}
        <div style={{ height: 32 }} />
        {!existingMapImage && !newMapImage && (
          <UploadBox onChange={(file) => setNewMapImage(file)} text="Upload Static Map Image" />
        )}
        {!!existingMapImage && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">Existing Static Map Image</Typography>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 16 }}>
                <UploadedImage
                  imageStyle={{ maxWidth: 350 }}
                  onRemove={() => {
                    setExistingMapImage(undefined);
                    setExistingMapImageDeleted(true);
                  }}
                  src={existingMapImage}
                />
              </div>
            </div>
            {!newMapImage && <UploadBox onChange={(file) => setNewMapImage(file)} text="Replace Static Map Image" />}
          </>
        )}
        {newMapImage && (
          <div>
            <Typography variant="h6">New Static Map Image</Typography>
            <UploadedImage
              imageStyle={{ maxWidth: 350 }}
              onRemove={() => setNewMapImage(null)}
              src={URL.createObjectURL(newMapImage)}
            />
          </div>
        )}
        <div style={{ height: 32 }} />
        <Typography variant="h6">Colors</Typography>
        <ColorItem name="Primary" color={primaryColor} onChange={(c) => setPrimaryColor(c)} />
        <ColorItem name="Secondary" color={secondaryColor} onChange={(c) => setSecondaryColor(c)} />
        <ColorItem name="Accent" color={accentColor} onChange={(c) => setAccentColor(c)} />
        <ColorItem name="Complimentary" color={complimentaryColor} onChange={(c) => setComplimentaryColor(c)} />
        <div style={{ height: 48 }} />
        <div style={{ width: 350, display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={<Checkbox checked={doNotDisplay} onChange={(e) => setDoNotDisplay(e.target!.checked)} />}
            label="Do not display on app"
            style={{ marginBottom: 48 }}
          />
          <Typography style={{ alignSelf: 'flex-start', marginBottom: 8 }} variant="body2">
            Display after this date.
          </Typography>
          <DesktopDateTimePicker
            label="Date"
            inputFormat="MM/DD/YYYY h:mm A"
            disabled={!doNotDisplay}
            css={styles.textfield}
            value={showDate}
            onChange={(value) => setShowDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
          <div style={{ height: 32 }} />
          <Typography style={{ alignSelf: 'flex-start', marginBottom: 8 }} variant="body2">
            Hide after this date.
          </Typography>
          <DesktopDateTimePicker
            label="Date"
            inputFormat="MM/DD/YYYY h:mm A"
            disabled={!doNotDisplay}
            css={styles.textfield}
            value={hideDate}
            onChange={(value) => setHideDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div style={{ height: 64 }} />
        {!submission ? eventButton() : submissionButtons()}
      </Card>
    </View>
  );
}
