import React from 'react';
import { Container, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilledButton from '../components/buttons/FilledButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetEvents } from '../api-client/hooks/useGetEvents';

type Props = {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

const EventActsListHeader = (props: Props) => {
  const { searchInput, setSearchInput } = props;
  const navigate = useNavigate();
  const { eventId } = useParams();

  const { data: eventsData } = useGetEvents({ id: Number(eventId) });

  const event = eventsData?.data?.[0];

  const sendEventToCreateActScreen = () => {
    navigate(`/events/${eventId}/create-event-act`);
  };

  return (
    <Container disableGutters sx={{ width: '100%', height: '65px', paddingTop: '10px' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Grid item>
          <Typography sx={{ paddingLeft: '30px', fontWeight: '700', fontSize: '30px' }}>{event?.name} Acts</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          <SearchIcon sx={{ position: 'absolute', left: 8 }} />
          <TextField
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search Acts"
            InputProps={{ sx: { paddingLeft: 3 } }}
            size="small"
          />
        </Grid>
        <Grid item sx={{ paddingRight: '30px' }}>
          <FilledButton onClick={sendEventToCreateActScreen}>Create New Act</FilledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventActsListHeader;
