import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0B6F49',
    },
    secondary: {
      main: '#F1EFDE',
    },
    text: {
      primary: '#000',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
  },
});
