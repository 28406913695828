import { Alert, Card, Snackbar, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import FilledButton from '../components/buttons/FilledButton';
import { useGetFerryText } from '../api-client/hooks/useGetFerryText';
import { useCreateFerryText } from '../api-client/hooks/useCreateFerryText';
import { useUpdateFerryText } from '../api-client/hooks/useUpdateFerryText';
import MDEditor from '@uiw/react-md-editor';

export default function AddAdminPage() {
  const [open, setOpen] = useState(false);

  const [text, setText] = useState('');

  const { data: ferryText } = useGetFerryText();

  const { mutateAsync: createFerryText } = useCreateFerryText();
  const { mutateAsync: updateFerryText } = useUpdateFerryText();

  useEffect(() => {
    if (ferryText) {
      setText(ferryText.markdown || '');
    }
  }, [ferryText]);

  const handleSubmit = async () => {
    try {
      if (ferryText) {
        await updateFerryText({ slug: 'ferry-info', iGenericPageUpdateRequest: { genericPage: { markdown: text } } });
      } else {
        await createFerryText({
          iGenericPageCreationRequest: { genericPage: { markdown: text, slug: 'ferry-info', html: text } },
        });
      }
      setOpen(true);
    } catch (e) {}
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Ferry text successfully updated!
        </Alert>
      </Snackbar>

      <Card data-color-mode="light" sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <MDEditor style={{ width: 700 }} height={500} value={text} onChange={(value) => setText(value || '')} />
        {/*
        <Typography style={{ minWidth: 380, marginBottom: 16 }} variant="h5">
          Preview:
        </Typography>
        <div style={{ marginTop: 0, width: 346, border: '2px dotted black', padding: '0px 16px 0px 16px' }}>
          <ReactMarkdown children={text} />
        </div>
       */}
        <FilledButton onClick={handleSubmit} style={{ marginTop: 48 }}>
          Update Ferry Text
        </FilledButton>
      </Card>
    </div>
  );
}
