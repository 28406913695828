import { useQuery } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiGetPointOfInterestsInEventRequest } from '../autogenerated';

const getPointsOfInterest = (parameters: DefaultApiGetPointOfInterestsInEventRequest) =>
  getAuthenticatedApiClient()
    .getPointOfInterestsInEvent(parameters)
    .then((x) => x.data);

export const useGetPointsOfInterest = (parameters: DefaultApiGetPointOfInterestsInEventRequest) =>
  useQuery(['points-of-interest', parameters.id], () => getPointsOfInterest(parameters));
