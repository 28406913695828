import { Box, Button, Card, List, ListItem, Typography } from '@mui/material';
import { getAuthenticatedApiClient } from '../api-client/api-client';
import FilledButton from '../components/buttons/FilledButton';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { IEmailListEntry } from '../api-client/autogenerated';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 16,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  itemText: {
    fontSize: 16,
    color: 'black',
    cursor: 'auto',
  },
  item: {
    paddingTop: 16,
    paddingBottom: 16,
    width: '100%',
  },
});

interface EmailListPDFProps {
  emails: IEmailListEntry[];
}

const EmailListPDF = ({ emails }: EmailListPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {emails.map((x) => (
          <View style={styles.item}>
            <Text style={styles.itemText}>{x.email}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default function EmailListPage() {
  const [emails, setEmails] = useState<IEmailListEntry[]>([]);
  const sortEmails = (a: IEmailListEntry, b: IEmailListEntry) => {
    if (a.email < b.email) return -1;
    if (a.email > b.email) return 1;
    return 0;
  };
  const fetchEmails = async () => {
    const e = await getAuthenticatedApiClient().getEmailList();
    setEmails(e.data.sort(sortEmails));
  };
  useEffect(() => {
    fetchEmails();
  }, []);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card elevation={4} sx={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8 }}>
        {emails.length > 0 ? (
          <FilledButton style={{ padding: 0, position: 'relative' }}>
            <PDFDownloadLink
              style={{
                color: 'white',
                textDecoration: 'none',
                width: '100%',
                height: '100%',
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 8,
              }}
              document={<EmailListPDF emails={emails} />}
              fileName="email-list.pdf"
            >
              Download Email List
            </PDFDownloadLink>
          </FilledButton>
        ) : (
          <Typography>Loading Email List...</Typography>
        )}
      </Card>
    </Box>
  );
}
