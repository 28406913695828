import { Box, Button } from '@mui/material';
import { ChangeEvent, useRef } from 'react';

interface UploadBoxProps {
  text: string;
  onChange: (file: File) => void;
  uploadPdf?: boolean;
}

export default function UploadBox({ text, onChange, uploadPdf }: UploadBoxProps) {
  const ref = useRef<any>(null);
  const onClick = () => {
    ref?.current?.click();
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.files?.[0]) onChange(e.currentTarget.files[0]);
  };
  return (
    <Box
      sx={{
        borderRadius: '16px',
        width: '360px',
        height: '100px',
        border: 'dotted black',
      }}
    >
      <Button
        variant="text"
        sx={{
          width: '100%',
          height: '100%',
        }}
        onClick={onClick}
      >
        <input
          onChange={(e) => handleChange(e)}
          style={{ display: 'none' }}
          ref={ref}
          type="file"
          accept={uploadPdf ? 'application/pdf' : 'image/*'}
        />
        {text}
      </Button>
    </Box>
  );
}
