import { IPointOfInterest, IPointOfInterestCreation, IMapLocationCreation } from '../autogenerated';
import { getAuthenticatedApiClient } from '../api-client';
import { useMutation } from 'react-query';

const createPOI = (params: {
  poi: IPointOfInterestCreation;
  mapLocation: IMapLocationCreation;
}): Promise<IPointOfInterest> =>
  getAuthenticatedApiClient()
    .createPointOfInterest({
      iPointOfInterestCreationRequest: {
        pointOfInterest: params.poi,
        imageGallery: {},
        mapLocation: params.mapLocation,
      },
    })
    .then((res) => res.data);

export const useCreatePOI = () => useMutation(['points-of-interest'], createPOI);
