import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiCreateGenericPageRequest, DefaultApiUpdateGenericPageBySlugRequest } from '../autogenerated';

const createFerryText = (parameters: DefaultApiCreateGenericPageRequest) =>
  getAuthenticatedApiClient()
    .createGenericPage(parameters)
    .then((x) => x.data);

export const useCreateFerryText = () => useMutation(createFerryText);
