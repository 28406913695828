import React from 'react';
import { css, ListItem, MenuItem, Typography } from '@mui/material';
import { IEvent, IPointOfInterest } from '../api-client/autogenerated';
import FilledButton from './buttons/FilledButton';
import { useNavigate } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

interface POIListItemProps {
  index: number;
  POI: IPointOfInterest;
  onDelete: () => {};
}

const evenColor = 'white';
const oddColor = '#EEEEEE';

const styles = {
  listItem: css`
    height: 48px;
    width: 100%;
  `,
};

export default function POIListItem({ index, POI, onDelete }: POIListItemProps) {
  const navigate = useNavigate();
  return (
    <ListItem sx={{ backgroundColor: index % 2 === 0 ? evenColor : oddColor }} css={styles.listItem}>
      <div style={{ paddingLeft: 32, paddingRight: 16, display: 'flex', width: '100%' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
          {POI.name}
        </Typography>
        <FilledButton
          onClick={() => navigate(`/events/${POI.eventId}/create-pois/${POI.id}`)}
          style={{ marginRight: 16 }}
        >
          Update
        </FilledButton>
        <FilledButton onClick={onDelete}>Delete</FilledButton>
      </div>
    </ListItem>
  );
}
