import { getAuthenticatedApiClient } from '../api-client';
import { useQuery } from 'react-query';
import { ISponsor } from '../autogenerated';

const getSponsors = (eventId: number): Promise<ISponsor[]> =>
  getAuthenticatedApiClient()
    .getSponsorsInEvent({ id: eventId })
    .then((res) => res.data);

export const useGetSponsors = (eventId: number, options?: any) =>
  useQuery(['sponsors', eventId], () => getSponsors(eventId), options);
