import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiUpdatePointOfInterestCategoryRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const updatePOICategory = (request: DefaultApiUpdatePointOfInterestCategoryRequest) =>
  getAuthenticatedApiClient()
    .updatePointOfInterestCategory(request)
    .then((res) => res.data);

export const useUpdatePOICategory = () => useMutation('update-poi-category', updatePOICategory);
