import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiReplaceOrganizerAboutPdfByNameRequest } from '../autogenerated';

const replacePdf = (params: DefaultApiReplaceOrganizerAboutPdfByNameRequest) =>
  getAuthenticatedApiClient()
    .replaceOrganizerAboutPdfByName(params)
    .then((x) => x.data);

export const useReplacePdf = () => useMutation(replacePdf);
