import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiSendMessageToTopicRequest, IEvent, IEventCreation, IMapLocationCreation } from '../autogenerated';

const createNotification = (params: DefaultApiSendMessageToTopicRequest) =>
  getAuthenticatedApiClient()
    .sendMessageToTopic(params)
    .then((x) => x.data);

export const useCreateNotification = () => useMutation(createNotification);
