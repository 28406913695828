import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiCreateImageGalleryImagesRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const uploadImage = (request: DefaultApiCreateImageGalleryImagesRequest) =>
  getAuthenticatedApiClient()
    .createImageGalleryImages(request)
    .then((res) => res.data);

export const useUploadImages = () => useMutation('upload-images', uploadImage);
