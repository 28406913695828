import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IEventSubmission } from '../api-client/autogenerated';
import { localeDateTime } from '../utils/utils';

interface EventSubmissionRowProps {
  submission: Required<IEventSubmission>;
  index: number;
  selected: boolean;
  onSelect: (submission: IEventSubmission) => void;
}

const evenColor = 'white';
const oddColor = '#EEEEEE';

export default function EventSubmissionRow({ submission, index, selected, onSelect }: EventSubmissionRowProps) {
  const navigate = useNavigate();
  return (
    <TableRow style={{ backgroundColor: index % 2 === 0 ? evenColor : oddColor }}>
      <TableCell>
        <Checkbox checked={selected} onChange={() => onSelect(submission)} />
      </TableCell>
      <TableCell style={{ cursor: 'pointer' }} onClick={() => navigate(`${submission.id}`)}>
        <b>{submission.event.name}</b>
      </TableCell>
      <TableCell>{submission.contactName}</TableCell>
      <TableCell align="right">
        {localeDateTime(new Date(submission.event.startsAt))} - {localeDateTime(new Date(submission.event.endsAt))}
      </TableCell>
    </TableRow>
  );
}
