import { useMutation } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiDeleteEventSubmissionRequest } from '../autogenerated';

const deleteEventSubmission = (parameters: DefaultApiDeleteEventSubmissionRequest) =>
  getAuthenticatedApiClient()
    .deleteEventSubmission(parameters)
    .then((x) => x.data);

export const useDeleteEventSubmission = () => useMutation(deleteEventSubmission);
