import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiUpdateEventCategoryRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const updateEventCategory = (request: DefaultApiUpdateEventCategoryRequest) =>
  getAuthenticatedApiClient()
    .updateEventCategory(request)
    .then((res) => res.data);

export const useUpdateEventCategory = () => useMutation('update-event-category', updateEventCategory);
