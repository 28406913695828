import { Card, Divider, List, Pagination, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FilledButton from '../components/buttons/FilledButton';
import { useGetEvents } from '../api-client/hooks/useGetEvents';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventListItem from '../components/EventListItem';
import { IEvent } from '../api-client/autogenerated';
import { useDeleteEvent } from '../api-client/hooks/useDeleteEvent';

const pageSize = 20;

export default function EventPage() {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);

  const { data: eventsData, refetch: refetchEvents } = useGetEvents({
    pageSize,
    page,
    orderBy: 'name',
    searchString: searchInput,
  });
  const { mutateAsync: deleteEvent } = useDeleteEvent();

  useEffect(() => {
    setPage(1);
  }, [searchInput]);

  const events = eventsData?.data;

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  const handleDeletePressed = async (event: IEvent) => {
    await deleteEvent(event);
    refetchEvents();
  };

  return (
    <Card sx={{ width: 959, minHeight: 64 }} elevation={4}>
      <Box
        sx={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <Typography variant="h5" style={{ color: 'black', fontWeight: 'bold' }}>
          Events
        </Typography>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginRight: 16 }}>
            <SearchIcon sx={{ position: 'absolute', left: 8 }} />
            <TextField
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Events"
              InputProps={{ sx: { paddingLeft: 3 } }}
              size="small"
            />
          </div>
        </div>
        <FilledButton onClick={() => navigate('/create-event')}>Create Event</FilledButton>
      </Box>
      <Divider style={{ backgroundColor: 'black' }} />
      <List>
        {events?.map((x, index) => (
          <EventListItem onDelete={() => handleDeletePressed(x)} index={index} event={x} />
        ))}
      </List>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 8, paddingBottom: 16 }}>
        <Pagination count={eventsData?.totalPages} shape="rounded" page={page} onChange={handlePageChange} />
      </div>
    </Card>
  );
}
