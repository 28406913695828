if (!process.env.REACT_APP_API_URL) {
  throw new Error('REACT_APP_API_URL is not set');
}

const API_URL = process.env.REACT_APP_API_URL;

if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
  throw new Error('REACT_APP_COGNITO_USER_POOL_ID is not set');
}
const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;

if (!process.env.REACT_APP_AWS_REGION) {
  throw new Error('REACT_APP_REGION is not set');
}
const AWS_REGION = process.env.REACT_APP_AWS_REGION;

if (!process.env.REACT_APP_COGNITO_WEB_CLIENT_ID) {
  throw new Error('REACT_APP_COGNITO_WEB_CLIENT_ID is not set');
}
const COGNITO_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;

export { API_URL, COGNITO_USER_POOL_ID, AWS_REGION, COGNITO_WEB_CLIENT_ID };
