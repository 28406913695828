import { useQuery } from 'react-query';
import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiGetEventsRequest, IEventGetAllResponse } from '../autogenerated';

const getEvents = (parameters?: DefaultApiGetEventsRequest): Promise<IEventGetAllResponse> =>
  getAuthenticatedApiClient()
    .getEvents(parameters)
    .then((x) => x.data);

export const useGetEvents = (parameters?: DefaultApiGetEventsRequest, options?: any) =>
  useQuery(
    [
      'events',
      {
        page: parameters?.page,
        pageSize: parameters?.pageSize,
        id: parameters?.id,
        searchString: parameters?.searchString,
      },
    ],
    () => getEvents(parameters),
    options,
  );

const getGenericPages = async () => {
  const genericPages = await getAuthenticatedApiClient().getGenericPages();
  const genericPageSlugToHtml: Record<string, string> = {};
  genericPages.data.forEach((genericPage) => (genericPageSlugToHtml[genericPage.slug] = genericPage.html || ''));
  return genericPageSlugToHtml;
};

export const useGetGenericPages = () => useQuery('generic-pages', getGenericPages);
