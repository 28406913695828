import { getAuthenticatedApiClient } from '../api-client';
import { DefaultApiReplaceMapStaticImageForEventRequest } from '../autogenerated';
import { useMutation } from 'react-query';

const replaceMapImage = (request: DefaultApiReplaceMapStaticImageForEventRequest) =>
  getAuthenticatedApiClient()
    .replaceMapStaticImageForEvent(request)
    .then((res) => res.data);

export const useReplaceMapImage = () => useMutation('replace-map-image', replaceMapImage);
