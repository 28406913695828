import { getAuthenticatedApiClient } from '../api-client';
import { IPointOfInterestCategoryCreation } from '../autogenerated';
import { useMutation } from 'react-query';

const createPOICategory = (category: IPointOfInterestCategoryCreation) =>
  getAuthenticatedApiClient()
    .createPointOfInterestCategory({ iPointOfInterestCategoryCreationRequest: { pointOfInterestCategory: category } })
    .then((res) => res.data);

export const useCreatePOICategory = () => useMutation('create-poi-category', createPOICategory);
