import './App.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import LoginPage from './screens/LoginPage';
import { theme } from './themes/CustomTheme';
import { ThemeProvider } from '@emotion/react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { ProtectedRoute } from './components/ProtectedRoute';
import EventPage from './screens/EventPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import CreateEventPage from './screens/CreateEventPage';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CreateTagsAndCategories from './screens/CreateTagsAndCategories';
import EventActsPage from './screens/EventActsPage';
import CreateEventActPage from './screens/CreateEventActPage';
import React from 'react';
import PointsOfInterestPage from './screens/PointsOfInterestPage';
import CreatePOIs from './screens/CreatePOIs';
import AddAdminPage from './screens/AddAdminPage';
import EmailListPage from './screens/EmailListPage';
import ChangePDFPage from './screens/ChangePDFPage';
import FerryTextPage from './screens/FerryTextPage';
import SendNotificationPage from './screens/SendNotificationPage';
import EventSubmissionsPage from './screens/EventSubmissionsPage';
import PrivacyPolicyPage from './screens/PrivacyPolicyPage';

const queryClient = new QueryClient();

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              {/* for the future, can add more paths here */}
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route
                  path="/events"
                  element={
                    <ProtectedRoute>
                      <EventPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-event"
                  element={
                    <ProtectedRoute>
                      <CreateEventPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-event/:eventId"
                  element={
                    <ProtectedRoute>
                      <CreateEventPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-event-categories"
                  element={
                    <ProtectedRoute>
                      <CreateTagsAndCategories type="event" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-poi-categories"
                  element={
                    <ProtectedRoute>
                      <CreateTagsAndCategories type="poi" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/create-pois"
                  element={
                    <ProtectedRoute>
                      <CreatePOIs />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/create-pois/:pointOfInterestId"
                  element={
                    <ProtectedRoute>
                      <CreatePOIs />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/event-acts"
                  element={
                    <ProtectedRoute>
                      <EventActsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/create-event-act"
                  element={
                    <ProtectedRoute>
                      <CreateEventActPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/update-event-act/:actId"
                  element={
                    <ProtectedRoute>
                      <CreateEventActPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/events/:eventId/points-of-interest"
                  element={
                    <ProtectedRoute>
                      <PointsOfInterestPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/email-list"
                  element={
                    <ProtectedRoute>
                      <EmailListPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-admin"
                  element={
                    <ProtectedRoute>
                      <AddAdminPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/change-pdf"
                  element={
                    <ProtectedRoute>
                      <ChangePDFPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ferry"
                  element={
                    <ProtectedRoute>
                      <FerryTextPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="notifications"
                  element={
                    <ProtectedRoute>
                      <SendNotificationPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="submissions"
                  element={
                    <ProtectedRoute>
                      <EventSubmissionsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="submissions/:submissionId"
                  element={
                    <ProtectedRoute>
                      <CreateEventPage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
