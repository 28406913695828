import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import DashboardView from './DashboardView';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

interface ProtectedRouteProps {
  children: any;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const location = useLocation();
  const [user, setUser] = useState<any>('loading');
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await Auth.currentAuthenticatedUser();
        setUser(response);
      } catch {
        setUser(null);
      }
    };
    fetchUser();
  }, []);
  if (user === 'loading') return null;
  else if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <DashboardView>{children}</DashboardView>;
}
