import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface UploadedImageProps {
  src?: string;
  onRemove?: (file: String) => void;
  imageStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export default function UploadedImage({ src, onRemove, imageStyle, containerStyle }: UploadedImageProps) {
  return (
    <div style={{ position: 'relative', ...containerStyle }}>
      <img alt="" style={imageStyle || { width: 250, height: 250 }} src={src} />
      {onRemove && src && (
        <IconButton
          size="small"
          style={{ position: 'absolute', top: 0, right: 0, color: 'white', padding: 0 }}
          onClick={() => onRemove(src)}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}
