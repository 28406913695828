import { getAuthenticatedApiClient } from '../api-client';
import { useMutation } from 'react-query';
import { DefaultApiReplaceThumbnailImageForEventCategoryRequest } from '../autogenerated';

const replaceEventCategoryThumbnailImage = (request: DefaultApiReplaceThumbnailImageForEventCategoryRequest) =>
  getAuthenticatedApiClient()
    .replaceThumbnailImageForEventCategory(request)
    .then((res) => res.data);

export const useReplaceEventCategoryThumbnailImage = () =>
  useMutation('replace-event-category-thumbnail', replaceEventCategoryThumbnailImage);
