import { Box, Collapse, Icon, Typography } from '@mui/material';
import { theme } from '../themes/CustomTheme';
import { ArrowDropDown, Event } from '@mui/icons-material';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

/** @jsxImportSource @emotion/react */

export type DrawerItemProps = {
  title: string;
  iconName:
    | 'event'
    | 'my_location'
    | 'event_note'
    | 'person'
    | 'mail'
    | 'logout'
    | 'picture_as_pdf'
    | 'directions_boat'
    | 'rate_review'
    | 'notifications';
  onClick?: () => void;
  selected?: boolean;
  disableDropdown?: boolean;
  route?: string;
};

const styles = {
  drawerItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 8px 0 14px;
    cursor: pointer;
  `,
  listItem: css`
    display: flex;
    align-items: center;
    padding: 8px 0px 12px 14px;
    background-color: #09603f;
    cursor: pointer;
  `,
};

export default function DrawerItem(props: DrawerItemProps) {
  const { title, iconName, onClick, selected, disableDropdown } = props;

  const navigate = useNavigate();

  const getOptions = () => {
    switch (iconName) {
      case 'my_location':
        return [
          {
            title: 'Create Categories',
            onClick: () => {
              navigate('/create-poi-categories');
            },
          },
          // {
          //   title: 'Create POI',
          //   onClick: () => {
          //     navigate('/create-pois');
          //   },
          // },
          // { title: 'View/Edit POI', onClick: () => {} },
        ];
      case 'event_note':
        return [
          { title: 'Create Event Acts', onClick: () => {} },
          { title: 'View/Edit Event Acts', onClick: () => {} },
        ];
      case 'event':
        return [
          {
            title: 'Create Categories',
            onClick: () => {
              navigate('/create-event-categories');
            },
          },
          {
            title: 'Create Events',
            onClick: () => {
              navigate('/create-event');
            },
          },
          {
            title: 'View/Edit Events',
            onClick: () => {
              navigate('/events');
            },
          },
        ];
      case 'logout':
      case 'person':
      case 'mail':
      case 'picture_as_pdf':
      case 'directions_boat':
      case 'notifications':
      case 'rate_review':
        return [];
    }
  };

  return (
    <>
      <Box onClick={onClick} css={styles.drawerItem} style={{ backgroundColor: selected ? '#958B55' : 'initial' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ color: !selected ? theme.palette.common.white : '#FF9600' }}>{iconName}</Icon>
          <Typography
            color={theme.palette.common.white}
            style={{ marginLeft: 14, fontWeight: selected ? 700 : 'initial', whiteSpace: 'nowrap' }}
          >
            {title}
          </Typography>
        </div>
        {!disableDropdown && <ArrowDropDown htmlColor={!selected ? theme.palette.common.white : '#FF9600'} />}
      </Box>
      {!disableDropdown && (
        <Collapse in={selected}>
          {getOptions().map(({ title, onClick }) => (
            <Box onClick={onClick} css={styles.listItem}>
              <Typography color={theme.palette.common.white} style={{ lineHeight: '25px' }}>
                {title}
              </Typography>
            </Box>
          ))}
        </Collapse>
      )}
    </>
  );
}
